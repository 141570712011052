/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/react";
import React from "react";
import ElementWrapper from "./wrappers/ElementWrapper";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import log from "cslog";
import { elementState, selectedElementState } from "../db/elementDb";
import {
    parseStyle,
    decodeStyle,
    decodeAttribute,
} from "../parser/styleparser";
import { desktop, tablet, mobile, getDevice } from "../data/bp";
import { menuState } from "../db/siteConfig";
import { TOP_NAV_MENU } from "../data/defaults";

// TODO: Style and responsive behaviour
function NavMenu({ id, index, setHoverIndex, direction, gs, device }) {
    const [data, setData] = useRecoilState(elementState(id));
    const setSelectedElement = useSetRecoilState(selectedElementState);

    // const ps = parseStyle(data, gs, data.cstate);
    const ps = data;

    log.d(id, "Nav Menu incomding id");

    // const deviceName = getDevice(device);

    // const tabletStyle = tablet(device) ? ps.tablet : {};
    // const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    const menuHeight = input.menuHeight + "px";
    const menuColor = "transparent";

    const styleMenuItem = decodeStyle(ps.styleGroup.menuItem, gs);
    const styleMenuItemMobile = decodeStyle(ps.mobileGroup.menuItem, gs);
    const styleDrawer = decodeStyle(ps.mobileGroup.drawer, gs);
    const styleBurger = decodeStyle(ps.mobileGroup.burgerButton, gs);

    log.d(styleMenuItemMobile, "StyleMenuItem Nav");

    // Always keep containing section z-index >3
    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            {/* https://www.youtube.com/watch?v=oLgtucwjVII */}
            <nav
                onClick={() => setSelectedElement(id)}
                css={css`
                    background-color: ${menuColor};
                    height: ${menuHeight};
                    width: 100%;
                    * {
                        padding: 0;
                        margin: 0;
                        text-decoration: 0;
                        list-style: none;
                        box-sizing: border-box;
                    }
                    z-index: 5;
                `}
            >
                <input
                    type="checkbox"
                    id="checkBtnForNav"
                    css={{
                        display: "none",
                        ...(mobile(device)
                            ? {
                                  "&:checked ~ ul": {
                                      //   left: 0,
                                      transform: "translate(0px, 0px)",
                                  },
                              }
                            : {}),
                    }}
                />
                <label
                    htmlFor="checkBtnForNav"
                    css={{
                        float: "right",
                        lineHeight: menuHeight,
                        marginRight: "40px",
                        // fontSize: "30px",
                        color: "black",
                        cursor: "pointer",
                        display: "none",
                        zIndex: 10,
                        ...(mobile(device)
                            ? {
                                  display: "block",
                              }
                            : {}),
                        ...styleBurger,
                    }}
                >
                    <i className="fas fa-bars" style={{ zIndex: 11 }} />
                </label>
                <ul
                    css={{
                        // marginRight: "20px",
                        paddingRight: "50px",
                        float: "right",
                        zIndex: 11,
                        ...(mobile(device)
                            ? {
                                  width: "360px",
                                  height: "100vh",
                                  textAlign: "center",
                                  transform: "translate(-100%, 0px)",
                                  transition: "all 0.5s",
                                  ...styleDrawer,
                              }
                            : {}),
                    }}
                >
                    {input.items.map((item) => {
                        return (
                            <li
                                key={item.name}
                                css={{
                                    display: "inline-block",
                                    lineHeight: menuHeight,
                                    ...(mobile(device)
                                        ? {
                                              display: "block",
                                              lineHeight: "30px",
                                              margin: styleMenuItemMobile.margin,
                                          }
                                        : {}),
                                }}
                            >
                                <a
                                    css={{
                                        ...styleMenuItem,
                                        // textTransform: "uppercase",
                                        padding: "7px 13px",
                                        borderRadius: "3px",
                                        "&:hover": {
                                            color: "white",
                                            backgroundColor: "black",
                                        },
                                        ...(mobile(device)
                                            ? {
                                                  ...styleMenuItemMobile,
                                                  margin: 0,
                                              }
                                            : {}),
                                    }}
                                    // href={item.link.value}
                                    href="#nothing"
                                >
                                    {item.name}
                                </a>
                            </li>
                        );
                    })}
                    {/* <li>
                        <a href="#">Feedback</a>
                    </li> */}
                </ul>
            </nav>
        </ElementWrapper>
    );
}

export default NavMenu;
