import log from "cslog";
import React from "react";
import Element from "../../ElementStaticView";
import "./styles/CanvasFree.css";

const CanvasFreeView = ({ id, db, width, height, device, gs }) => {
    // const edata = useRecoilValue(elementState(id));
    const edata = db[id];

    log.d(edata, "Canvas Actual Data");
    log.d(id in db, "DB Data");

    // log.d(edata, "EDATA");
    const data = { ...edata["childs"] };

    return (
        <div
            style={{
                position: "absolute",
                pointerEvents: "none",
            }}
        >
            <div className="container">
                <div
                    className="canvas"
                    style={{
                        width: width,
                        height: height,
                        overflow: "hidden",
                        pointerEvents: "none",
                    }}
                >
                    {Object.keys(data).map((item) => {
                        const translate = data[item]["translate"];
                        const value = data[item];
                        return (
                            <div
                                key={item}
                                // className="targett"
                                id={item}
                                style={{
                                    // pointerEvents: "none",
                                    position: "absolute",
                                    border: "1px solid transparent",
                                    width: value.width,
                                    height: value.height,
                                    pointerEvents: "none",
                                    transform: `translate(${translate[0]}px, ${translate[1]}px) rotate(${value.rotate}deg)`,
                                }}
                            >
                                {/* {item} */}
                                <Element
                                    id={item}
                                    db={db}
                                    device={device}
                                    gs={gs}
                                />
                            </div>
                        );
                    })}
                </div>
                {/* <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: width,
                        height: height,
                        backgroundColor: "transparet",
                        opacity: 0.4,
                    }}
                /> */}
            </div>
        </div>
    );
};

export default CanvasFreeView;
