import React, { Fragment, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { setColor } from "cslog";
import { QurealTextEditorDialog } from "../widgets/rte/QurealTextEditor";

import styles from "./styles/COptions.module.scss";

import { Range } from "react-range";

import { SHAPES } from "../data/extra/shapeData";

import {
    Popup,
    Button,
    Icon,
    Form,
    Label,
    Input,
    Dropdown,
} from "semantic-ui-react";
import log from "cslog";

import {
    colorOption,
    rangeOption,
    selectOption,
    booleanOption,
    optionRowIcon,
    textOption,
    ColorConfigPicker,
} from "./SimpleOptions";

import GradientPicker from "../widgets/GradientPicker";
import ImagePanel from "../widgets/ImagePanel";
import { decodeAttribute } from "../parser/styleparser";
import { startCase } from "lodash";
import { useRecoilValue } from "recoil";
import { pageState } from "../db/siteConfig";

function saveSelection() {
    if (window.getSelection) {
        const sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
            return sel.getRangeAt(0);
        }
    } else if (document.selection && document.selection.createRange) {
        return document.selection.createRange();
    }
    return null;
}

function restoreSelection(range) {
    if (range) {
        if (window.getSelection) {
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (document.selection && range.select) {
            range.select();
        }
    }
}

const HyperlinkButton = () => {
    const [sr, setSr] = useState(null);
    const [linkText, setLinkText] = useState("");
    const [open, setOpen] = useState(false);

    const justLink = () => {
        restoreSelection(sr);
        let link = linkText;
        if (!link.startsWith("http")) {
            link = `https://${linkText}`;
        }
        let selectedText = document.getSelection();
        document.execCommand(
            "insertHTML",
            false,
            '<a href="' + link + '" target="_blank">' + selectedText + "</a>"
        );
        // var selection = document.getSelection();
        // document.execCommand("createLink", false, link);
        // selection.anchorNode.parentElement.target = "_blank";
        setOpen(false);
    };

    return (
        <Popup
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            on="click"
            trigger={
                <Button className={styles.item} key="hyperlink" icon>
                    <Icon name="linkify" />
                </Button>
            }
        >
            <Form>
                <input
                    placeholder="Paste link here"
                    value={linkText}
                    onChange={(e) => setLinkText(e.target.value)}
                    onFocus={(e) => {
                        const range = saveSelection();
                        setSr(range);
                        log.d(range, "Range");
                    }}
                />
                <Button
                    primary
                    style={{ marginTop: "10px" }}
                    onClick={justLink}
                >
                    Add Link
                </Button>
            </Form>
        </Popup>
    );
};

export const textEditOptions = (
    basic = false,
    allow = [
        ["bold", "bold", <b>Bold</b>],
        ["italic", "italic", <i>Italic</i>],
        ["strikeThrough", "strikethrough", <strike>Strike</strike>],
        ["underline", "underline", <u>Underline</u>],
        [
            "subscript",
            "subscript",
            <span>
                Sub <sub>script</sub>
            </span>,
        ],
        ["removeFormat", "remove", <span>Remove Formatting</span>],
    ]
) => {
    return (
        <Form.Field key="selectedText">
            <label>Selected Text</label>
            <div className={styles.grid4}>
                {allow.map(([item, icon, html]) => (
                    <Popup
                        key={item}
                        inverted
                        content={html}
                        trigger={
                            <Button
                                className={styles.item}
                                key={item}
                                icon
                                onMouseDown={(evt) => {
                                    evt.preventDefault(); // Avoids loosing focus from the editable area
                                    // let window = document.getElementById(
                                    // 	"resDev"
                                    // ).contentWindow;
                                    let selection = window.getSelection();

                                    if (
                                        selection.extentOffset -
                                            selection.baseOffset ===
                                        0
                                    ) {
                                        log.p(
                                            "Nothing is selected. so selecting all"
                                        );
                                        // document.execCommand(
                                        // 	"selectAll",
                                        // 	false,
                                        // 	null
                                        // );
                                        window.document.execCommand(
                                            "selectAll",
                                            false,
                                            null
                                        );
                                    }
                                    // let done = document.execCommand(
                                    // 	item,
                                    // 	false,
                                    // 	null
                                    // ); // Send the command to the browser
                                    let done = window.document.execCommand(
                                        item,
                                        false,
                                        null
                                    );
                                    log.d(done, "Done");
                                }}
                            >
                                <Icon name={icon} />
                            </Button>
                        }
                    />
                ))}
                {!basic && <HyperlinkButton />}
                {!basic && (
                    <Popup
                        key="unlink"
                        inverted
                        content="Remove link"
                        trigger={
                            <Button
                                className={styles.item}
                                key="hyperlink"
                                icon
                                onClick={() => {
                                    document.execCommand(
                                        "unlink",
                                        false,
                                        false
                                    );
                                }}
                            >
                                <Icon name="unlink" />
                            </Button>
                        }
                    />
                )}
            </div>
        </Form.Field>
    );
};

export const widthsOption = (key, value, onChange) => {
    return <h3>Widths</h3>;
};

export const colWidths = (key, value, onChange) => {
    return (
        <Form.Field>
            <label>Column width</label>
            <Range
                step={1}
                min={0}
                max={100}
                values={[value[0]]}
                onChange={(values) =>
                    onChange(key, [values[0], 100 - values[0]])
                }
                renderTrack={({ props, children }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: "6px",
                            width: "100%",
                            backgroundColor: "#5f9de8",
                            borderRadius: "2px",
                        }}
                    >
                        {children}
                    </div>
                )}
                renderThumb={({ props }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: "15px",
                            width: "15px",
                            backgroundColor: "white",
                            border: "1px solid black",
                            borderRadius: "2px",
                        }}
                    />
                )}
            />
        </Form.Field>
    );
};

export const numCol = (key, value, onChange) => {
    return (
        <Form.Field>
            <label>Number of columns</label>
            <div className={styles.grid3}>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange(key, 1);
                    }}
                    primary={value === 1}
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(-45deg)",
                        }}
                    />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange(key, 2);
                    }}
                    primary={value === 2}
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(-45deg)",
                        }}
                    />
                </Button>
            </div>
        </Form.Field>
    );
};

export const setDims = (key, value, setAttribute) => {
    log.d(value, "Dim Value");
    let { width, height } = value;
    let mwidth = parseInt(width.substring(0, width.indexOf("%")));
    return (
        <Form.Field>
            <label>Width</label>
            <Range
                step={1}
                min={0}
                max={100}
                values={[mwidth]}
                onChange={(values) =>
                    setAttribute(key, {
                        width: values[0] + "%",
                        height: height,
                    })
                }
                renderTrack={({ props, children }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: "6px",
                            width: "100%",
                            backgroundColor: "#5f9de8",
                            borderRadius: "2px",
                        }}
                    >
                        {children}
                    </div>
                )}
                renderThumb={({ props }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: "15px",
                            width: "15px",
                            backgroundColor: "white",
                            border: "1px solid black",
                            borderRadius: "2px",
                        }}
                    />
                )}
            />
        </Form.Field>
    );
};

export const textAlign = (key, value, onChange) => {
    return (
        <Form.Field key={key}>
            <label>{key}</label>
            <div className={styles.grid3}>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange("left");
                    }}
                    primary={value === "left"}
                >
                    <Icon name="align left" />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange("center");
                    }}
                    primary={value === "center"}
                >
                    <Icon name="align center" />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange("right");
                    }}
                    primary={value === "right"}
                >
                    <Icon name="align right" />
                </Button>
            </div>
        </Form.Field>
    );
};

export const verticleAlign = (key, value, onChange) => {
    return (
        <Form.Field>
            <label>{key}</label>
            <div className={styles.grid3}>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange("flex-start");
                    }}
                    primary={value === "flex-start"}
                >
                    <Icon name="arrow up" />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange("center");
                    }}
                    primary={value === "center"}
                >
                    <Icon name="circle outline" />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange("flex-end");
                    }}
                    primary={value === "flex-end"}
                >
                    <Icon name="arrow down" />
                </Button>
            </div>
        </Form.Field>
    );
};

export const alignSelf = (key, value, onChange) => {
    return (
        <Form.Field>
            <label>{key}</label>
            <div className={styles.grid3}>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange(key, "flex-start");
                    }}
                    primary={value === "flex-start"}
                >
                    <Icon name="align left" />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange(key, "center");
                    }}
                    primary={value === "center"}
                >
                    <Icon name="align center" />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        onChange(key, "flex-end");
                    }}
                    primary={value === "flex-end"}
                >
                    <Icon name="align right" />
                </Button>
            </div>
        </Form.Field>
    );
};

export const setPosition = (key, value, setValue) => {
    const { alignItems, justifyContent, flexDirection } = value;
    let h = "alignItems";
    let v = "justifyContent";
    if (flexDirection === "row") {
        h = "justifyContent";
        v = "alignItems";
    }

    return (
        <Form.Field>
            <label>Position</label>
            <div className={styles.grid3}>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        setValue("position", {
                            ...value,
                            [h]: "flex-start",
                            [v]: "flex-start",
                        });
                    }}
                    primary={
                        justifyContent === "flex-start" &&
                        alignItems === "flex-start"
                    }
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(-45deg)",
                        }}
                    />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        setValue("position", {
                            ...value,
                            [h]: "center",
                            [v]: "flex-start",
                        });
                    }}
                    primary={
                        justifyContent === "center" &&
                        alignItems === "flex-start"
                    }
                >
                    <Icon name="arrow up" />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        setValue("position", {
                            ...value,
                            [h]: "flex-end",
                            [v]: "flex-start",
                        });
                    }}
                    primary={
                        justifyContent === "flex-end" &&
                        alignItems === "flex-start"
                    }
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(45deg)",
                        }}
                    />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        setValue("position", {
                            ...value,
                            [h]: "flex-start",
                            [v]: "center",
                        });
                    }}
                    primary={
                        justifyContent === "flex-start" &&
                        alignItems === "center"
                    }
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(-90deg)",
                        }}
                    />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        setValue("position", {
                            ...value,
                            [h]: "center",
                            [v]: "center",
                        });
                    }}
                    primary={
                        justifyContent === "center" && alignItems === "center"
                    }
                >
                    <Icon name="circle outline" />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        setValue("position", {
                            ...value,
                            [h]: "flex-end",
                            [v]: "center",
                        });
                    }}
                    primary={
                        justifyContent === "flex-end" && alignItems === "center"
                    }
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(90deg)",
                        }}
                    />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        setValue("position", {
                            ...value,
                            [h]: "flex-start",
                            [v]: "flex-end",
                        });
                    }}
                    primary={
                        justifyContent === "flex-start" &&
                        alignItems === "flex-end"
                    }
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(-135deg)",
                        }}
                    />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        setValue("position", {
                            ...value,
                            [h]: "center",
                            [v]: "flex-end",
                        });
                    }}
                    primary={
                        justifyContent === "center" && alignItems === "flex-end"
                    }
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(180deg)",
                        }}
                    />
                </Button>
                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        console.log("Setting Attribute");
                        setValue("position", {
                            ...value,
                            [h]: "flex-end",
                            [v]: "flex-end",
                        });
                    }}
                    primary={
                        justifyContent === "flex-end" &&
                        alignItems === "flex-end"
                    }
                >
                    <Icon
                        name="arrow up"
                        style={{
                            transform: "rotate(135deg)",
                        }}
                    />
                </Button>
            </div>
        </Form.Field>
    );
};

export const alignVertical = (key, setProperty) => {
    return (
        <div className="hbox" key={key}>
            <button onClick={() => setProperty(key, "flex-start")}>T</button>
            <button onClick={() => setProperty(key, "center")}>M</button>
            <button onClick={() => setProperty(key, "flex-end")}>B</button>
        </div>
    );
};

export const justifyContent = (key, setProperty) => {
    return (
        <div className="hbox" key={key}>
            <button onClick={() => setProperty(key, "flex-start")}>L</button>
            <button onClick={() => setProperty(key, "center")}>C</button>
            <button onClick={() => setProperty(key, "flex-end")}>R</button>
        </div>
    );
};

export const fontSize = (key, value, setProperty) => {
    return (
        <Form.Field>
            <label>Font Size</label>
            <Range
                step={1}
                min={0}
                max={100}
                values={[value]}
                onChange={(values) => setProperty(key, values[0])}
                renderTrack={({ props, children }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: "6px",
                            width: "100%",
                            backgroundColor: "#5f9de8",
                            borderRadius: "2px",
                        }}
                    >
                        {children}
                    </div>
                )}
                renderThumb={({ props }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: "15px",
                            width: "15px",
                            backgroundColor: "white",
                            border: "1px solid black",
                            borderRadius: "2px",
                        }}
                    />
                )}
            />
            {/* <div className="hbox" key={key}>
				<button onClick={() => setProperty(key, value + 1)}>+</button>
				<button onClick={() => setProperty(key, value - 1)}>-</button>
			</div> */}
        </Form.Field>
    );
};

export const colorP = (key, value, setProperty) => {
    return (
        <Form.Field>
            <label>{key}</label>
            <Popup
                trigger={
                    <Label
                        as="a"
                        style={{
                            color: "black",
                            backgroundColor: value,
                            border: "1px solid black",
                        }}
                        key={value}
                    >
                        {value}
                    </Label>
                    // <Button
                    // 	style={{
                    // 		backgroundColor: value,
                    // 		color: "white",
                    // 	}}
                    // 	bordered
                    // >
                    // 	Line Color
                    // </Button>
                }
                eventsEnabled={true}
                on="click"
            >
                <SketchPicker
                    color={value}
                    onChangeComplete={(color) => {
                        // setCol(color.hex);
                        setProperty(key, color.hex);
                    }}
                />
            </Popup>
        </Form.Field>
    );
};

export const gcolorOption = (label, value, onChange, gs) => {
    let val = value;
    const isObj = typeof value === "object";
    if (isObj) {
        val = decodeAttribute(value, gs);
    }

    return (
        <React.Fragment key={label}>
            <Form.Field key={label}>
                {/* <label>{key}</label> */}
                {label && <label>{label}</label>}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2px",
                    }}
                >
                    <Popup
                        trigger={
                            <Button
                                icon="tint"
                                // primary={!isObj}
                                style={{
                                    // color: "#7f7f7f",
                                    border: "1px solid #f1f1f1",
                                    ...(isObj ? {} : { color: val }),
                                }}
                            />
                        }
                        eventsEnabled={true}
                        on="click"
                    >
                        <SketchPicker
                            color={val}
                            onChangeComplete={(color) => {
                                // onChange(color.hex);
                                onChange(
                                    `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                                );
                            }}
                        />
                    </Popup>

                    <ColorConfigPicker
                        active={isObj}
                        value={val}
                        setValue={(newVal) => onChange(newVal)}
                    />
                </div>
            </Form.Field>
        </React.Fragment>
    );
};

export const setBackground = (key, value, setValue, gs, pstyle) => {
    log.d(value, "Background Value");
    let vtype = value.type;
    let params = value.params;
    if (typeof params === "object") {
        if ("type" in params) {
            params = decodeAttribute(params, gs);
        }
    } else if (vtype === "config") {
        params = decodeAttribute(value, gs);
    }

    return (
        <React.Fragment key={key}>
            <Form.Field key={key}>
                <label>{key}</label>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2px",
                    }}
                >
                    <Popup
                        inverted
                        content="Transparent"
                        trigger={
                            <Button
                                icon="ban"
                                primary={vtype === "transparent"}
                                onClick={() => {
                                    setValue("background", {
                                        type: "transparent",
                                        params: "transparent",
                                        value: "transparent",
                                    });
                                }}
                            />
                        }
                    />
                    <ColorConfigPicker
                        active={vtype === "config"}
                        value={params}
                        setValue={(newVal) => setValue("background", newVal)}
                    />
                    <Popup
                        trigger={
                            <Button
                                icon="tint"
                                primary={vtype === "simple"}
                                style={{
                                    color: "#7f7f7f",
                                    border: "1px solid #f1f1f1",
                                    ...(vtype === "simple"
                                        ? { backgroundColor: value.value }
                                        : {}),
                                }}
                            />
                        }
                        eventsEnabled={true}
                        on="click"
                    >
                        <SketchPicker
                            color={params}
                            onChangeComplete={(color) => {
                                // onChange(color.hex);
                                setValue("background", {
                                    type: "simple",
                                    params: color.rgb,
                                    value: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
                                });
                            }}
                        />
                    </Popup>

                    <GradientPicker
                        label={null}
                        value={vtype === "gradient" ? params : null}
                        onSelect={(newBack) => {
                            console.log("Selected: " + newBack);
                            setValue("background", {
                                type: "gradient",
                                params: newBack,
                                value: newBack.fvalue,
                            });
                        }}
                        isSelected={vtype === "gradient"}
                    />

                    <Popup
                        inverted
                        content="Image Background"
                        trigger={
                            <ImagePanel
                                button={
                                    <Button
                                        icon="image outline"
                                        primary={vtype === "image"}
                                    />
                                }
                                onPhoto={(photo) => {
                                    setValue("background", {
                                        type: "image",
                                        params: { src: photo.full },
                                        value: `url(${photo.full})`,
                                    });
                                }}
                            />
                        }
                    />
                </div>
            </Form.Field>
            {vtype === "image" &&
                selectOption(
                    "Background Position",
                    pstyle.backgroundPosition,
                    (newVal) => setValue("backgroundPosition", newVal),
                    [
                        ["Top", "top center"],
                        ["Center", "center center"],
                        ["Bottom", "bottom center"],
                    ]
                )}
            {vtype === "image" &&
                booleanOption(
                    "Parallex",
                    pstyle.backgroundAttachment === "fixed",
                    (newVal) => {
                        if (newVal) {
                            setValue("backgroundAttachment", "fixed");
                        } else {
                            setValue("backgroundAttachment", "local");
                        }
                    }
                )}
        </React.Fragment>
    );
};

export const GOOGLE_FONTS = [
    "Roboto",
    "Patrick Hand",
    "Open Sans",
    "Lato",
    "Old Standard TT",
    "Abril Fatface",
    "PT Serif",
    "Ubuntu",
    "Vollkorn",
    "Droid",
    "Montserrat",
    "Oswald",
    "Source Sans Pro",
    "Raleway",
    "Merriweather",
    "Pacifico",
    "Annie Use Your Telescope",
    "Monoton",
    "Lobster",
];

// TODO: Add Font Library (picker)
// Use this API to get list of fonts
// https://developers.google.com/fonts/docs/developer_api
function QFonts() {
    useEffect(() => {
        window.WebFont.load({
            google: {
                families: GOOGLE_FONTS,
            },
        });
    });
    // return <h2>Hello World</h2>;
    return null;
}

export const fontFamily = (key, value, onValue) => {
    const fontList = [
        [`Default`, `inherit`],
        [`Arial`, `Arial, Helvetica, sans-serif`],
        [`Times New Roman`, `"Times New Roman", Times, serif`],
        [`Georgia`, `Georgia, serif`],
        [`Halvetica`, `Helvetica, sans-serif`],
        // [`Comic Sans MS`, `"Comic Sans MS, Comic Sans, cursive`],
        [`Verdana`, `Verdana, Geneva, sans-serif`],
        [`Lucida Console`, `"Lucida Console", Monaco, monospace`],
    ];

    // GOOGLE_FONTS.map((font) => {
    //     fontList.push([font, font]);
    // });

    const options = fontList.map((item) => {
        return {
            key: item[0],
            text: <span style={{ fontFamily: item[1] }}>{item[0]}</span>,
            value: item[1],
        };
    });

    const handleChange = (e, { value }) => onValue(value);

    return (
        <React.Fragment key={key}>
            <Form.Field key={key}>
                <label>{key}</label>
                <Dropdown
                    placeholder="Select Font"
                    options={options}
                    selection
                    value={value}
                    onChange={handleChange}
                />
            </Form.Field>
            <QFonts />
        </React.Fragment>
    );
};

// Depend on height, position will appear or hide.
export const height = (key, value, onValue) => {
    const optionList = [
        ["Auto", "auto"],
        ["Screen Height", "fullscreen"],
        ["Fixed", "fixed"],
    ];

    const lookup = {
        auto: { type: "auto", params: "auto", value: "auto" },
        fullscreen: { type: "fullscreen", params: 100, value: "100vh" },
        fixed: { type: "fixed", params: 200, value: "200px" },
    };

    const options = optionList.map((item) => {
        return {
            key: item[0],
            text: item[0],
            value: item[1],
        };
    });

    const handleChange = (e, { value }) => {
        log.d(value, "Changing Height to");
        onValue(lookup[value]);
    };

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "3fr 2fr",
                gap: "5px",
            }}
        >
            <div>
                <Form.Field>
                    <label>{key}</label>
                    <Dropdown
                        placeholder="Select Option"
                        options={options}
                        selection
                        value={value.type}
                        onChange={handleChange}
                        fluid
                    />
                </Form.Field>
            </div>
            {value.type === "fullscreen" && (
                <div>
                    <Form.Field>
                        <label>Percentage</label>
                        <Form.Input
                            placeholder="100"
                            fluid
                            value={value.params}
                            onChange={(eve) => {
                                onValue({
                                    type: value.type,
                                    params: eve.target.value,
                                    value: eve.target.value + "vh",
                                });
                            }}
                        />
                    </Form.Field>
                </div>
            )}
            {value.type === "fixed" && (
                <div>
                    <Form.Field>
                        <label>Pixels</label>
                        <Form.Input
                            placeholder="100"
                            fluid
                            value={value.params}
                            onChange={(eve) => {
                                onValue({
                                    type: value.type,
                                    params: eve.target.value,
                                    value: eve.target.value + "px",
                                });
                            }}
                        />
                    </Form.Field>
                </div>
            )}
        </div>
    );
};

// combiner= a function to convert from params to value
export const fourSide = (label, value, setValue, combiner) => {
    const params = value.params;
    const sliderValue = value.type === "single" ? params : params[params.cside];

    const onChange = (newVal) => {
        if (value.type === "single") {
            setValue({
                type: "single",
                params: newVal,
                value: `${newVal}px`,
            });
        } else {
            setValue({
                type: "multiple",
                params: {
                    ...params,
                    [params.cside]: newVal,
                },
                value: combiner(params),
            });
        }
    };

    const onSideChange = (side) => {
        if (value.type === "single") {
            setValue({
                ...value,
                type: "multiple",
                params: {
                    cside: side,
                    top: value.params,
                    right: value.params,
                    bottom: value.params,
                    left: value.params,
                },
            });
        } else {
            setValue({
                ...value,
                type: "multiple",
                params: { ...params, cside: side },
            });
        }
    };
    return (
        <Form.Field key={label}>
            <label>{label}</label>
            <div className={styles.grid5}>
                {[
                    ["top", "arrow up"],
                    ["right", "arrow right"],
                    ["bottom", "arrow down"],
                    ["left", "arrow left"],
                ].map((item, index) => {
                    return (
                        <Button
                            key={index}
                            className={styles.item}
                            icon
                            onClick={() => {
                                onSideChange(item[0]);
                            }}
                            primary={params.cside === item[0]}
                        >
                            <Icon name={item[1]} />
                        </Button>
                    );
                })}

                <Button
                    className={styles.item}
                    icon
                    onClick={() => {
                        const temp =
                            value.type === "single"
                                ? params
                                : parseInt(
                                      (params.left +
                                          params.right +
                                          params.top +
                                          params.bottom) /
                                          4
                                  );
                        setValue({
                            type: "single",
                            params: temp,
                            value: temp,
                        });
                    }}
                    primary={value.type === "single"}
                >
                    <Icon
                        name={value.type === "single" ? "lock" : "lock open"}
                    />
                </Button>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                <Form.Field style={{ flex: 1 }}>
                    <label style={{ marginBottom: "25px" }}></label>
                    <Range
                        step={1}
                        min={0}
                        max={100}
                        // max={max}
                        values={[sliderValue]}
                        onChange={(values) => {
                            log.p("Range changed to " + value);
                            onChange(values[0]);
                        }}
                        renderTrack={({ props, children }) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: "6px",
                                    width: "100%",
                                    backgroundColor: "#5f9de8",
                                    borderRadius: "2px",
                                }}
                            >
                                {children}
                            </div>
                        )}
                        renderThumb={({ props }) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: "15px",
                                    width: "15px",
                                    backgroundColor: "white",
                                    border: "1px solid black",
                                    borderRadius: "2px",
                                }}
                            />
                        )}
                    />
                </Form.Field>

                <Form.Input
                    style={{
                        width: "70px",
                        marginTop: "8px",
                        paddingLeft: "1px",
                    }}
                    type="number"
                    value={sliderValue}
                    onChange={(eve) => onChange(parseInt(eve.target.value))}
                />
            </div>
        </Form.Field>
    );
};

// Seperate panel with name ANIMATION
export const transform = (label, value, setValue) => {
    const params = value.params;
    log.d(value, "Tarnsform Value");

    const combiner = (params) => {
        let value = "";
        Object.keys(params).map((item) => {
            switch (item) {
                case "rotate":
                    value += ` rotate(${params[item]}deg)`;
                case "translateX":
                case "translateY":
                    value += ` translate(${params["translateX"]}px, ${params["translateY"]}px)`;
            }
        });
        return value;
    };

    return (
        <Fragment>
            <p>Open seperate panel here with name ANIMATE</p>
            {rangeOption(
                "Rotate",
                params.rotate,
                (newVal) => {
                    setValue({
                        ...value,
                        params: {
                            ...value.params,
                            rotate: newVal,
                        },
                        value: combiner({
                            ...value.params,
                            rotate: newVal,
                        }),
                    });
                },
                0,
                180
            )}
            {rangeOption(
                "Move Horizontal",
                params.translateX,
                (newVal) => {
                    setValue({
                        ...value,
                        params: {
                            ...value.params,
                            translateX: newVal,
                        },
                        value: combiner({
                            ...value.params,
                            translateX: newVal,
                        }),
                    });
                },
                -50,
                50
            )}
            {rangeOption(
                "Move Vertical",
                params.translateY,
                (newVal) => {
                    setValue({
                        ...value,
                        params: {
                            ...value.params,
                            translateY: newVal,
                        },
                        value: combiner({
                            ...value.params,
                            translateY: newVal,
                        }),
                    });
                },
                -50,
                50
            )}
        </Fragment>
    );
};

export const imgSizeOption = (label, value, setValue) => {
    const optionList = [
        ["Custom", "custom"],
        ["Full", "full"],
        ["Mini", "mini"],
        ["Small", "small"],
        ["Medium", "medium"],
        ["Big", "big"],
        ["Large", "large"],
    ];

    const LOOKUP = {
        full: { params: 0, value: "100%" },
        mini: { params: 50, value: "50px" },
        small: { params: 100, value: "100px" },
        medium: { params: 250, value: "250px" },
        big: { params: 500, value: "500px" },
        large: { params: 1000, value: "1000px" },
        custom: {
            params: value.params > 0 ? value.params : 250,
            value: `${value.params > 0 ? value.params : 250}px`,
        },
    };

    const options = optionList.map((item) => {
        return {
            key: item[0],
            text: item[0],
            value: item[1],
        };
    });

    const handleChange = (e, { value }) =>
        setValue({
            type: value,
            ...LOOKUP[value],
        });

    return (
        <React.Fragment key={label}>
            <Form.Field key={label}>
                <label>{label}</label>
                <Dropdown
                    placeholder={label}
                    options={options}
                    selection
                    value={value.type}
                    onChange={handleChange}
                />
            </Form.Field>
            {value.type === "custom" &&
                rangeOption(
                    "Custom Image Size",
                    value.params,
                    (newVal) =>
                        setValue({
                            type: "custom",
                            params: newVal,
                            value: `${newVal}px`,
                        }),
                    10,
                    1000
                )}
        </React.Fragment>
    );
};

export const iconSizeOption = (label, value, setValue) => {
    const optionList = [
        ["Custom", "custom"],
        ["Mini", "mini"],
        ["Small", "small"],
        ["Medium", "medium"],
        ["Big", "big"],
        ["Large", "large"],
    ];

    const LOOKUP = {
        mini: { params: 15, value: "15px" },
        small: { params: 24, value: "24px" },
        medium: { params: 40, value: "40px" },
        big: { params: 70, value: "70px" },
        large: { params: 120, value: "120px" },
        custom: {
            params: value.params > 0 ? value.params : 40,
            value: `${value.params > 0 ? value.params : 40}px`,
        },
    };

    const options = optionList.map((item) => {
        return {
            key: item[0],
            text: item[0],
            value: item[1],
        };
    });

    const handleChange = (e, { value }) =>
        setValue({
            type: value,
            ...LOOKUP[value],
        });

    return (
        <React.Fragment key={label}>
            <Form.Field key={label}>
                <label>{label}</label>
                <Dropdown
                    placeholder={label}
                    options={options}
                    selection
                    value={value.type}
                    onChange={handleChange}
                />
            </Form.Field>
            {value.type === "custom" &&
                rangeOption(
                    "Custom Image Size",
                    value.params,
                    (newVal) =>
                        setValue({
                            type: "custom",
                            params: newVal,
                            value: `${newVal}px`,
                        }),
                    1,
                    500
                )}
        </React.Fragment>
    );
};

export const htmlOption = (label, value, setValue) => {
    log.d(value, "Value in HTML Option");
    return (
        <Form.Field>
            <QurealTextEditorDialog
                html={value}
                setHtml={setValue}
                trigger={
                    <Button primary icon labelPosition="left">
                        <Icon name="edit" /> Edit
                    </Button>
                }
            />
        </Form.Field>
    );
};

function PageDropdown({ value, setValue }) {
    const pages = useRecoilValue(pageState);

    const getPageOptions = () => {
        return pages.map((item) => {
            return {
                key: item.id,
                text: item.name,
                value: item,
            };
        });
    };

    return (
        <Dropdown
            placeholder="Select Page"
            options={getPageOptions()}
            selection
            value={value.params}
            onChange={(e, { value: newParam }) => {
                setValue({
                    ...value,
                    params: newParam,
                    value: newParam,
                });
            }}
            fluid
        />
    );
}

export const actionOption = (label, value, setValue) => {
    // const OPTIONS = ["none", "page", "link", "file", "scrollTo"];
    const OPTIONS = ["link", "page"];
    const options = OPTIONS.map((item) => {
        return {
            key: item,
            text: startCase(item),
            value: item,
        };
    });

    const handleChange = (e, { value: newType }) => {
        setValue({
            type: newType,
            newTab: false,
        });
    };

    return (
        <React.Fragment key={label}>
            <Form.Field>
                <label>{label}</label>
                <Dropdown
                    placeholder="Select Option"
                    options={options}
                    selection
                    value={value.type}
                    onChange={handleChange}
                    fluid
                />
            </Form.Field>
            {value.type === "page" && (
                <Form.Field>
                    <label>Page Number</label>
                    <Input
                        value={value.params}
                        type="number"
                        onChange={(e) =>
                            setValue({
                                ...value,
                                params: e.target.value,
                                value: e.target.value,
                            })
                        }
                    />
                </Form.Field>
            )}
            {/* {value.type === "page" && (
                <Form.Field>
                    <label>Select Page</label>
                    <PageDropdown value={value} setValue={setValue} />
                </Form.Field>
            )} */}
            {value.type === "link" &&
                textOption("Web Address", value.params, (newVal) =>
                    setValue({
                        ...value,
                        params: newVal,
                        value: newVal.startsWith("http")
                            ? newVal
                            : "http://" + newVal,
                    })
                )}

            {["link"].includes(value.type) &&
                booleanOption("Open in New Tab", value.newTab, (newNewTab) =>
                    setValue({ ...value, newTab: newNewTab })
                )}
        </React.Fragment>
    );
};

export const shapeClipOption = (label, value, setValue, gs) => {
    const options = Object.keys(SHAPES).map((item) => {
        return {
            key: item,
            text: startCase(item),
            value: item,
        };
    });

    const handleChange = (e, { value: newType }) => {
        setValue({
            ...value,
            type: newType,
            path: SHAPES[newType],
        });
    };

    return (
        <Fragment>
            <Form.Field>
                <label>{label}</label>
                <Dropdown
                    placeholder="Select Option"
                    options={options}
                    selection
                    value={value.type}
                    onChange={handleChange}
                    fluid
                />
            </Form.Field>

            {value.type !== "none" && (
                <Fragment>
                    {colorOption(
                        "Shape Color",
                        value.color,
                        (newCol) =>
                            setValue({
                                ...value,
                                color: newCol,
                            }),
                        gs
                    )}
                    {optionRowIcon(
                        "Shape Position",
                        value.position,
                        (newPos) =>
                            setValue({
                                ...value,
                                position: newPos,
                            }),
                        [
                            ["top", "long arrow alternate up"],
                            ["bottom", "long arrow alternate down"],
                        ]
                    )}
                    {rangeOption(
                        "Shape Height",
                        value.height,
                        (newHeight) =>
                            setValue({
                                ...value,
                                height: newHeight,
                            }),
                        5,
                        200,
                        5
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export const frameShapeOption = (label, value, setValue) => {
    return (
        <React.Fragment key={label}>
            {optionRowIcon(
                label,
                value.type,
                (newVal) =>
                    setValue({
                        type: newVal,
                        params:
                            newVal === "custom"
                                ? {
                                      type: "single",
                                      params: 10,
                                      value: 10,
                                  }
                                : newVal,
                        value: newVal === "custom" ? 10 : newVal,
                    }),
                [
                    ["circle", "circle"],
                    ["square", "square"],
                    ["custom", "pencil square"],
                ]
            )}
            {value.type === "custom" &&
                fourSide(
                    "Border Radius",
                    value.params,
                    (newVal) =>
                        setValue({
                            type: "custom",
                            params: newVal,
                            value: newVal.value,
                        }),
                    ({ top, right, bottom, left }) => {
                        return `${top}px ${right}px ${bottom}px ${left}px`;
                    }
                )}
        </React.Fragment>
    );
};

export const borderOption = (label, value, setValue) => {
    const combiner = (type, params) => {
        return `${params.width}px ${type} ${params.color}`;
    };
    return (
        <React.Fragment key={label}>
            {optionRowIcon(
                label,
                value.type,
                (newVal) =>
                    setValue({
                        type: newVal,
                        params: {
                            width: 2,
                            color: "black",
                        },
                        value:
                            newVal === "none"
                                ? "none"
                                : combiner(newVal, {
                                      width: 2,
                                      color: "black",
                                  }),
                    }),
                [
                    ["none", "dont"],
                    ["solid", "square outline"],
                    ["dashed", "expand"],
                ]
            )}

            {value.type !== "none" &&
                rangeOption(
                    "Border Width",
                    value.params.width,
                    (newWidth) => {
                        setValue({
                            ...value,
                            params: { ...value.params, width: newWidth },
                            value: combiner(value.type, {
                                ...value.params,
                                width: newWidth,
                            }),
                        });
                    },
                    1,
                    20,
                    1
                )}

            {value.type !== "none" &&
                colorOption("Border Color", value.params.color, (newColor) => {
                    setValue({
                        ...value,
                        params: { ...value.params, color: newColor },
                        value: combiner(value.type, {
                            ...value.params,
                            color: newColor,
                        }),
                    });
                })}
        </React.Fragment>
    );
};

export const headerTagOption = (label, value, setValue) => {
    const optionList = [
        ["Custom", "div"],
        ["Huge", "h1"],
        ["Big", "h2"],
        ["Large", "h3"],
        ["Small", "h4"],
        ["Tiny", "h5"],
        ["Mini", "h6"],
    ];

    const LOOKUP = {
        h1: { params: 36, value: "36px" },
        h2: { params: 30, value: "30px" },
        h3: { params: 24, value: "24px" },
        h4: { params: 20, value: "20px" },
        h5: { params: 18, value: "18px" },
        h6: { params: 16, value: "16px" },
        div: {
            params: value.params > 0 ? value.params : 20,
            value: `${value.params > 0 ? value.params : 20}px`,
        },
    };

    const options = optionList.map((item) => {
        return {
            key: item[0],
            text: item[0],
            value: item[1],
        };
    });

    const handleChange = (e, { value }) =>
        setValue({
            type: value,
            ...LOOKUP[value],
        });

    return (
        <React.Fragment key={label}>
            <Form.Field key={label}>
                <label>{label}</label>
                <Dropdown
                    placeholder={label}
                    options={options}
                    selection
                    value={value.type}
                    onChange={handleChange}
                />
            </Form.Field>
            {value.type === "div" &&
                rangeOption(
                    "Custom Font Size",
                    value.params,
                    (newVal) =>
                        setValue({
                            type: "div",
                            params: newVal,
                            value: `${newVal}px`,
                        }),
                    5,
                    150
                )}
        </React.Fragment>
    );
};

export const pageSizeOption = (label, value, setValue) => {
    log.d(value, "PageSizeValue");
    const optionList = [
        ["Custom", "custom"],
        ["Magazine (11x14)", "magazine"],
        ["A4 (70x99)", "a4"],
        ["Square (1x1)", "square"],
        ["Landscape (5x4)", "landscape"],
        ["Portrait (4x5)", "portrait"],
        ["Trade (13x20)", "trade"],
    ];

    const LOOKUP = {
        magazine: { width: 440, height: 560 },
        a4: { width: 420, height: 594 },
        square: { width: 480, height: 480 },
        landscape: { width: 500, height: 400 },
        portrait: { width: 400, height: 500 },
        trade: { width: 390, height: 600 },
        custom: value.value,
    };

    const options = optionList.map((item) => {
        return {
            key: item[0],
            text: item[0],
            value: item[1],
        };
    });

    const handleChange = (e, { value }) => {
        log.d(
            {
                type: value,
                value: LOOKUP[value],
            },
            "setting value to"
        );
        setValue({
            type: value,
            value: LOOKUP[value],
        });
    };
    return (
        <React.Fragment key={label}>
            <Form.Field key={label}>
                <label>{label}</label>
                <Dropdown
                    placeholder={label}
                    options={options}
                    selection
                    value={value.type}
                    onChange={handleChange}
                />
            </Form.Field>
            {value.type === "custom" &&
                rangeOption(
                    "Width",
                    value.value.width,
                    (newVal) =>
                        setValue({
                            type: "custom",
                            value: {
                                width: newVal,
                                height: value.value.height,
                            },
                        }),
                    10,
                    1000
                )}
            {value.type === "custom" &&
                rangeOption(
                    "Height",
                    value.value.height,
                    (newVal) =>
                        setValue({
                            type: "custom",
                            value: {
                                width: value.value.width,
                                height: newVal,
                            },
                        }),
                    10,
                    1000
                )}
        </React.Fragment>
    );
};
