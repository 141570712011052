import React, { useState } from "react";
import Gallery from "react-photo-gallery";
import log from "cslog";
import axios from "axios";
import { Button, Icon, Form } from "semantic-ui-react";

import CImage from "./CImage";

import { useRecoilState } from "recoil";
import { selectedImageState } from "../db/elementDb";
import CameraDialog from "./CameraDialog";

const ImageLibrary = ({ placeholder, cam = false }) => {
    const [data, setData] = useState();
    const [uploading, setUploading] = useState(false);
    const [selectedImage, setSelectedImage] =
        useRecoilState(selectedImageState);
    const [camopen, setCamopen] = useState(cam);

    React.useEffect(() => {
        axios
            .get("/cdnn/photos/list")
            // .get("/sb/hello")
            // .get("http://www.test-magazine.qureal.com/cdnn//photos")
            .then((res) => {
                log.d(res.data, "Photos Response");
                if (!data) {
                    setData(res.data);
                }
            })
            .catch((err) => {
                log.d(err, "Photo Error");
                log.d(err.response, "Erro Responase");
            });
    }, [setData, data]);

    const uploadImage = (title, photo, name = null) => {
        setUploading(true);
        const formD = new FormData();
        formD.append("title", title);
        if (name) {
            // formD.append("original", photo, name);
            formD.append("image", photo, name);
        } else {
            // formD.append("original", photo);
            formD.append("image", photo);
        }
        axios
            .post("/cdnn/photos/", formD)
            .then((response) => {
                log.d(response.data, "Upload Response");
                setData([...data, response.data]);
                // if (!data) {
                //     setData(response.data);
                // }
            })
            .catch((err) => {
                log.d(err, "Upload Error");
            })
            .finally(() => {
                setUploading(false);
            });
    };

    const onFile = (eve) => {
        console.log("Got Image");
        const photo = eve.target.files[0];
        if (photo) {
            if (typeof photo === "object" && photo !== null) {
                uploadImage("Uploaded", photo);
            }
        }
    };

    log.d(data, "Data");
    log.d(data?.lenght, "Data Length");

    return (
        <div>
            {data ? (
                <div>
                    <Button
                        icon
                        primary
                        labelPosition="left"
                        onClick={(eve) => {
                            eve.preventDefault();
                            document.getElementById("photo-in-zzz").click();
                        }}
                        loading={uploading}
                    >
                        Upload Image
                        <Icon name="upload" />
                    </Button>
                    <Button
                        icon
                        primary
                        labelPosition="left"
                        onClick={() => setCamopen(true)}
                    >
                        Camera
                        <Icon name="camera" />
                    </Button>
                    <CameraDialog
                        open={camopen}
                        setOpen={(value) => setCamopen(value)}
                        onPhoto={(photo) =>
                            uploadImage("Captured", photo, "captured.jpg")
                        }
                    />
                    <input
                        type="file"
                        name="photo"
                        onChange={onFile}
                        id="photo-in-zzz"
                        hidden
                    />

                    {/* <Divider /> */}
                    {data.length > 0 ? (
                        <div
                            style={{
                                height: "70vh",
                                overflow: "auto",
                                marginTop: "10px",
                            }}
                        >
                            <Gallery
                                direction={"column"}
                                columns={3}
                                renderImage={CImage}
                                photos={data.map((item) => {
                                    return {
                                        src: item.thumbnail,
                                        width: item.width,
                                        height: item.height,
                                        id: item.id,
                                        sid: selectedImage.id,
                                        onPhotoClick: (img) => {
                                            const imgObj = {
                                                id: item.id,
                                                width: item.width,
                                                height: item.height,
                                                description: item.title,
                                                thumb: item.thumbnail,
                                                full: item.original,
                                            };
                                            setSelectedImage(imgObj);
                                        },
                                    };
                                })}
                            />
                        </div>
                    ) : (
                        <h3 style={{ color: "gray" }}>No photo uploaded</h3>
                    )}
                </div>
            ) : (
                placeholder
            )}
        </div>
    );

    return (
        <div
            style={{
                height: "60vh",
                overflow: "auto",
            }}
        >
            {data ? (
                <Gallery
                    direction={"column"}
                    columns={3}
                    renderImage={CImage}
                    photos={data.map((item) => {
                        return {
                            src: item.thumbnail,
                            width: item.width,
                            height: item.height,
                            id: item.id,
                            sid: selectedImage.id,
                            onPhotoClick: (img) => {
                                console.log("Image Selected");
                                setSelectedImage(img);
                            },
                        };
                    })}
                />
            ) : (
                placeholder
            )}
        </div>
    );
};

export default ImageLibrary;
