/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { createRef, useRef, useEffect, useState, Fragment } from "react";
import HTMLFlipBook from "react-pageflip";
import log from "cslog";
import wood_bg from "./assets/wood.jpg";
import styles from "./styles/BookView.module.css";
import { useWindowDimensions } from "./hooks";
import {
    useRecoilCallback,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState,
} from "recoil";
import { previewState } from "../db/previewDb";
import {
    bookContentPageState,
    bookLayerState,
    bookLayoutState,
    bookPageArray,
    bookPageArrayState,
} from "../db/bookConfig";
import Element from "../Element";
import { globalConfigState, gstyleArrayState } from "../db/gstyleDb";
import { editorConfigState } from "../db/editorConfig";
import classNames from "classnames";
// import BookPage from "../elements_freeview_static/book/BookPage";
// import BookPage from "../elements_freeview_static/book/BookPage";
import BookPage from "../elements_freeview_static/book/BookPagePreview";
import useMedia from "react-use/lib/useMedia";
import { Menu, Icon } from "semantic-ui-react";
import {
    TransformWrapper,
    TransformComponent,
    Pan,
} from "react-zoom-pan-pinch";
import BookControls from "./BookControls";
import ReactDOMServer from "react-dom/server";
import { extractFullSiteDataReadable } from "../data/extractor";

const BookStaticView = () => {
    const { width, height } = useWindowDimensions();
    const [preview, setPreview] = useRecoilState(previewState);
    // const bookPageArray = useRecoilValue(bookPageArrayState);
    const bookPageArray = useRecoilValue(bookContentPageState);
    const gs = useRecoilValue(gstyleArrayState);
    const editorConfig = useRecoilValue(editorConfigState);
    const bookRef = useRef();
    const [bookLayout, setBookLayout] = useRecoilState(bookLayoutState);
    const [cpage, setCpage] = useState(0);
    const [zoom, setZoom] = useState(1);
    const isMobile = useMedia("(max-width: 880px)");
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [totalPagesCount, setTotalPagesCount] = useState(2);
    // const [isExtraPage, setIsExtraPage] = useState(false);
    const [pagesDb, setPagesDb] = useState({});
    const globalConfig = useRecoilValue(globalConfigState);

    log.d(isMobile, "isMobile");

    // log.d(pageArray, "Page ARRAY");

    // const dims = bookLayout.pageSize.value;

    const ratio = bookLayout.pageSize.value;

    useEffect(() => {
        let totalPagesCount = bookPageArray.length;
        // let isExtraPage = totalPagesCount % 2 !== 0;
        // totalPagesCount += isExtraPage ? 3 : 2;
        setTotalPagesCount(totalPagesCount);
        // setIsExtraPage(isExtraPage);
    }, []);

    useEffect(() => {
        const mul = isMobile ? 1 : 2;
        // const margin = isMobile ? 2 : 20;
        const marginTop = isMobile ? 2 : 15;
        const marginBottom = isMobile ? 50 : 50;
        const marginH = isMobile ? 2 : 70;
        const hZoom = (width - marginH * 2) / (ratio.width * mul);
        const vZoom = (height - marginTop - marginBottom) / ratio.height;

        log.d(hZoom, "hZoom");
        log.d(vZoom, "vZoom");
        setZoom(hZoom < vZoom ? hZoom : vZoom);
        // setBookLayout({
        //     ...bookLayout,
        //     width: Math.floor((height * ratio.w) / ratio.h),
        // });
    }, [width, height, isMobile, ratio]);

    //#####################################################################

    const extractPageData = useRecoilCallback(({ snapshot }) => async () => {
        const out = await extractFullSiteDataReadable(snapshot, null);
        return out;
    });

    useEffect(() => {
        async function getData() {
            log.p("Loading Data");
            const out = await extractPageData();
            log.d(out, "OUT");
            const pages = out["pages"];
            const db = {};
            pages.forEach((item) => {
                db[item.pid] = item.html;
            });
            setPagesDb(db);
        }

        getData();
    }, []);

    // useEffect(() => {
    async function downloadFile(fileName, data) {
        const blob = new Blob([data], { type: "application/json" });
        // const href = await URL.createObjectURL(blob);
        const href = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function getBookPageHTML(id) {
        const dd = ReactDOMServer.renderToStaticMarkup(
            <BookPage
                // id="firstPage"
                // db={pagesDb["firstPage"]}
                id={id}
                db={pagesDb[id]}
                globalDb={pagesDb["globalPage"]}
                device={editorConfig.editorView}
                gs={gs}
                globalConfig={globalConfig}
                bookLayout={bookLayout}
                preview={preview}
            />
        );
        return dd;
    }

    async function generateHTML() {
        log.p("Generating HTML Now");
        const out = {
            bookLayout: bookLayout,
        };
        const pages = {};
        bookPageArray.forEach((item) => {
            const html = getBookPageHTML(item.pid);
            pages[item.pid] = html;
            log.d(html, "Static Markup: " + item.pid);
        });

        out["pages"] = pages;
        out["globalPage"] = getBookPageHTML("globalPage");

        downloadFile("elements.json", JSON.stringify(out));
    }

    // if ("globalPage" in pagesDb) {
    //     generateHTML();
    // }
    // }, [pagesDb]);

    const nowGenerateIt = async () => {
        if ("globalPage" in pagesDb) {
            generateHTML();
        }
    };

    //#####################################################################

    const next = () => bookRef.current.pageFlip().flipNext();
    const prev = () => bookRef.current.pageFlip().flipPrev();
    const flipTo = (pno) => bookRef.current.pageFlip().flip(pno);

    const toggleFS = () => {
        var doc = window.document;
        var docEl = doc.body;

        var requestFullScreen =
            docEl.requestFullscreen ||
            docEl.mozRequestFullScreen ||
            docEl.webkitRequestFullScreen ||
            docEl.msRequestFullscreen;
        var cancelFullScreen =
            doc.exitFullscreen ||
            doc.mozCancelFullScreen ||
            doc.webkitExitFullscreen ||
            doc.msExitFullscreen;

        if (
            !doc.fullscreenElement &&
            !doc.mozFullScreenElement &&
            !doc.webkitFullscreenElement &&
            !doc.msFullscreenElement
        ) {
            requestFullScreen.call(docEl);
        } else {
            cancelFullScreen.call(doc);
        }

        setIsFullScreen(!isFullScreen);
    };

    return (
        <div
            className={styles.book_wrapper}
            style={{
                backgroundImage: `url(${wood_bg})`,
                // backgroundImage: `url(${wood_bg2})`,
                backgroundSize: "cover",
            }}
        >
            <TransformWrapper
                // pan={{ disabled: !isZoomed }}
                wheel={{
                    step: 80,
                }}
                zoomIn={{
                    stop: 40,
                }}
                doubleClick={{
                    mode: "reset",
                }}
                onZoomChange={(data) => {
                    log.d(data, "NewZoomData");
                }}

                // onZoomChange={(newZoom) => setScale(newZoom.scale)}
            >
                {({ zoomIn, zoomOut, resetTransform, scale, ...rest }) => {
                    // const bookEnabled = scale === 1;
                    // if (scale === 1 && isZoomed) {
                    //     setIsZoomed(false);
                    // } else if (scale !== 1 && !isZoomed) {
                    //     setIsZoomed(true);
                    // }
                    return (
                        <Fragment>
                            <TransformComponent>
                                <div
                                    className={styles.inner_wrapper}
                                    style={{
                                        paddingTop: "15px",
                                    }}
                                >
                                    <div
                                        className={styles.half_left}
                                        onClick={prev}
                                    ></div>
                                    <div
                                        className={styles.half_right}
                                        onClick={next}
                                    ></div>
                                    <HTMLFlipBook
                                        ref={bookRef}
                                        width={ratio.width}
                                        height={ratio.height}
                                        // width={dims.width}
                                        // height={dims.height}
                                        useMouseEvents={false}
                                        autoSize={!isMobile}
                                        maxShadowOpacity={0.5}
                                        showCover={true}
                                        flippingTime={2000}
                                        onFlip={({ data }) => setCpage(data)}
                                        className={
                                            cpage === 0
                                                ? styles.first_page_view
                                                : cpage ===
                                                  bookPageArray.length + 2
                                                ? styles.last_page_view
                                                : styles.mid_page_view
                                        }
                                        style={{
                                            // zoom: zoom,
                                            transform: `scale(${zoom})`,
                                            transformOrigin: "center top",
                                            // boxShadow:
                                            //     "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                                        }}
                                    >
                                        {/* {pagesDb.map((item, index) => { */}
                                        {bookPageArray.map((item, index) => {
                                            const db = pagesDb[item.pid];
                                            if (!db) {
                                                return <div>Loading...</div>;
                                            }
                                            // log.d(db, "DB :" + item.pid);
                                            return (
                                                <div
                                                    className={classNames(
                                                        styles.book_page
                                                        // index % 2 === 0
                                                        //     ? styles.book_page_stack_right
                                                        //     : styles.book_page_stack_left
                                                    )}
                                                >
                                                    <BookPage
                                                        id={item.pid}
                                                        db={db}
                                                        globalDb={
                                                            pagesDb[
                                                                "globalPage"
                                                            ]
                                                        }
                                                        device={
                                                            editorConfig.editorView
                                                        }
                                                        gs={gs}
                                                        globalConfig={
                                                            globalConfig
                                                        }
                                                        bookLayout={bookLayout}
                                                        preview={preview}
                                                    />
                                                    <div
                                                        className={
                                                            index % 2 === 0
                                                                ? styles.page_overlay_right
                                                                : // : null
                                                                  styles.page_overlay_left
                                                        }
                                                    ></div>
                                                </div>
                                            );
                                        })}
                                    </HTMLFlipBook>
                                </div>
                            </TransformComponent>

                            <div className={styles.bottom_panel}>
                                <BookControls
                                    // currentPage={bookRef.current
                                    //     .pageFlip()
                                    //     .getCurrentPageIndex()}
                                    isMobile={isMobile}
                                    next={next}
                                    prev={prev}
                                    first={() => flipTo(0)}
                                    last={() => flipTo(totalPagesCount - 1)}
                                    fullScreen={() => toggleFS()}
                                    zoomIn={zoomIn}
                                    zoomOut={zoomOut}
                                    currentPage={cpage + 1}
                                    totalPages={totalPagesCount}
                                />
                            </div>
                        </Fragment>
                    );
                }}
            </TransformWrapper>

            <div>
                <button
                    className={classNames(styles.corner_btn, styles.back_btn)}
                    onClick={() => setPreview({ isPreview: false })}
                >
                    <Icon name="arrow left" />
                </button>
                <button
                    className={classNames(styles.corner_btn, styles.pages_btn)}
                    onClick={() => setPreview({ isPreview: false })}
                >
                    <Icon name="th large" />
                </button>
                <button
                    className={classNames(styles.corner_btn, styles.info_btn)}
                    onClick={() => setPreview({ isPreview: false })}
                >
                    <Icon name="users" />
                </button>
                <button
                    className={classNames(styles.corner_btn, styles.theme_btn)}
                    onClick={nowGenerateIt}
                >
                    <Icon name="paint brush" />
                </button>
                <button
                    className={classNames(styles.side_btn, styles.prev_btn)}
                    onClick={prev}
                >
                    <Icon name="angle left" size="huge" />
                </button>
                <button
                    className={classNames(styles.side_btn, styles.next_btn)}
                    onClick={next}
                >
                    <Icon name="angle right" size="huge" />
                </button>
            </div>
        </div>
    );
};
export default BookStaticView;
