import React from "react";
import log from "cslog";
import { nanoid } from "nanoid";
import { elementState, addElement } from "../db/elementDb";
import {
    gstyleConfigState,
    gstyleArrayState,
    globalConfigState,
} from "../db/gstyleDb";
import { pageState, menuState } from "../db/siteConfig";
import {
    bookInfoState,
    bookLayoutState,
    bookPageArrayState,
} from "../db/bookConfig";
import { seoBasicState } from "../db/seoDb";
import { cloneDeep } from "lodash";
import { TOP_HEADER_ID } from "../data/defaults";

export const extractData = async (id, pid, data, snapshot, arr) => {
    data = cloneDeep(data);
    data = { ...data, id: id, pid: pid };
    if ("sections" in data) {
        await Promise.all(
            data.sections.map(async (child, index) => {
                const childId = "q_" + nanoid(10);
                data.sections[index] = childId;
                let childData = await snapshot.getPromise(elementState(child));
                childData = { ...childData, id: childId };
                await extractData(childId, id, childData, snapshot, arr);
            })
        );
    } else if ("child" in data) {
        const cid = "q_" + nanoid(10);
        const cdata = await snapshot.getPromise(elementState(data.child));
        await extractData(cid, id, cdata, snapshot, arr);
        data = { ...data, child: cid };
    } else if ("childs" in data) {
        // log.d(data, "DATA");
        if (Array.isArray(data.childs)) {
            await Promise.all(
                data.childs.map(async (child, index) => {
                    const childId = "q_" + nanoid(10);
                    // data.childs[index] = childId;
                    data.childs[index] = child;
                    let childData = await snapshot.getPromise(
                        elementState(child)
                    );
                    // childData = { ...childData, id: childId };
                    childData = { ...childData, id: child };
                    // await extractData(childId, id, childData, snapshot, arr);
                    await extractData(child, id, childData, snapshot, arr);
                })
            );
        } else {
            await Promise.all(
                Object.keys(data.childs).map(async (child, index) => {
                    const childId = "q_" + nanoid(10);
                    // data.childs[index] = childId;
                    data.childs[child] = data.childs[child];
                    let childData = await snapshot.getPromise(
                        elementState(child)
                    );
                    // childData = { ...childData, id: childId };
                    childData = { ...childData, id: child };
                    // await extractData(childId, id, childData, snapshot, arr);
                    await extractData(child, id, childData, snapshot, arr);
                })
            );
        }
    }
    if ("bg" in data) {
        log.p("Making Promise");
        await Promise.all(
            data.bg.map(async (child, index) => {
                const childId = "q_" + nanoid(10);
                // data.childs[index] = childId;
                data.bg[index] = child;
                let childData = await snapshot.getPromise(elementState(child));
                // childData = { ...childData, id: childId };
                childData = { ...childData, id: child };
                // await extractData(childId, id, childData, snapshot, arr);
                await extractData(child, id, childData, snapshot, arr);
            })
        );
    }

    arr[id] = data;
};

export const cultivateData = async (id, pid, data, db) => {
    // log.h3("Cultivating Data");
    // log.d(id, "ID");
    // log.d(pid, "PID");
    // log.d(data, "DATA");
    // log.d(db, "DB");
    data = cloneDeep(data);
    data = { ...data, id: id, pid: pid };
    if ("sections" in data) {
        data.sections.map(async (child, index) => {
            // const childId = nanoid(10);
            const childId = child;
            data.sections[index] = childId;
            let childData = db[child];
            childData = { ...childData, id: childId };
            cultivateData(childId, id, childData, db);
        });
    } else if ("child" in data) {
        const cid = "q_" + nanoid(10);
        const cdata = db[data.child];
        cultivateData(cid, id, cdata, db);
        data = { ...data, child: cid };
    } else if ("childs" in data) {
        if (Array.isArray(data.childs)) {
            data.childs.map(async (child, index) => {
                // const childId = nanoid(10);
                const childId = child;
                data.childs[index] = childId;
                let childData = db[child];
                childData = { ...childData, id: childId };
                cultivateData(childId, id, childData, db);
            });
        } else {
            Object.keys(data.childs).map(async (child, index) => {
                // const childId = nanoid(10);
                const childId = child;
                // data.childs[index] = childId;
                let childData = db[child];
                childData = { ...childData, id: childId };
                cultivateData(childId, id, childData, db);
            });
        }
    }
    if ("bg" in data) {
        data.bg.map(async (child, index) => {
            // const childId = nanoid(10);
            const childId = child;
            data.bg[index] = childId;
            let childData = db[child];
            childData = { ...childData, id: childId };
            cultivateData(childId, id, childData, db);
        });
    }
    addElement(id, data);
};

export const extractFullSiteData = async (snapshot, config) => {
    const data = {};
    log.h4("Extracting Full Book Data");

    if (config) {
        data["name"] = config.name;
        data["cats"] = config.categories;
        data["description"] = "";
        data["visibility"] = config.visibility;
        data["preview"] = config.preview?.id;
    }

    const bookInfo = await snapshot.getPromise(bookInfoState);
    data["info"] = JSON.stringify(bookInfo);
    data["layout"] = JSON.stringify(await snapshot.getPromise(bookLayoutState));
    // data["default_version"] = 0;
    data["gconfig"] = JSON.stringify({
        gconfig: await snapshot.getPromise(gstyleConfigState),
        globalConfig: await snapshot.getPromise(globalConfigState),
    });
    data["gstyle"] = JSON.stringify(
        await snapshot.getPromise(gstyleArrayState)
    );

    data["seo"] = JSON.stringify(await snapshot.getPromise(seoBasicState));
    // data["menu"] = JSON.stringify(await snapshot.getPromise(menuState));

    const pages = [];
    const pageslist = await snapshot.getPromise(bookPageArrayState);
    log.d(pageslist, "BookPageLIST");
    await Promise.all(
        pageslist.map(async (page) => {
            const telementArray = {};
            // const cid = "root";
            const cid = page.pid;
            const data = await snapshot.getPromise(elementState(page.pid));
            await extractData(cid, data.pid, data, snapshot, telementArray);
            pages.push({
                pid: page.pid,
                id: page.id,
                page_no: page.page_no,
                // name: config ? config.name + ": " + page.name : page.name,
                info: JSON.stringify(page),
                // visibility: config ? config.visibility : "PUBLIC",
                html: JSON.stringify(telementArray),
                book_id: bookInfo.id,
                // cats: [],
            });
        })
    );
    data["page_array"] = JSON.stringify(pageslist);
    // data["pages"] = pages;
    data["pages"] = JSON.stringify(pages);

    const globalConfig = await snapshot.getPromise(globalConfigState);
    data["others"] = JSON.stringify({
        // topHeader: topHeaderOut,
        globalConfig: globalConfig,
    });

    log.d(data, "FINAL WEBSITE DATA::::");
    return data;
};

export const extractFullSiteDataReadable = async (snapshot, config) => {
    const data = {};

    if (config) {
        data["name"] = config.name;
        data["cats"] = config.categories;
        data["description"] = "";
        data["visibility"] = config.visibility;
        data["preview"] = config.preview?.id;
    }

    data["info"] = await snapshot.getPromise(bookInfoState);
    data["layout"] = await snapshot.getPromise(bookLayoutState);
    data["default_version"] = 0;
    data["gconfig"] = await snapshot.getPromise(gstyleConfigState);
    data["gstyle"] = await snapshot.getPromise(gstyleArrayState);

    // data["seo"] = JSON.stringify(await snapshot.getPromise(seoBasicState));
    // data["menu"] = JSON.stringify(await snapshot.getPromise(menuState));

    const pages = [];
    const pageslist = await snapshot.getPromise(bookPageArrayState);
    log.d(pageslist, "BookPageLIST");
    await Promise.all(
        pageslist.map(async (page) => {
            const telementArray = {};
            const cid = page.pid;
            const data = await snapshot.getPromise(elementState(page.pid));
            await extractData(cid, data.pid, data, snapshot, telementArray);
            pages.push({
                pid: page.pid,
                id: page.id,
                page_no: page.page_no,
                // name: config ? config.name + ": " + page.name : page.name,
                info: page,
                visibility: config ? config.visibility : "PUBLIC",
                html: telementArray,
                cats: [],
            });
        })
    );
    data["page_array"] = pageslist;
    data["pages"] = pages;

    const globalConfig = await snapshot.getPromise(globalConfigState);
    data["others"] = {
        // topHeader: topHeaderOut,
        globalConfig: globalConfig,
    };

    log.d(data, "FINAL WEBSITE DATA::::");
    return data;
};
