/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import classNames from "classnames";
import React from "react";
import ContentEditable from "react-contenteditable";
import { mobile, tablet } from "../data/bp";
import { decodeStyle, parseStyle } from "../parser/styleparser";
import ElementWrapper from "./wrappers/ElementWrapper";

function Header({ id, db, index, setHoverIndex, direction, device, gs }) {
    // const [data, setData] = useRecoilState(elementState(id));

    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    let content = input?.content;

    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    // log.d(data.classes, " Classes in data");
    // log.d(classNames(data.classes), "Classes in Header");

    // if (ps.classes.q_hide_on_mobile && device === "mobile") {
    //     return null;
    // }

    // log.d(data, "Header DATA");

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            <ContentEditable
                className={classNames(ps.classes)}
                // html={input ? input.id.content : data.input.content}
                // html={input ? input.content : "Something"}
                html={content ? content : ""}
                disabled={true}
                tagName={input.headingTag.type}
                // className="qh1"
                style={{
                    height: "100%",
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    textAlign: input.textAlign,
                    textTransform: input.uppercase ? "uppercase" : "none",
                    ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                    ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                    fontSize:
                        input.headingTag.type === "div"
                            ? input.headingTag.value
                            : "auto",
                    // lineHeight:
                    //     input.headingTag.type === "div"
                    //         ? input.headingTag.value
                    //         : "auto",
                    outline: "none",
                }}
            />
        </ElementWrapper>
    );
}

export default Header;
