/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { mobile, tablet } from "../data/bp";
import { decodeStyle, parseStyle } from "../parser/styleparser";
import ElementWrapper from "./wrappers/ElementWrapper";

function Divider({ id, db, index, setHoverIndex, direction, gs, device }) {
    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    const dwebStyle = decodeStyle(ps.style, gs);
    const dmobileStyle = decodeStyle(mobileStyle, gs);

    let color = dwebStyle.color;
    if (mobile(device)) {
        color = dmobileStyle.color;
    }

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            <div
                css={{ "&:hover": decodeStyle(ps.hover) }}
                style={{
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                    ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                }}
            >
                <hr
                    style={{
                        borderTop: `${input.thickness * 0.5}px ${
                            input.lineStyle === "rounded"
                                ? "solid"
                                : input.lineStyle
                        } ${color}`,
                        borderRadius: `${
                            input.lineStyle === "rounded" ? 100 : 0
                        }px`,
                    }}
                ></hr>
            </div>
        </ElementWrapper>
    );
}

export default Divider;
