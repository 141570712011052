/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/react";
import React from "react";
import ElementWrapper from "./wrappers/ElementWrapper";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { elementState, selectedElementState } from "../db/elementDb";
import { parseStyle, decodeStyle } from "../parser/styleparser";
import log from "cslog";
import { Resizable } from "re-resizable";
import { tablet, mobile, getDevice } from "../data/bp";

function Blank({ id, index, setHoverIndex, direction, gs, device }) {
    const [data, setData] = useRecoilState(elementState(id));
    const setSelectedElement = useSetRecoilState(selectedElementState);

    const deviceName = getDevice(device);

    const setHeight = (newHeight) => {
        setData({
            ...data,
            [deviceName === "desktop" ? "input" : `i${deviceName}`]: {
                ...data.input,
                height: newHeight,
            },
        });
    };

    const ps = parseStyle(data, gs, data.cstate);

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            <Resizable
                key={index}
                defaultSize={{
                    width: "100%",
                    height: input.height,
                }}
                maxWidth="100%"
                minWidth="1"
                enable={{
                    top: true,
                    bottom: true,
                }}
                size={{
                    width: "100%",
                    height: input.height,
                }}
                // onResize={(e, dir, ref, d) => {
                onResizeStop={(e, dir, ref) => {
                    const hh = parseFloat(ref.style.height.slice(0, -1));
                    log.d(ref.style.height, "Ref height");
                    log.d(hh, "HH");
                    setHeight(hh);
                }}
            >
                <div
                    css={{
                        width: "100%",
                        height: input.height,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#d6d7d844",
                        fontWeight: "bold",
                        fontSize: input.height > 200 ? 100 : input.height / 2,
                        // outline: "2px solid #F2F2F244",
                        transition: "0.2s",
                        overflow: "hidden",
                    }}
                    onClick={() => {
                        setSelectedElement(id);
                    }}
                >
                    BLANK
                </div>
            </Resizable>
        </ElementWrapper>
    );
}

export default Blank;
