/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/react";
import React, { useEffect } from "react";
import ElementWrapper from "./wrappers/ElementWrapper";
import { useRecoilState, useSetRecoilState } from "recoil";
import { elementState, selectedElementState } from "../db/elementDb";
import ContentEditable from "react-contenteditable";
import { parseStyle, decodeStyle } from "../parser/styleparser";
import { tablet, mobile } from "../data/bp";
import log from "cslog";
import { QurealTextEditorBasic } from "../widgets/rte/QurealTextEditor";

function Text({ id, index, setHoverIndex, direction, gs, device }) {
    const [data, setData] = useRecoilState(elementState(id));
    const setSelectedElement = useSetRecoilState(selectedElementState);

    const handleChange = (eve) => {
        setData({
            ...data,
            input: { ...data.input, content: eve.target.value },
        });
    };

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    let content = input?.content;

    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    const onPaste = (eve) => {
        eve.preventDefault();
        var text = eve.clipboardData.getData("text");
        document.execCommand("insertText", false, text);
    };

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            <div
                css={{
                    columnWidth: "auto",
                    columnCount: input.columnCount,
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    "&:hover": decodeStyle(ps.hover, gs),
                    textAlign: input.textAlign,
                    ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                    ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                    // "p::first-letter": {
                    //     fontSize: "200%",
                    // },
                }}
            >
                <ContentEditable
                    // html={input ? input.content : data.input.content}
                    html={content ? content : ""}
                    onChange={handleChange}
                    disabled={false}
                    tagName="p"
                    onFocusCapture={() => {
                        setSelectedElement(id);
                    }}
                    onPaste={onPaste}
                    style={{
                        lineHeight: ps.style.lineHeight,
                        fontFamily: ps.style.fontFamily,
                        outline: "none",
                        background: "transparent",
                        // whiteSpace: "pre-wrap",
                    }}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            document.execCommand("insertLineBreak");
                            event.preventDefault();
                        }
                    }}
                />
            </div>
        </ElementWrapper>
    );
}

export default Text;
