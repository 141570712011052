import React, { useState } from "react";
import { useDrop } from "react-dnd";
import log from "cslog";

import ContainerWrapper from "./wrappers/ContainerWrapper";
import { useRecoilState } from "recoil";
import { elementState, addElement } from "../db/elementDb";
import Element from "../Element";
import { v4 as uuid } from "uuid";
import { nanoid } from "nanoid";

import { ELEMENTS } from "../data/panelConfig";

function HorizontalContainer({ id, index }) {
	const [data, setData] = useRecoilState(elementState(id));

	let hoverIndex = 999;
	const setHoverIndex = (newHI) => {
		hoverIndex = newHI;
		log.d(hoverIndex, "HOver Index");
	};

	const [{ isOver, isOverCurrent }, drop] = useDrop({
		accept: ["basic", "vlayout"],
		drop: (item, monitor) => {
			const didDrop = monitor.didDrop();
			if (didDrop) {
				return;
			}

			if (!item.data) {
				log.d(item, "Sorting");
				let fromIndex = item.index;
				let toIndex = hoverIndex;
				log.p(`From: ${fromIndex}, To: ${toIndex}`);
				const newChilds = Array.from(data.childs);
				/* problem is here (fromIndex > toIndex) */
				// replace element more accurately
				newChilds.splice(toIndex, 0, item.id);
				newChilds.splice(fromIndex, 1);
				/* --------- */
				setData({ ...data, childs: newChilds });
				return;
			}

			//Add element
			const eid = nanoid(10);

			const edata = {
				...ELEMENTS[item.data.type],
				id: eid,
				pid: data.id,
			};
			addElement(eid, edata);

			const newChilds = Array.from(data.childs);
			newChilds.splice(hoverIndex, 0, eid);
			setData({ ...data, childs: newChilds });
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			isOverCurrent: monitor.isOver({ shallow: true }),
		}),
	});

	const childs = data.childs;
	return (
		<ContainerWrapper label="hbox">
			<div
				ref={drop}
				style={{
					width: "100%",
					minHeight: "50px",
					backgroundColor: isOver ? "pink" : "white",
					display: "flex",
					flexDirection: "row",
					gap: 0,
					alignItems: "flex-start",
				}}
			>
				{childs.map((item, index) => (
					<Element
						key={item}
						id={item}
						index={index}
						setHoverIndex={setHoverIndex}
						direction="h"
					/>
				))}
			</div>
		</ContainerWrapper>
	);
}

export default HorizontalContainer;
