/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import styles from "./wrappers/styles/LayoutWrapper.module.scss";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { elementState, selectedElementState } from "../db/elementDb";
import Element from "../Element";
import { Icon } from "semantic-ui-react";
import log from "cslog";
import { Resizable } from "re-resizable";
import { parseStyle, decodeStyle } from "../parser/styleparser";
import { tablet, mobile, getDevice } from "../data/bp";
import SectionWrapper from "./wrappers/SectionWrapper";

function HeaderSection({ id, device, gs, index, addSection }) {
    const [data, setData] = useRecoilState(elementState(id));
    const [height, setHeight] = useState(90);
    const ref = useRef(null);

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    const deviceName = getDevice(device);
    const inputName = deviceName === "desktop" ? "input" : `i${deviceName}`;
    const setInput = (key, newVal) => {
        setData({
            ...data,
            [inputName]: {
                ...data[inputName],
                [key]: newVal,
            },
        });
    };

    let widths = input.widths;
    if (input.orientation === "v") {
        widths = new Array(widths.length).fill(100);
    }

    const setWidths = (newWidths) => {
        // setData({ ...data, widths: newWidths });
        setData({
            ...data,
            // input: { ...data.input, widths: newWidths },
            [deviceName === "desktop" ? "input" : `i${deviceName}`]: {
                ...data[deviceName === "desktop" ? "input" : `i${deviceName}`],
                widths: newWidths,
            },
        });
    };

    let tw = 0;
    for (let i = 0; i < widths.length; i++) {
        tw += widths[i];
    }

    const colStyle = {
        padding: "1px",
    };

    useEffect(() => {
        log.d(ref.current.clientHeight, "Setting Heihgt to ");
        setHeight(ref.current.clientHeight);
    });

    log.d(data, "Header Section Dataa");

    return (
        <Fragment>
            {input.fixedOnTop && input.solid && (
                <div
                    style={{
                        width: "100%",
                        height: `${height}px`,
                    }}
                />
            )}
            <SectionWrapper
                data={data}
                ps={ps}
                gs={gs}
                input={input}
                device={device}
                index={index}
                addSection={addSection}
                isHeaderSection={true}
                fixedOnTop={input.fixedOnTop}
            >
                <Resizable
                    key={index}
                    style={{
                        margin: "0px auto",
                        zIndex: input.zIndex,
                    }}
                    defaultSize={{
                        width: `${input.width}px`,
                        height: "auto",
                    }}
                    maxWidth="100%"
                    minWidth="1"
                    enable={{
                        right: deviceName === "desktop",
                        left: deviceName === "desktop",
                    }}
                    size={{
                        width: `${input.width}px`,
                        height: "auto",
                    }}
                    onResizeStop={(e, dir, ref, d) => {
                        setInput("width", input.width + d.width);
                    }}
                    resizeRatio={2}
                >
                    <div
                        ref={ref}
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: input.alignChilds,
                            zIndex: input.zIndex,
                        }}
                    >
                        {/* <Element id={data.child} device={device} gs={gs} /> */}

                        <div className={styles.wrapper}>
                            {data.childs.map((item, index) => {
                                if (index === data.childs.length - 1) {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                ...colStyle,
                                                width: `${
                                                    widths[
                                                        data.childs.length - 1
                                                    ]
                                                }%`,
                                            }}
                                        >
                                            <Element
                                                key={item}
                                                id={item}
                                                index={index}
                                                device={device}
                                                gs={gs}
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <Resizable
                                            key={index}
                                            style={colStyle}
                                            defaultSize={{
                                                width: `${widths[index]}%`,
                                                height: "auto",
                                            }}
                                            maxWidth="100%"
                                            minWidth="1"
                                            enable={{
                                                right:
                                                    input.orientation !== "v",
                                            }}
                                            size={{
                                                width: `${widths[index]}%`,
                                                height: "auto",
                                            }}
                                            // onResize={(e, dir, ref, d) => {
                                            // snap={{ x: [10, 20, 40, 50] }}
                                            onResizeStop={(e, dir, ref) => {
                                                const ww = parseFloat(
                                                    ref.style.width.slice(0, -1)
                                                );
                                                log.d(ww, "Width");

                                                const newWidths =
                                                    Array.from(widths);
                                                newWidths[index] = ww;
                                                let osum = 0;
                                                for (
                                                    let i = 0;
                                                    i < newWidths.length;
                                                    i++
                                                ) {
                                                    if (
                                                        i === index ||
                                                        i === index + 1
                                                    ) {
                                                        continue;
                                                    }
                                                    osum += newWidths[i];
                                                }
                                                newWidths[index + 1] =
                                                    100 - osum - ww;
                                                setWidths(newWidths);
                                                log.d(
                                                    newWidths,
                                                    "New Width of Header Section"
                                                );
                                            }}
                                        >
                                            <Element
                                                key={item}
                                                id={item}
                                                index={index}
                                                device={device}
                                                gs={gs}
                                            />
                                        </Resizable>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </Resizable>
            </SectionWrapper>
        </Fragment>
    );
}

export default HeaderSection;
