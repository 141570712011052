/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { mobile, tablet } from "../data/bp";
import { decodeStyle } from "../parser/styleparser";
import ElementWrapper from "./wrappers/ElementWrapper";

function IconList({ id, db, index, setHoverIndex, direction, gs, device }) {
    const data = db[id];

    // const handleChange = (eve) => {
    //     setData({
    //         ...data,
    //         input: { ...data.input, content: eve.target.value },
    //     });
    // };

    // const ps = parseStyle(data, gs, data.cstate);
    const ps = data;

    // const tabletStyle = tablet(device) ? ps.tablet : {};
    // const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    function renderChilds() {
        return input.items.map((item) => {
            if (item !== "") {
                return (
                    <li
                        key={item}
                        style={{
                            margin: input.itemGap - 0.5 + "rem 0px",
                        }}
                    >
                        <span>
                            <span
                                className="fa-li"
                                style={{
                                    ...decodeStyle(ps.styleGroup.icon, gs),
                                }}
                            >
                                <i className={item.icon.value}></i>
                            </span>
                            <span
                                style={{
                                    ...decodeStyle(ps.styleGroup.text, gs),
                                }}
                            >
                                {item.text}
                            </span>
                        </span>
                    </li>
                );
            }
        });
    }

    // const ss = decodeStyle(ps.style, gs);

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            <ul
                className="fa-ul"
                style={{
                    fontSize: input.iconSize.value,
                    lineHeight: input.iconSize.value,
                    ...decodeStyle(ps.styleGroup.iconList, gs),
                    alignSelf: input.alignList,
                    marginTop: "-8px",
                    marginBottom: "-8px",
                }}
            >
                {renderChilds()}
            </ul>
            {/* <h2 onClick={() => setSelectedElement(id)}>Icon List</h2> */}
        </ElementWrapper>
    );
}

export default IconList;
