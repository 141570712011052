import axios from "axios";
import log from "cslog";

const ENV = "prod"; // "prod", "test", "local"

//====================================================

let url = "https://magazineapi.qureal.com";
let editorUrl = "https://qureal.com/editor/";

if (ENV === "test") {
    url = "http://testapi.qureal.com";
    editorUrl = "http://test.qureal.com/editor/";
} else if (ENV === "local") {
    url = "http://127.0.0.1:5000";
    editorUrl = "http://127.0.0.1:3200";
}

export const BASE_URL = url;
export const EDITOR_URL = editorUrl;

export const API = axios.create({
    baseURL: url,
});

export default function setAuthToken(token) {
    axios.defaults.baseURL = BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    if (token === "local") {
        axios.defaults.headers.common["Authorization"] =
            localStorage.getItem("jtoken");
        log.p("Auth Token Header set from localStorage");
    } else if (token && token !== "local") {
        axios.defaults.headers.common["Authorization"] = token;
        localStorage.setItem("jtoken", token);
        log.p("Auth Token Header Set");
    } else {
        delete axios.defaults.headers.common["Authorization"];
        log.h3("Unauthorized");
    }
}
