/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/react";
import React from "react";
import ElementWrapper from "./wrappers/ElementWrapper";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { elementState, selectedElementState } from "../db/elementDb";
import { parseStyle, decodeStyle } from "../parser/styleparser";
import log from "cslog";
import { tablet, mobile } from "../data/bp";

function Divider({ id, index, setHoverIndex, direction, gs, device }) {
    const data = useRecoilValue(elementState(id));
    const setSelectedElement = useSetRecoilState(selectedElementState);

    // const handleChange = (eve) => {
    //     setData({
    //         ...data,
    //         input: { ...data.input, content: eve.target.value },
    //     });
    // };
    // const input = data.input;

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    const dwebStyle = decodeStyle(ps.style, gs);
    const dmobileStyle = decodeStyle(mobileStyle, gs);

    let color = dwebStyle.color;
    if (mobile(device)) {
        color = dmobileStyle.color;
    }

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            <div
                css={{
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    "&:hover": decodeStyle(ps.hover),
                    ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                    ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                }}
                onClick={() => setSelectedElement(id)}
            >
                <hr
                    style={{
                        borderTop: `${input.thickness * 0.5}px ${
                            input.lineStyle === "rounded"
                                ? "solid"
                                : input.lineStyle
                        } ${color}`,
                        borderRadius: `${
                            input.lineStyle === "rounded" ? 100 : 0
                        }px`,
                    }}
                ></hr>
            </div>
        </ElementWrapper>
    );
}

export default Divider;
