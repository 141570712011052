/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { mobile, tablet } from "../data/bp";
import { decodeStyle, parseStyle } from "../parser/styleparser";
import ElementWrapper from "./wrappers/ElementWrapper";

function Video({ id, db, index, setHoverIndex, direction, gs, device }) {
    const data = db[id];

    // const handleChange = (eve) => {
    //     setData({
    //         ...data,
    //         input: { ...data.input, content: eve.target.value },
    //     });
    // };
    // const input = data.input;

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    const youtubeVideoID = input.youtubeVideoURL.value
        ? input.youtubeVideoURL.value
        : "rUWxSEwctFU";

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            <div
                css={{
                    "&:hover": decodeStyle(ps.hover, gs),
                }}
                style={{
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                    ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                    // width: input.videoSize.value,
                    width: "100%",
                    // maxWidth: "100%",
                    alignSelf: input.alignVideo,
                    overflow: "hidden",
                }}
            >
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        height: "0px",
                        paddingBottom: "56.25%",
                        pointerEvents: "auto",
                    }}
                >
                    <iframe
                        title="HelloVideo"
                        src={`//www.youtube.com/embed/${youtubeVideoID}?autoplay=${
                            input.autoplay ? 1 : 0
                        }&modestbranding=1&fs=1&loop=${
                            input.playInLoop ? 1 : 0
                        }${
                            input.playInLoop
                                ? "&playlist=" + youtubeVideoID
                                : ""
                        }&controls=${input.showControls ? 1 : 0}`}
                        frameborder="0"
                        allowfullscreen
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                        }}
                    ></iframe>
                </div>
            </div>
        </ElementWrapper>
    );
}

export default Video;

// Use this for vast experiance
// https://github.com/mediaelement/mediaelement/
