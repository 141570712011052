import classNames from "classnames";
import React, { Fragment } from "react";
import { Icon } from "semantic-ui-react";
import styles from "./styles/BookView.module.css";

const ControlBtn = ({ icon, action, classes = [] }) => {
    return (
        <div
            className={classNames(styles.panel_item, ...classes)}
            onClick={action}
        >
            <Icon name={icon} size="large" />
        </div>
    );
};

const BookControls = ({
    isMobile,
    zoomIn,
    zoomOut,
    prev,
    next,
    first,
    last,
    print,
    fullScreen,
    currentPage,
    totalPages,
}) => {
    return (
        <Fragment>
            <ControlBtn icon="zoom-in" action={zoomIn} />
            <ControlBtn icon="zoom-out" action={zoomOut} />
            <ControlBtn icon="angle double left" action={first} />
            <ControlBtn icon="angle left" action={prev} />
            <div className={styles.panel_item_center}>
                {currentPage === 1
                    ? "1"
                    : currentPage === totalPages
                    ? totalPages
                    : `${currentPage}-${currentPage + 1}`}{" "}
                / {totalPages}
            </div>
            <ControlBtn icon="angle right" action={next} />
            <ControlBtn icon="angle double right" action={last} />
            <ControlBtn icon="print" action={print} />
            <ControlBtn icon="expand" action={fullScreen} />
        </Fragment>
    );

    // return [
    //     { icon: "zoom-in", action: zoomIn },
    //     { icon: "zoom-out", action: zoomOut },
    //     { icon: "angle double left", action: first },
    //     { icon: "angle left", action: prev },
    //     { icon: "angle right", action: next },
    //     { icon: "angle double right", action: last },
    //     { icon: "print", action: print },
    //     { icon: "expand", action: fullScreen },
    // ].map((item) => {
    //     return <ControlBtn icon={item.icon} action={item.action} />;
    // });
};

export default BookControls;
