/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/react";
import React, { useEffect, useState } from "react";
import ElementWrapper from "../wrappers/ElementWrapper";
import { useRecoilState, useSetRecoilState } from "recoil";
import { elementState, selectedElementState } from "../../db/elementDb";
import { parseStyle } from "../../parser/styleparser";
import log from "cslog";
import { tablet, mobile } from "../../data/bp";
import { decodeStyle } from "../../parser/styleparser";
import { Helmet } from "react-helmet";
import ScriptLoader from "../wrappers/ScriptLoader";

function Countdown({ id, index, setHoverIndex, direction, gs, device }) {
    const [data, setData] = useRecoilState(elementState(id));
    const setSelectedElement = useSetRecoilState(selectedElementState);
    const [scLoaded, setScLoaded] = useState(false);

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    const scale = input.size * 0.02;
    const theme = input.theme;

    useEffect(() => {
        if (scLoaded) {
            document.getElementById(`${id}_countdown`).innerHTML = "";
            new window.FlipDown(
                new Date().getTime() / 1000 + 24 * 3600,
                `${id}_countdown`,
                { theme: theme }
            ).start();
        }
    }, [scale, theme, scLoaded]);

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            <Helmet>
                <link rel="stylesheet prefetch" href={data.deps.css}></link>
            </Helmet>
            {scLoaded ? (
                <div
                    css={{
                        display: "flex",
                        justifyContent: input.alignItem,
                        ...decodeStyle(ps.fixStyle, gs),
                        ...decodeStyle(ps.style, gs),
                        "&:hover": decodeStyle(ps.hover),
                        ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                        ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                    }}
                >
                    <div
                        onClick={() => setSelectedElement(data.id)}
                        id={`${id}_countdown`}
                        className="flipdown"
                        css={css`
                            zoom: ${scale};
                            -moz-transform: scale(${scale});
                            align-self: center;
                        `}
                    ></div>
                </div>
            ) : (
                <ScriptLoader
                    scriptPath={data.deps.js}
                    onLoad={() => setScLoaded(true)}
                />
            )}
        </ElementWrapper>
    );
}

export default Countdown;

// Script loading using Helmet
// https://github.com/nfl/react-helmet/issues/146#issuecomment-271552211
