/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/react";
import React from "react";
import ElementWrapper from "./wrappers/ElementWrapper";
import { useRecoilState, useSetRecoilState } from "recoil";
import log from "cslog";
import { elementState, selectedElementState } from "../db/elementDb";
import { parseStyle, decodeStyle } from "../parser/styleparser";
import { mobile, tablet } from "../data/bp";

function List({ id, index, setHoverIndex, direction, gs, device }) {
    const [data, setData] = useRecoilState(elementState(id));
    const setSelectedElement = useSetRecoilState(selectedElementState);

    const handleChange = (eve) => {
        setData({
            ...data,
            input: { ...data.input, content: eve.target.value },
        });
    };

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    function renderChilds() {
        return input.items.map((item) => {
            if (item !== "") {
                return (
                    <li
                        key={item}
                        style={{
                            margin: input.itemGap - 0.5 + "rem 0px",
                        }}
                    >
                        {item}
                    </li>
                );
            }
        });
    }

    const ss = decodeStyle(ps.style, gs);

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            {input.listStyle.tag === "ul" && (
                <ul
                    onClick={() => setSelectedElement(id)}
                    css={{
                        ...decodeStyle(ps.fixStyle, gs),
                        ...ss,
                        "&:hover": decodeStyle(ps.hover, gs),
                        ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                        ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                        // fontSize: "40px",
                        lineHeight: ss.fontSize + "px",
                        listStyleType: input.listStyle.style,
                        listStylePosition: "inside",
                        "*": {
                            fontFamily: ss.fontFamily,
                        },
                    }}
                >
                    {renderChilds()}
                </ul>
            )}
            {input.listStyle.tag === "ol" && (
                <ol
                    onClick={() => setSelectedElement(id)}
                    type={input.listStyle.style}
                    css={{
                        ...decodeStyle(ps.fixStyle, gs),
                        ...ss,
                        "&:hover": decodeStyle(ps.hover),
                        ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                        ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                        // fontSize: "40px",
                        lineHeight: ss.fontSize + "px",
                        listStyleType: input.listStyle.style,
                        listStylePosition: "inside",
                    }}
                >
                    {renderChilds()}
                </ol>
            )}
        </ElementWrapper>
    );
}

export default List;
