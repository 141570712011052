import React, { useState } from "react";

import { Modal, Button, Form, Icon } from "semantic-ui-react";

import { colorOption } from "../customize/SimpleOptions";

const GradientPicker = ({ value, onSelect, isSelected = false }) => {
    const [open, setOpen] = useState(false);

    const [numCol, setNumCol] = useState(2);
    const [col1, setCol1] = useState(value ? value.col1 : "#59f9e2");
    const [col2, setCol2] = useState(value ? value.col2 : "#0082ff");
    const [col3, setCol3] = useState(value ? value.col3 : "#67ded9");
    const [fd, setFd] = useState(value ? value.fd : "to right");

    const background = `${
        fd === "ellipse at center" ? "radial" : "linear"
    }-gradient(${fd}, ${col1} 0%, ${col2} ${numCol === 2 ? "100%" : "50%"} ${
        numCol === 3 ? ", " + col3 + " 100%" : ""
    })`;

    const proceed = () => {
        onSelect({
            numCol: numCol,
            col1: col1,
            col2: col2,
            col3: col3,
            fd: fd,
            fvalue: background,
        });
    };

    let btnStyle = {};
    if (isSelected) {
        btnStyle = { background: background };
    }
    return (
        <Modal
            size="tiny"
            open={open}
            // trigger={<Button primary>Select Photo</Button>}
            trigger={
                // <Form.Field>
                // {label && <label>{label}</label>}
                <Button icon="tint" style={btnStyle} primary={isSelected} />

                // </Form.Field>
            }
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        >
            <Modal.Header>Customize Gradient</Modal.Header>
            <Modal.Content style={{ display: "flex", flexDirection: "row" }}>
                <div
                    style={{
                        width: "200px",
                        // height: "100px",
                        background: background,
                        // "linear-gradient(to right, #27CE4E 0%, #5814C5 100%)",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    Preview
                </div>
                <div
                    style={{
                        flex: "1",
                        paddingLeft: "25px",
                        transition: "0.5s",
                    }}
                >
                    <Form>
                        <Form.Field>
                            <label>Number of colors</label>
                            <Button
                                primary={numCol === 2}
                                onClick={() => setNumCol(2)}
                            >
                                Two
                            </Button>
                            <Button
                                primary={numCol === 3}
                                onClick={() => setNumCol(3)}
                            >
                                Three
                            </Button>
                        </Form.Field>
                        <Form.Group widths="equal">
                            {colorOption(
                                "Color 1",
                                col1,
                                (newCol) => setCol1(newCol),
                                true
                            )}
                            {colorOption(
                                "Color 2",
                                col2,
                                (newCol) => setCol2(newCol),
                                true
                            )}
                            {numCol === 3 &&
                                colorOption(
                                    "Color 2",
                                    col3,
                                    (newCol) => setCol3(newCol),
                                    true
                                )}
                        </Form.Group>
                        <Form.Field>
                            <label>Filling Direction</label>
                            <Button
                                icon="arrow right"
                                primary={fd === "to right"}
                                onClick={() => setFd("to right")}
                            />
                            <Button
                                icon="arrow down"
                                primary={fd === "to bottom"}
                                onClick={() => setFd("to bottom")}
                            />
                            <Button
                                icon
                                primary={fd === "135deg"}
                                onClick={() => setFd("135deg")}
                            >
                                <Icon
                                    name="arrow right"
                                    style={{
                                        transform: "rotate(45deg)",
                                    }}
                                />
                            </Button>
                            <Button
                                icon
                                primary={fd === "45deg"}
                                onClick={() => setFd("45deg")}
                            >
                                <Icon
                                    name="arrow right"
                                    style={{
                                        transform: "rotate(-45deg)",
                                    }}
                                />
                            </Button>
                            <Button
                                icon="circle"
                                primary={fd === "ellipse at center"}
                                onClick={() => setFd("ellipse at center")}
                            />
                        </Form.Field>
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    positive
                    // disabled={isSelected}
                    onClick={() => {
                        proceed();
                        setOpen(false);
                    }}
                >
                    Proceed
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default GradientPicker;
