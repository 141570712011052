/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { premiumDialogState } from "../db/editorConfig";
import log from "cslog";
import { mobile } from "../data/bp";
import { siteInfoState } from "../db/siteConfig";

function QurealAd({ device }) {
    const setPremiumDialog = useSetRecoilState(premiumDialogState);
    const siteInfo = useRecoilValue(siteInfoState);

    const isMobile = mobile(device);

    log.d(isMobile, "Device in Mobile");

    log.d(siteInfo, "Site Info in Qureal Ad");

    let siteID = siteInfo.id;
    if (siteID === parseInt(siteID, 10)) {
        siteID = siteID * 12;
    }

    return (
        <div
            css={{
                position: "absolute",
                bottom: "-70px",
                left: 0,
                zIndex: 11,
                borderTopRightRadius: "15px",
                padding: "15px",
                paddingRight: "20px",
                backgroundColor: "#ffffff",
                boxShadow: "0px 0px 18px -6px rgba(173, 173, 173, 1)",
                transition: "ease-in 0.2s",
                paddingBottom: "30px",
                display: "flex",
                alignItems: "center",
                flexDirection: isMobile ? "row" : "column",
                ...(isMobile && {
                    right: 0,
                    borderRadius: 0,
                    bottom: "-30px",
                    justifyContent: "center",
                }),
                "&:hover": {
                    bottom: "0px",
                    ...(isMobile && {
                        bottom: "-30px",
                    }),
                },
            }}
        >
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;
                    margin-bottom: 9px;
                `}
            >
                <img
                    src={process.env.PUBLIC_URL + "qureal_logo_colored.png"}
                    // src={process.env.PUBLIC_URL + "logo.svg"}
                    css={{
                        height: "50px",
                        width: "auto",
                    }}
                    alt="Logo"
                />
                <p
                    css={{
                        paddingBottom: "12px",
                    }}
                >
                    Created with{" "}
                    <span role="img" aria-label="love">
                        ❤️
                    </span>{" "}
                    with Qureal
                </p>
            </div>

            <a
                css={{
                    backgroundColor: "royalblue",
                    color: "white",
                    padding: "10px 20px",
                    outline: "none",
                    border: "none",
                    borderRadius: "3px",
                    textDecoration: "none",
                    cursor: "pointer",
                    ...(isMobile && {
                        display: "none",
                    }),
                    "&:hover": {
                        color: "white",
                    },
                }}
                href={`https://home.qureal.com/#/upgrade?site=${siteID}`}
                target="_blank"
                // onClick={() =>
                //     setPremiumDialog((currVal) => ({ ...currVal, open: true }))
                // }
            >
                Remove Watermark
            </a>
        </div>
    );
}

export default QurealAd;
