/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import React from "react";
import ContentEditable from "react-contenteditable";
import { mobile, tablet } from "../data/bp";
import { decodeStyle, parseStyle } from "../parser/styleparser";
import ElementWrapper from "./wrappers/ElementWrapper";

function PageLink({ id, db, index, setHoverIndex, direction, gs, device }) {
    // const [data, setData] = useRecoilState(elementState(id));
    // const setSelectedElement = useSetRecoilState(selectedElementState);
    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    let content = input?.content;

    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    const actionType = input.action.type;

    const innerStyle = {
        lineHeight: ps.style.lineHeight,
        fontFamily: ps.style.fontFamily,
        outline: "none",
        background: "transparent",
        pointerEvents: "auto",
        cursor: "pointer",
        // whiteSpace: "pre-wrap",
    };

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            <div
                css={{
                    "&:hover": decodeStyle(ps.hover, gs),
                }}
                style={{
                    columnWidth: "auto",
                    columnCount: input.columnCount,
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    textAlign: input.textAlign,
                    ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                    ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                    // "p::first-letter": {
                    //     fontSize: "200%",
                    // },
                }}
            >
                {actionType === "link" ? (
                    <a
                        dangerouslySetInnerHTML={{
                            __html: content ? content : "",
                        }}
                        style={innerStyle}
                        href={input.action.value}
                        target="_blank"
                    ></a>
                ) : (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: content ? content : "",
                        }}
                        style={innerStyle}
                        data-openpage={input.action.value}
                    ></span>
                )}
            </div>
        </ElementWrapper>
    );
}

export default PageLink;
