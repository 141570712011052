import { DEFAULT } from "../data/defaults";
// import { parsedGStyleArray } from "../db/gstyleDb";
// import log from "cslog";

export function parseStyle(data, gs, cstate = "normal") {
    let root = data.extends;
    let rootStyles = {};

    if (root) {
        switch (root.type) {
            case "default":
                rootStyles = parseStyle(
                    DEFAULT[root.name],
                    gs,
                    (cstate = cstate)
                );
                break;
            case "gstyle":
                rootStyles = parseStyle(gs[root.name], gs, (cstate = cstate));
                break;
            default:
                break;
        }
    }

    return {
        style: {
            ...rootStyles.style,
            ...data.style,
            ...(cstate === "hover" ? data.hover : {}),
        },
        fixStyle: { ...rootStyles.fixStyle, ...data.fixStyle },
        hover: { ...rootStyles.hover, ...data.hover },
        tablet: { ...rootStyles.tablet, ...data.tablet },
        mobile: { ...rootStyles.mobile, ...data.mobile },
        // complexStyle: { ...rootStyles.complexStyle, ...data.complexStyle },
        input: { ...rootStyles.input, ...data.input },
        itype: { ...rootStyles.itype, ...data.itype },
        itablet: { ...rootStyles.itablet, ...data.itablet },
        imobile: { ...rootStyles.imobile, ...data.imobile },
        classes: { ...rootStyles.classes, ...data.classes },
        dataFields: { ...rootStyles.dataFields, ...data.dataFields },
    };
}

export function decodeStyle(style, gs) {
    const result = {};
    let value = null;
    Object.keys(style).map((key) => {
        value = style[key];
        if (typeof value === "object") {
            switch (value.type) {
                case "config":
                    result[key] = gs.config[value.value];
                    // log.p("GStyleConfig");
                    // log.d(gs.config[value.value], key);
                    break;
                // case "none":
                // 	break;
                default:
                    if (value.params?.type === "config") {
                        result[key] = decodeAttribute(value.params, gs);
                    } else {
                        result[key] = value.value;
                    }
            }
        } else {
            result[key] = value;
        }
    });
    return result;
}

export function decodeAttribute(value, gs) {
    switch (value.type) {
        case "config":
            return gs.config[value.value];
        default:
            return value.value;
    }
}
