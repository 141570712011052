import { atom, selector } from "recoil";
import log from "cslog";

// export const elementArray = atom({
// 	key: "elementArray",
// 	default: {},
// });

export const elementArray = {};

export const elementState = (id, data = null) => {
    if (!id) {
        id = "NULL";
    }
    if (id in elementArray) {
        return elementArray[id];
    }
    elementArray[id] = atom({
        key: id,
        default: data ? data : {},
    });
    return elementArray[id];
};

export const addElement = (id, initialData) => {
    if (id in elementArray) {
        return;
    }
    elementArray[id] = atom({
        key: id,
        default: initialData,
    });
};

export const addElementForcefully = (id, initialData) => {
    elementArray[id] = atom({
        key: id,
        default: initialData,
    });
};

export const selectedElementState = atom({
    key: "selectedElement",
    default: null,
});

export const selectedImageState = atom({
    key: "selectedImage",
    default: {},
});

export const currentDragState = atom({
    key: "currentDragState",
    default: null,
});
