export const PANEL = [
    {
        id: "basic",
        name: "Basic Elements",
        items: [
            // "section",
            "header",
            "p",
            "richtext",
            // "text",
            "button",
            "link",
            "img",
            // "button3d",
            "list",
            "icon",
            // "blank",
            "divider",
            "video",
            "shape",
        ],
    },
    {
        id: "widgets",
        name: "Widget Elements",
        items: [
            // "navMenu",
            // "countdown",
            "codeview",
            // "button3d",
            "iconList",
        ],
    },
];

export const FORMS = {
    contactForm: {
        id: "contactForm",
        type: "contactForm",
        group: "form",
        pgroup: "pro",
        name: "Contact Form",
        extends: {
            type: "default",
            name: "contactForm",
        },
        styleGroup: {},
        mobileGroup: {},
        input: {},
        itype: {},
    },
};

export const ELEMENTS = {
    bookPage: {
        id: "bookPage",
        type: "bookPage",
        name: "Page",
        childs: [],
        bg: [],
        layout: [],
        style: {},
        input: {},
        itype: {},
    },
    freeBox: {
        id: "freeBox",
        type: "freeBox",
        name: "FreeBox",
        childs: [],
        layout: [],
        style: {},
        input: {},
        itype: {},
    },
    gridBox: {
        id: "gridBox",
        type: "gridBox",
        layout: [],
        childs: [],
    },
    page: {
        id: "page",
        type: "page",
        sections: [],
    },
    section: {
        id: "section",
        type: "section",
        group: "section",
        pgroup: "free",
        name: "Section",
        extends: {
            type: "default",
            name: "section",
        },
        dims: {
            width: "100%",
            height: "100%",
        },
        // widths: [100],
        childs: [],
        cstate: "normal",
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itablet: {},
        imobile: {},
        itype: {},
    },
    headerSection: {
        id: "headerSection",
        type: "headerSection",
        group: "section",
        pgroup: "free",
        name: "Header Section",
        extends: {
            type: "default",
            name: "section",
        },
        dims: {
            width: "100%",
            height: "100%",
        },
        widths: [100],
        childs: [],
        cstate: "normal",
        style: {
            background: {
                type: "simple",
                params: "teal",
                value: "teal",
            },
        },
        tablet: {},
        mobile: {},
        input: {
            name: "Header",
            fixedOnTop: true,
            solid: true,
        },
        itablet: {},
        imobile: { orientation: "h", fixedOnTop: true },
        itype: {
            fixedOnTop: "boolean",
            solid: "boolean",
        },
        exclude: ["shapeDivider"],
    },
    box: {
        id: "box",
        type: "box",
        group: "vlayout",
        pgroup: "free",
        name: "Box",
        extends: {
            type: "default",
            name: "box",
        },
        css: null,
        cstate: "normal",
        dims: {
            width: "100%",
            height: "auto",
        },
        fixStyle: {},
        style: {},
        hover: {},
        mobile: {},
        input: {},
        childs: [],
    },
    hbox: {
        id: "hbox",
        type: "hbox",
        group: "hlayout",
        name: "Horizontal Box",
        extends: null,
        css: null,
        dims: {
            width: "100%",
            height: "auto",
        },
        fixStyle: {},
        style: {},
        hover: {},
        mobile: {},
        input: {},
        childs: [],
    },
    grid: {
        id: "grid",
        type: "grid",
        group: "vlayout",
        name: "Grid",
    },
    header: {
        id: "header",
        type: "header",
        group: "basic",
        pgroup: "free",
        name: "Header",
        extends: {
            // type: "default",
            type: "gstyle",
            name: "header",
        },
        dims: {
            w: 120,
            h: 60,
        },
        cstate: "normal",
        css: null,
        style: {},
        mobile: {},
        input: {},
        itype: {},
    },
    p: {
        id: "p",
        type: "p",
        group: "basic",
        pgroup: "free",
        name: "Text",
        extends: {
            type: "gstyle",
            name: "p",
        },
        dims: {
            w: 150,
            h: 100,
        },
        css: null,
        style: {},
        mobile: {},
        input: {},
        itype: {},
    },
    link: {
        id: "link",
        type: "link",
        group: "basic",
        pgroup: "free",
        name: "Link",
        extends: {
            type: "default",
            name: "link",
        },
        dims: {
            w: 100,
            h: 40,
        },
        css: null,
        style: {},
        mobile: {},
        input: {},
        itype: {},
    },
    richtext: {
        id: "richtext",
        type: "richtext",
        group: "basic",
        pgroup: "free",
        name: "Rich Text",
        extends: {
            type: "default",
            name: "richtext",
        },
        dims: {
            w: 150,
            h: 100,
        },
        css: null,
        style: {},
        mobile: {},
        input: {},
        itype: {},
    },
    button: {
        id: "button",
        type: "button",
        group: "basic",
        pgroup: "free",
        name: "Button",
        readymate: "button" /* button type readymate collection */,
        extends: {
            type: "gstyle",
            name: "button",
        },
        dims: {
            w: 120,
            h: 60,
        },
        css: null,
        style: {},
        hover: {},
        tablet: {
            // backgroundColor: "red",
        },
        mobile: {
            // backgroundColor: "orange",
        },
        input: {
            content: "Button",
        },
        itype: {
            content: "text",
        },
        itablet: {},
        imobile: {},
    },
    button3d: {
        id: "button3d",
        type: "button3d",
        group: "basic",
        pgroup: "pro",
        name: "Button 3D",
        extends: {
            type: "gstyle",
            name: "button",
        },
        css: null,
        style: {},
        hover: {},
        mobile: {},
        input: {
            frontText: "Front Text",
            backText: "Back Text",
            uppercase: true,
            buttonAlign: "center",
        },
        itype: {
            frontText: "shorttext",
            backText: "shorttext",
            uppercase: "boolean",
            buttonAlign: "optionRowIcon",
        },
        exclude: ["content"],
        dataFields: {
            frontText: "text",
            backText: "text",
        },
    },
    img: {
        id: "img",
        type: "img",
        group: "basic",
        pgroup: "free",
        name: "Image",
        extends: {
            type: "default",
            name: "img",
        },
        dims: {
            w: 120,
            h: 90,
        },
        css: null,
        style: {},
        hover: {},
        mobile: {},
        input: {},
        itype: {},
    },
    video: {
        id: "video",
        type: "video",
        group: "basic",
        pgroup: "pro",
        name: "Video",
        extends: {
            type: "default",
            name: "video",
        },
        dims: {
            w: 150,
            h: 100,
        },
        css: null,
        style: {},
        hover: {},
        mobile: {},
        input: {},
        itype: {},
    },
    blank: {
        id: "blank",
        type: "blank",
        group: "basic",
        pgroup: "free",
        name: "Space",
        readymate: null /* button type readymate collection */,
        extends: {
            type: "default",
            name: "blank",
        },
        dims: {
            w: 6,
            h: 6,
        },
        css: null,
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    divider: {
        id: "divider",
        type: "divider",
        group: "basic",
        pgroup: "free",
        name: "Divider",
        readymate: null /* button type readymate collection */,
        extends: {
            type: "default",
            name: "divider",
        },
        dims: {
            w: 200,
            h: 30,
        },
        css: null,
        style: {},
        tablet: {},
        mobile: {},
        input: {},
        itype: {},
        itablet: {},
        imobile: {},
    },
    icon: {
        id: "icon",
        type: "icon",
        group: "basic",
        pgroup: "free",
        name: "Icon",
        extends: {
            type: "default",
            name: "icon",
        },
        dims: {
            w: 75,
            h: 75,
        },
        css: null,
        style: {
            background: {
                type: "simple",
                params: "#E0E1E2",
                value: "#E0E1E2",
            },
        },
        hover: {},
        mobile: {},
        input: {},
        itype: {},
    },
    shape: {
        id: "shape",
        type: "shape",
        group: "basic",
        pgroup: "free",
        name: "Shape",
        extends: {
            type: "default",
            name: "shape",
        },
        dims: {
            w: 75,
            h: 75,
        },
        css: null,
        style: {},
        hover: {},
        mobile: {},
        input: {},
        itype: {},
    },
    menu: {
        id: "menu",
        type: "menu",
        group: "basic",
        pgroup: "free",
        name: "Menu",
        extends: {
            type: "default",
            name: "menu",
        },
        css: null,
        style: {},
        hover: {},
        mobile: {},
        input: {},
        itype: {},
    },
    list: {
        id: "list",
        type: "list",
        group: "basic",
        pgroup: "free",
        name: "List",
        extends: {
            type: "default",
            name: "list",
        },
        css: null,
        dims: {
            w: 120,
            h: 100,
        },
        style: {},
        hover: {},
        mobile: {},
        input: {},
        itype: {},
    },
    iconList: {
        id: "iconList",
        type: "iconList",
        group: "complex",
        pgroup: "pro",
        name: "Icon List",
        extends: null,
        includes: {
            text: "p",
            icon: "icon",
        },
        css: null,
        dims: {
            w: 120,
            h: 150,
        },
        styleGroup: {
            iconList: {
                // margin: {
                //     type: "single",
                //     params: 20,
                //     value: "20px",
                // },
                padding: {
                    type: "single",
                    params: 0,
                    value: "0px",
                },
            },
            icon: {
                color: "black",
            },
            text: {
                color: "black",
                // fontFamily: `Arial, Helvetica, sans-serif`,
                fontFamily: `inherit`,
            },
        },
        mobileGroup: {
            iconList: {},
            icon: {},
            text: {},
        },
        input: {
            items: [
                {
                    icon: {
                        params: {
                            type: "fa",
                            name: "coffee",
                        },
                        value: "fa fa-coffee",
                    },
                    text: "Item 1",
                },
                {
                    icon: {
                        params: {
                            type: "fa",
                            name: "coffee",
                        },
                        value: "fa fa-coffee",
                    },
                    text: "Item 2",
                },
                {
                    icon: {
                        params: {
                            type: "fa",
                            name: "coffee",
                        },
                        value: "fa fa-coffee",
                    },
                    text: "Item 3",
                },
            ],
            itemGap: 1.5,
            iconSize: {
                type: "mini",
                params: 15,
                value: "15px",
            },
            // alignList: "flex-start",
        },
        itype: {
            items: "iconListData",
            listStyle: "listStyle",
            itemGap: "iconGap",
            iconSize: "iconSize",
            alignList: "optionRowIcon",
        },
        dataFields: {
            items: "iconListData",
        },
    },
    navMenu: {
        id: "navMenu",
        type: "navMenu",
        group: "complex",
        name: "Nav Menu",
        extends: null,
        css: null,
        styleGroup: {
            menuItem: {
                fontSize: 17,
                color: "white",
                backgroundColor: "transparent",
                margin: {
                    type: "single",
                    params: 10,
                    value: "10px",
                },
                fontFamily: `inherit`,
            },
        },
        hover: null,
        mobileGroup: {
            menuItem: {
                fontSize: 20,
                color: "black",
                backgroundColor: "transparent",
                margin: {
                    type: "single",
                    params: 20,
                    value: "20px",
                },
            },
            drawer: {
                backgroundColor: "teal",
            },
            burgerButton: {
                color: "black",
                fontSize: 30,
            },
        },
        input: {
            items: [
                {
                    name: "Home",
                    action: {
                        type: "page",
                        params: {
                            name: "Home",
                            id: "home",
                        },
                        value: {
                            name: "Home",
                            id: "home",
                        },
                    },
                },
            ],
            menuHeight: 50,
            // itemGap: 50,
        },
        itype: {
            items: "navListData",
            menuHeight: "standardRange",
            // itemGap: "standardRange",
        },
    },
    countdown: {
        id: "countdown",
        type: "countdown",
        group: "basic",
        pgroup: "pro",
        name: "Countdown",
        extends: null,
        css: null,
        dims: {
            w: 300,
            h: 100,
        },
        style: {
            // margin: {
            //     type: "single",
            //     params: 0,
            //     value: 0,
            // },
            padding: {
                type: "single",
                params: 10,
                value: 10,
            },
            background: {
                type: "simple",
                params: "transparent",
                value: "transparent",
            },
            borderRadius: {
                type: "single",
                params: 0,
                value: 0,
            },
            border: {
                type: "none",
                params: {
                    width: 1,
                    color: "black",
                },
                value: "none",
            },
        },
        hover: {},
        mobile: {},
        input: {
            size: 25,
            // alignItem: "center",
            theme: "dark",
        },
        itype: {
            size: "standardRange",
            // alignItem: "optionRowIcon",
            theme: "optionRowIcon",
        },
        deps: {
            js: "https://pbutcher.uk/flipdown/js/flipdown/flipdown.js",
            css: "https://pbutcher.uk/flipdown/css/flipdown/flipdown.css",
        },
    },
    codeview: {
        id: "codeview",
        type: "codeview",
        group: "basic",
        pgroup: "pro",
        name: "Code View",
        extends: null,
        css: null,
        dims: {
            w: 300,
            h: 150,
        },
        style: {
            // margin: {
            //     type: "single",
            //     params: 0,
            //     value: 0,
            // },
            borderRadius: {
                type: "single",
                params: 0,
                value: 0,
            },
        },
        hover: {},
        mobile: {},
        input: {
            code: `(function someDemo() {
    var test = "Hello World!";
    console.log(test);
})();
  
return () => <App />;`,
            language: "jsx",
            fontSize: 30,
            padding: 20,
            theme: "dark",
            codeEditorTheme: "nightOwl",
        },
        itype: {
            code: "text",
            fontSize: "standardRange",
            language: "selectOption",
            padding: "standardRange",
            codeEditorTheme: "selectOption",
        },
        exclude: ["code"],
        dataFields: {
            code: "text",
            language: "text",
        },
        // deps: {
        //     js: [
        //         "https://cdnjs.cloudflare.com/ajax/libs/prism/1.22.0/components/prism-core.min.js",
        //         "https://cdnjs.cloudflare.com/ajax/libs/prism/1.22.0/plugins/autoloader/prism-autoloader.min.js",
        //         "https://cdnjs.cloudflare.com/ajax/libs/prism/1.22.0/plugins/line-numbers/prism-line-numbers.min.js",
        //     ],
        //     css:
        //         "https://cdnjs.cloudflare.com/ajax/libs/prism/1.22.0/themes/prism.min.css",
        // },
    },
};

// Shashkeeya control
// hitesh patel (office karmachari)
// shiv kumar sthapak k baju me
