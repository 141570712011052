import "normalize.css";
import "semantic-ui-css/semantic.min.css";
// import "./semantic/dist/semantic.min.css";
import "./styles/global.scss";
import "./styles/qhover.css";
import React, { lazy, Suspense, useEffect } from "react";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import setAuthToken from "./api/apis";

import Loading from "./Loading";
import { authState } from "./db/editorConfig";
import log from "cslog";
import { useState } from "react";

import BookStaticView from "./viewers/BookStaticView";
import { previewState } from "./db/previewDb";

// import App from "./App";
const App = lazy(() => import("./App"));

const DEBUG = false;

if (!DEBUG) {
    console.log = function () {};
}

function Application() {
    const [auth, setAuth] = useRecoilState(authState);
    const [siteId, setSiteId] = useState(null);
    const preview = useRecoilValue(previewState);

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const jtoken = params.get("t");
        // const jtoken =
        //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjEsImlhdCI6MTYyMzY4ODUyNiwiZXhwIjoxNjIzNzc0OTI2fQ.CFpX3RV9NjPQyqrzNLx1u6ldQLEaduK1Aiqs0oo9NHw";
        const siteId = params.get("book");
        setSiteId(siteId);
        if (jtoken) {
            setAuthToken(jtoken);
            setAuth({ isAuth: true });
        } else if (localStorage.getItem("jtoken")) {
            setAuthToken("local");
            setAuth({ isAuth: true });
        } else {
            setAuthToken();
            log.h3("Unauthorized");
            setAuth({ isAuth: false });
        }
    }, []);

    //ForNow
    if (!auth.isAuth) {
        window.location.href = "https://magazine.qureal.com";
        return <Loading full={true} title="Redirecting to login screen" />;
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <Suspense fallback={<Loading full={true} />}>
                {preview.isPreview ? (
                    <BookStaticView />
                ) : (
                    <App debug={DEBUG} siteId={siteId} />
                )}
            </Suspense>
        </DndProvider>
    );
}

export default Application;
