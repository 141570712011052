// import "../../styles/CImage.scss";
import React from "react";
import { Icon } from "semantic-ui-react";
import styles from "./styles/CImage.module.scss";
import cx from "classnames";

const CImage = ({ index, left, top, key, photo }) => {
    const { id, sid, onPhotoClick } = photo;

    let isSelected = id === sid;

    const cont = {
        backgroundColor: "#eee",
        cursor: "pointer",
        overflow: "hidden",
        position: "relative",
    };
    const direction = "column";
    if (direction === "column") {
        cont.position = "absolute";
        cont.left = left;
        cont.top = top;
    }

    // const onPhotoClick = (photo) => {
    // 	console.log("Photo selected");
    // };

    return (
        <div
            key={key}
            index={index}
            style={{
                height: photo.height,
                width: photo.width,
                ...cont,
            }}
            // className={styles.cimg}
            className={cx(styles.cimg, { [styles.selected]: isSelected })}
            onClick={() => onPhotoClick(photo)}
        >
            <img {...photo} alt="Couldn't be loaded" />
            <div
                className={styles.middle}
                // onClick={() => onPhotoClick(photo)}
            >
                {isSelected && <Icon name="circle" color="teal" size="big" />}
            </div>
        </div>
    );
};

export default CImage;
