/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/react";
import React, { useEffect } from "react";
import ElementWrapper from "./wrappers/ElementWrapper";
import { useRecoilState, useSetRecoilState } from "recoil";
import { elementState, selectedElementState } from "../db/elementDb";
import ContentEditable from "react-contenteditable";
import { parseStyle, decodeStyle } from "../parser/styleparser";
import { tablet, mobile } from "../data/bp";
import log from "cslog";
import { QurealTextEditorBasic } from "../widgets/rte/QurealTextEditor";

function RichText({ id, index, setHoverIndex, direction, gs, device }) {
    const [data, setData] = useRecoilState(elementState(id));
    const [selectedElement, setSelectedElement] =
        useRecoilState(selectedElementState);

    const isSelected = selectedElement === id;

    const handleChange = (newHtml) => {
        setData({
            ...data,
            input: { ...data.input, html: newHtml },
        });
    };

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    let styles = decodeStyle(ps.style, gs);
    if (tablet(device)) {
        styles = { ...styles, ...decodeStyle(tabletStyle, gs) };
    }
    if (mobile(device)) {
        styles = { ...styles, ...decodeStyle(mobileStyle, gs) };
    }

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            <div
                css={{
                    columnWidth: "auto",
                    columnCount: input.columnCount,
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    "&:hover": decodeStyle(ps.hover, gs),
                    textAlign: input.textAlign,
                    ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                    ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                }}
            >
                <QurealTextEditorBasic
                    id={`QQ${id}`}
                    html={input.html}
                    setHtml={handleChange}
                    onFocus={() => setSelectedElement(id)}
                    styles={styles}
                    active={selectedElement === id}
                />
            </div>
        </ElementWrapper>
    );
}

export default RichText;
