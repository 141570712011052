/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/react";
import React, { createRef, useEffect, useLayoutEffect } from "react";
import ElementWrapper from "./wrappers/ElementWrapper";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { elementState, selectedElementState } from "../db/elementDb";
import { parseStyle, decodeStyle } from "../parser/styleparser";
import log from "cslog";
import { tablet, mobile } from "../data/bp";
import { FAS, BRANDS, REGULAR } from "../data/faicons";

// TODO: work on responsive style
function QIcon({ id, db, index, setHoverIndex, direction, gs, device }) {
    // const [data, setData] = useRecoilState(elementState(id));
    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    let borderRadius = "10000px";
    if (input.frameShape.type === "square") {
        borderRadius = "0px";
    } else if (input.frameShape.type === "custom") {
        borderRadius = input.frameShape.value;
    }

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            <div
                style={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <span
                    className={input.hoverAnimation}
                    css={{
                        // ...decodeStyle(ps.fixStyle, gs),
                        // ...decodeStyle(ps.style, gs),
                        "&:hover": decodeStyle(ps.hover, gs),
                        // ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                        // ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                        // alignSelf: input.alignImage,
                    }}
                    style={{
                        ...decodeStyle(ps.fixStyle, gs),
                        ...decodeStyle(ps.style, gs),
                        ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                        ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                        width: "100%",
                        height: "100%",
                        fontSize: input.iconSize.value,
                        // fontSize: "30px",
                        // color: input.frameType === "solid" ? "white" : "black",
                        // color: ps.style.color,
                        backgroundColor:
                            input.frameType === "solid"
                                ? ps.style.backgroundColor
                                : "transparent",
                        border:
                            input.frameType === "frame"
                                ? `3px solid ${ps.style.color}`
                                : "none",
                        // padding: input.iconSize.params * 0.7,
                        // width: input.iconSize.params * input.iconGap,
                        // height: input.iconSize.params * input.iconGap,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: borderRadius,
                        // alignSelf: "flex-start",
                    }}
                >
                    <i className={input.iconSource.value}></i>
                </span>
            </div>
        </ElementWrapper>
    );
}

export default QIcon;
