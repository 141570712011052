/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import styles from "./styles/ElementWrapper.module.scss";
import { forwardRef, useRef, useState } from "react";
import { Icon } from "semantic-ui-react";
import { useDrag, useDrop } from "react-dnd";
import { elementMenuState } from "../../db/cmenuDb";
import {
    useRecoilCallback,
    useRecoilValue,
    useRecoilState,
    useSetRecoilState,
} from "recoil";
import { contextMenu } from "react-contexify";
import log from "cslog";
import { selectedElementState } from "../../db/elementDb";

function ElementWrapper({ children }) {
    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                // clipDepth: 3,
                // clipParent: ".grid_element_wrapper",
                // border: "2px dotted blue",
            }}
        >
            {children}
        </div>
    );
}

export default ElementWrapper;
