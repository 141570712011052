import React, { Fragment } from "react";
import { Form, Button, Icon } from "semantic-ui-react";
import SpeechRecognition, {
    useSpeechRecognition,
} from "react-speech-recognition";
import { useState } from "react";
import log from "cslog";
import { useEffect } from "react";
import { selectOption } from "../customize/SimpleOptions";
import { OPTIONS } from "../data/extras";
import startCase from "lodash/startCase";

function VoiceTyper({ content, setContent }) {
    const {
        transcript,
        interimTranscript,
        finalTranscript,
        resetTranscript,
        listening,
    } = useSpeechRecognition();
    const [status, setStatus] = useState("nlisten");
    const [oldText, setOldText] = useState(content);
    const [lang, setLang] = useState("en-IN");

    useEffect(() => {
        if (finalTranscript != "") {
            setContent(oldText + " " + finalTranscript);
            setOldText(oldText + " " + finalTranscript);
            resetTranscript();
        } else {
            setContent(oldText + " " + transcript);
        }
    }, [finalTranscript, transcript]);

    useEffect(() => {
        if (status === "listen") {
            SpeechRecognition.stopListening();
            setStatus("nlisten");
        }
    }, [lang]);

    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        return null;
    }

    // log.d(transcript, "TR");
    // log.d(interimTranscript, "ITR");
    // log.d(finalTranscript, "FTR");

    if (!listening && status === "listen") {
        setStatus("nlisten");
    }

    // Language
    // https://cloud.google.com/speech-to-text/docs/languages
    const toggle = () => {
        if (status === "nlisten") {
            SpeechRecognition.startListening({
                continuous: true,
                // language: "hi-IN",
                // language: "mr-IN",
                language: lang,
            });
            // status = "listen";
            setStatus("listen");
        } else {
            SpeechRecognition.stopListening();
            // status = "nlisten";
            setStatus("nlisten");
        }
    };

    return (
        <Fragment>
            <Form.Field key="microphone">
                <label>Voice Typing</label>
                <Button
                    style={{
                        margin: 0,
                    }}
                    icon
                    onClick={toggle}
                    color={status === "listen" ? "green" : null}
                >
                    <Icon name="microphone" />
                </Button>{" "}
                <span style={{ marginLeft: "5px" }}>
                    {status === "listen" ? (
                        "Listening..."
                    ) : (
                        <i>Press Button & Speak</i>
                    )}
                </span>
            </Form.Field>
            {selectOption(
                "Voice Typing Language",
                lang,
                // (newVal) => setValue(key, newVal),
                (newVal) => setLang(newVal),
                OPTIONS["voiceLangs"]
            )}
        </Fragment>
    );
}

export default VoiceTyper;
