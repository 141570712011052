/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/react";
import React from "react";
import ElementWrapper from "./wrappers/ElementWrapper";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { elementState, selectedElementState } from "../db/elementDb";
import { parseStyle, decodeStyle } from "../parser/styleparser";
import log from "cslog";
import { tablet, mobile } from "../data/bp";
import { FAS, BRANDS, REGULAR } from "../data/faicons";

// TODO: work on responsive style
function QIcon({ id, index, setHoverIndex, direction, gs, device }) {
    const data = useRecoilValue(elementState(id));
    const setSelectedElement = useSetRecoilState(selectedElementState);

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    let borderRadius = "10000px";
    if (input.frameShape.type === "square") {
        borderRadius = "0px";
    } else if (input.frameShape.type === "custom") {
        borderRadius = input.frameShape.value;
    }

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            <span
                className={input.hoverAnimation}
                css={{
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    "&:hover": decodeStyle(ps.hover, gs),
                    ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                    ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                    alignSelf: input.alignImage,
                }}
                style={{
                    fontSize: input.iconSize.value,
                    // color: input.frameType === "solid" ? "white" : "black",
                    // color: ps.style.color,
                    backgroundColor:
                        input.frameType === "solid"
                            ? ps.style.backgroundColor
                            : "transparent",
                    border:
                        input.frameType === "frame"
                            ? `3px solid ${ps.style.color}`
                            : "none",
                    // padding: input.iconSize.params * 0.7,
                    width: input.iconSize.params * input.iconGap,
                    height: input.iconSize.params * input.iconGap,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: borderRadius,
                    // alignSelf: "flex-start",
                }}
                onClick={() => setSelectedElement(id)}
            >
                <i className={input.iconSource.value}></i>
            </span>
        </ElementWrapper>
    );
}

export default QIcon;
