import "./styles/GridBox.css";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import ElementView from "../../ElementView";
import log from "cslog";
import GridLayout from "react-grid-layout";

const GridBoxPreview = ({ gridWidth, data, device, gs }) => {
    return (
        <GridLayout
            className="layout"
            width={gridWidth}
            autoSize={true}
            cols={21}
            compactType={null}
            margin={[5, 5]}
            containerPadding={[0, 0]}
            rowHeight={5}
            isBounded={true}
            transformScale={1}
            preventCollision={true}
            layout={data.layout}
            isDroppable={false}
            isResizable={false}
            isDraggable={false}
            style={{
                height: "100%",
            }}
        >
            {data.layout.map((item) => {
                if (!item) {
                    return null;
                }
                return (
                    <div key={item.i} className="grid_item_wrapper_preview ">
                        <div className="grid_element_wrapper">
                            <ElementView id={item.i} device={device} gs={gs} />
                            {/* <h3>Hello</h3> */}
                        </div>
                    </div>
                );
            })}
        </GridLayout>
    );
};

export default GridBoxPreview;
