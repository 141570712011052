/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/react";
import React from "react";
import ElementWrapper from "./wrappers/ElementWrapper";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { elementState, selectedElementState } from "../db/elementDb";
import { parseStyle, decodeStyle } from "../parser/styleparser";
import log from "cslog";
import { tablet, mobile } from "../data/bp";

// Create a function that gives css for perticular element type
// and then create a css file with that css
function Button({ id, index, setHoverIndex, direction, gs, device }) {
    const data = useRecoilValue(elementState(id));

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    return (
        <ElementWrapper>
            <button
                className={input.hoverAnimation}
                css={{
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    "&:hover": decodeStyle(ps.hover, gs),
                    // ...mobileStyle,
                    alignSelf: input.buttonAlign,
                    textTransform: input.uppercase ? "uppercase" : "none",
                    // NOT [...decodeStyle(tabletStyle, gs)] just for optimization
                    ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                    ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                }}
            >
                {input.content}
            </button>
        </ElementWrapper>
    );
}

export default Button;
