import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";

import Application from "./Application";

ReactDOM.render(
    <RecoilRoot>
        <Application />
    </RecoilRoot>,
    document.querySelector("#root")
);

// For logic builder
// https://rete.js.org/#/examples/basic
