/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import styles from "./styles/SectionWrapper.module.scss";
import { Dropdown, Icon, Popup } from "semantic-ui-react";
import { useSetRecoilState } from "recoil";
import { selectedElementState } from "../../db/elementDb";
import { decodeStyle, parseStyle } from "../../parser/styleparser";
import { tablet, mobile, getDevice, desktop } from "../../data/bp";
import log from "cslog";
import { useRef } from "react";
import { sectionMenuState } from "../../db/cmenuDb";

function SectionWrapper({
    children,
    data,
    input,
    index,
    ps,
    gs,
    device,
    addSection,
    isHeaderSection,
    fixedOnTop,
}) {
    const contextRef = useRef();
    const setSelectedElement = useSetRecoilState(selectedElementState);
    const setMenuState = useSetRecoilState(sectionMenuState);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    const opneCMenu = (e) => {
        e.preventDefault();
        log.d(e.target.getBoundingClientRect(), "EBox");
        setSelectedElement(data.id);
        setMenuState({
            id: data.id,
            x: e.target.getBoundingClientRect().x,
            y: e.target.getBoundingClientRect().y,
            pid: data.pid,
            index: index,
        });
    };

    const deviceName = getDevice(device);
    let deviceHeight = 720;
    let height = {};
    if (deviceName === "mobile") {
        if ("height" in mobileStyle) {
            height =
                mobileStyle.height.type === "fullscreen"
                    ? {
                          height:
                              mobileStyle.height.params * deviceHeight * 0.01,
                      }
                    : {};
        } else if ("height" in tabletStyle) {
            height =
                tabletStyle.height.type === "fullscreen"
                    ? {
                          height:
                              tabletStyle.height.params * deviceHeight * 0.01,
                      }
                    : {};
        } else {
            height =
                ps.style.height.type === "fullscreen"
                    ? { height: ps.style.height.params * deviceHeight * 0.01 }
                    : {};
        }
    } else if (deviceName === "tablet") {
        deviceHeight = 900;
        if ("height" in tabletStyle) {
            height =
                tabletStyle.height.type === "fullscreen"
                    ? {
                          height:
                              tabletStyle.height.params * deviceHeight * 0.01,
                      }
                    : {};
        } else {
            height =
                ps.style.height.type === "fullscreen"
                    ? { height: ps.style.height.params * deviceHeight * 0.01 }
                    : {};
        }
    }

    return (
        <div
            className={styles.wrapper}
            css={{
                ...decodeStyle(ps.fixStyle, gs),
                ...decodeStyle(ps.style, gs),
                ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                zIndex: data.input.zIndex,
                backgroundSize: "cover",
                objectFit: "cover",
                backgroundRepeat: "no-repeat",
                // backgroundPosition: "bottom center",
                ...height,
                ...(fixedOnTop
                    ? {
                          position: "absolute",
                          top: 0,
                          right: 0,
                          left: 0,
                      }
                    : {}),
            }}
            // onContextMenu={opneCMenu}
        >
            <div
                className={styles.label}
                onContextMenu={isHeaderSection ? null : opneCMenu}
                style={
                    index === 0
                        ? {
                              top: 0,
                              borderRadius: "0px 0px 10px 10px",
                          }
                        : {}
                }
            >
                {!input.isInner && <Icon name="add" onClick={addSection} />}
                <Icon
                    name="columns"
                    onClick={() => setSelectedElement(data.id)}
                />
            </div>
            <div className={styles.psudo}></div>
            {children}

            <div
                css={css`
                    position: absolute;
                    /* bottom: -1px; */
                    ${input.shapeDivider.position === "bottom" &&
                    "bottom: -1px;"}
                    ${input.shapeDivider.position === "top" && "top: -1px;"}
					left: 0;
                    width: 100%;
                    overflow: hidden;
                    line-height: 0;
                    background: transparent;
                    svg {
                        position: relative;
                        display: block;
                        width: calc(100% + 1.3px);
                        height: ${input.shapeDivider.height}px;
                        bottom: -1px;
                        ${input.shapeDivider.position === "bottom" &&
                        "transform: rotate(180deg);"}
                    }
                    .shape-fill {
                        /* fill: #bd10e0; */
                        fill: ${input.shapeDivider.color};
                    }
                `}
            >
                <svg
                    data-name="Layer 1"
                    // xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                >
                    {/* <path
                        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                        class="shape-fill"
                    ></path> */}
                    <path
                        d={input.shapeDivider.path}
                        className="shape-fill"
                    ></path>
                </svg>
            </div>
        </div>
    );
}

export default SectionWrapper;
