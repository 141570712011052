/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styles from "./styles/ContainerWrapper.module.scss";
import { Icon } from "semantic-ui-react";
import classNames from "classnames";
import log from "cslog";
import { useRecoilCallback, useSetRecoilState } from "recoil";
import {
    elementState,
    addElement,
    selectedElementState,
} from "../../db/elementDb";
import { nanoid } from "nanoid";
import { ELEMENTS } from "../../data/panelConfig";
import { cmenuState, containerMenuState } from "../../db/cmenuDb";
import { contextMenu } from "react-contexify";
import { decodeStyle, parseStyle } from "../../parser/styleparser";
import { tablet, mobile } from "../../data/bp";

function ContainerWrapper({ label, children, index, data, gs, device }) {
    const setSelectedElement = useSetRecoilState(selectedElementState);
    const setMenuState = useSetRecoilState(containerMenuState);
    const pid = data.pid;
    const id = data.id;

    const addSibling = useRecoilCallback(({ set }) => async () => {
        log.p("Adding Sibling: " + pid + ": " + index);

        const cid = nanoid(10);
        const cdata = { ...ELEMENTS["box"], id: cid, pid: pid };
        addElement(cid, cdata);

        set(elementState(pid), (data) => {
            const ps = parseStyle(data, gs, data.cstate);
            let input = ps.input;
            const newChilds = Array.from(data.childs);
            newChilds.splice(index + 1, 0, cid);

            const newWidths = Array.from(input.widths);
            const newW = newWidths[index] / 2;
            newWidths[index] = newW;
            newWidths.splice(index + 1, 0, newW);

            return {
                ...data,
                childs: newChilds,
                input: {
                    ...data.input,
                    widths: newWidths,
                },
            };
        });
    });

    const opneCMenu = (e) => {
        e.preventDefault();
        log.d(e.target.getBoundingClientRect(), "EBox");
        setSelectedElement(id);
        setMenuState({
            id: id,
            x: e.target.getBoundingClientRect().x,
            y: e.target.getBoundingClientRect().y,
            pid: pid,
            index: index,
        });
    };

    // const parsedStyles = parseStyle(data, gs, data.cstate);
    // log.d(parsedStyles, "PS in CW");

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    return (
        <div
            className={`${styles.wrapper} ${input.hoverAnimation}`}
            // style={decodeStyle(parsedStyles.style, gs)}
            style={{
                // ...decodeStyle(parsedStyles.style, gs),
                ...decodeStyle(ps.fixStyle, gs),
                ...decodeStyle(ps.style, gs),
                ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                // height: "100%",
                minHeight: "100%",
                display: "flex",
                alignItems: data.input.alignChilds,
            }}
        >
            <div
                onClick={addSibling}
                className={classNames(styles.addBtn, styles.rightBtn)}
            >
                +
            </div>
            {/* <div className={classNames(styles.addBtn, styles.bottomBtn)}>+</div> */}

            <div
                className={styles.label}
                style={{
                    // backgroundColor: label === "box" ? "#44ff98" : "#c7ff44",
                    backgroundColor: "royalblue",
                }}
                onClick={opneCMenu}
                onContextMenu={opneCMenu}
            >
                {/* <Icon name="bars" onClick={() => setCMenu(pid)} /> */}
                {/* <ContainerMenu id={id} onDelete={deleteContainer} /> */}
                <Icon name="bars" />
            </div>

            <div className={styles.psudo}></div>
            {children}
        </div>
    );
}

export default ContainerWrapper;
