/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    elementState,
    addElement,
    selectedElementState,
    currentDragState,
} from "../../db/elementDb";
import Element from "../../Element";
import log from "cslog";
import { Button, Icon } from "semantic-ui-react";
import { nanoid } from "nanoid";
import { ELEMENTS } from "../../data/panelConfig";
import { globalConfigState } from "../../db/gstyleDb";
import { decodeStyle } from "../../parser/styleparser";
import { BasicToolbar } from "../../widgets/rte/QurealTextEditor";
import { GOOGLE_FONTS } from "../../customize/ComplexOptions";
import { bookLayoutState } from "../../db/bookConfig";
import GridLayout from "react-grid-layout";
import classNames from "classnames";
import { previewState } from "../../db/previewDb";
import styles from "./styles/BookPage.module.css";
import GridBox from "./GridBox";
import GridBoxPreview from "./GridBoxPreview";

const BookPage = ({ id, index, device, gs, preview }) => {
    const [data, setData] = useRecoilState(elementState(id));
    const globalConfig = useRecoilValue(globalConfigState);
    const globalConfigD = decodeStyle(globalConfig, gs);
    const bookLayout = useRecoilValue(bookLayoutState);
    const setSelectedElement = useSetRecoilState(selectedElementState);

    log.d(data, "BookPageData");

    const dims = bookLayout.pageSize.value;
    const gridWidth =
        dims.width -
        bookLayout.padding.params.left -
        bookLayout.padding.params.right;

    useEffect(() => {
        if (GOOGLE_FONTS.includes(globalConfigD.defaultFontFamily)) {
            window.WebFont.load({
                google: {
                    families: [globalConfigD.defaultFontFamily],
                },
            });
        }
    }, []);

    const onDrop = (layout, layoutItem, _event) => {
        log.h3("Dropped");
        if (!layoutItem) {
            return;
        }

        const eid = "q_" + nanoid(10);
        const edata = {
            ...ELEMENTS[layoutItem.i],
            id: eid,
            pid: data.id,
        };

        addElement(eid, edata);

        const newChilds = Array.from(data.childs);
        newChilds.push(eid);
        const newLayout = Array.from(data.layout);
        newLayout.push({
            i: eid,
            x: layoutItem.x,
            y: layoutItem.y,
            w: layoutItem.w,
            h: layoutItem.h,
        });
        setData({ ...data, childs: newChilds, layout: newLayout });
    };

    return (
        <div
            id={id}
            name={id}
            style={{
                width: dims.width,
                height: dims.height,
                padding: bookLayout.padding.value,
                // background: "white",
                // background: bookLayout.background.value,
                // backgroundPosition: bookLayout?.backgroundPosition,
                boxShadow: "0px 0px 8px 1px rgba(222,222,222,1)",
                borderRadius: "3px",
                marginTop: preview.isPreview ? 0 : "10px",
                //Needed for drag issue
                position: "absolute",
                // border: "1px solid black",
                borderRadius: bookLayout.borderRadius.value,
            }}
            css={{
                // "*": globalConfig,
                fontFamily: globalConfigD.defaultFontFamily,
                background: globalConfigD.pageBackground,
                // background: "yellow",
            }}
        >
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    background: bookLayout.background.value,
                    backgroundPosition: bookLayout?.backgroundPosition,
                    borderRadius: bookLayout.borderRadius.value,
                    overflow: "hidden",
                }}
            >
                {bookLayout.background.type === "image" && (
                    <img
                        src={bookLayout.background.params.src}
                        style={{
                            margin: "-10px",
                            filter: "blur(8px)",
                            objectFit: "cover",
                        }}
                    />
                )}
            </div>
            <div
                className={styles.page_setting_btn}
                onClick={() => setSelectedElement(id)}
            >
                <Icon name="setting" size="large" />
            </div>

            <div
                className={styles.grid_wrapper}
                style={{
                    outline: preview.isPreview ? null : "1px dashed #80808040",
                }}
            >
                {preview.isPreview ? (
                    <GridBoxPreview
                        gridWidth={gridWidth}
                        data={data}
                        device={device}
                        gs={gs}
                    />
                ) : (
                    <GridBox
                        gridWidth={gridWidth}
                        data={data}
                        setData={setData}
                        onDrop={onDrop}
                        device={device}
                        gs={gs}
                    />
                )}
            </div>
        </div>
    );
};

export default BookPage;
