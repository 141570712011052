import React, { useState } from "react";

import { Modal, Button, Tab, Form, Card, Placeholder } from "semantic-ui-react";
import ImageLibrery from "./ImageLibrery";
import Unsplash from "./Unsplash";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    elementState,
    selectedElementState,
    selectedImageState,
} from "../db/elementDb";
import log from "cslog";
import Texture from "./Texture";
import TenorGif from "./TenorGif";

const ImagePlaceholder = () => {
    return [1, 2].map((ittem) => (
        <Card.Group key={ittem} itemsPerRow={3}>
            {[1, 2, 3].map((item) => (
                <Card key={item}>
                    <Card.Content>
                        <Placeholder>
                            <Placeholder.Image rectangular />
                        </Placeholder>
                    </Card.Content>
                </Card>
            ))}
        </Card.Group>
    ));
};

const ImagePanel = ({ onPhoto, label, button }) => {
    const selectedImage = useRecoilValue(selectedImageState);
    const [open, setOpen] = useState(false);
    const [sourceIndex, setSourceIndex] = useState(0);
    const selectedElement = useRecoilValue(selectedElementState);
    const edata = useRecoilValue(elementState(selectedElement));
    const [pdata, setPData] = useRecoilState(elementState(edata.pid));

    log.d(edata, "EDATA");
    log.d(pdata, "PDATA");

    const isSelected = selectedImage.id === undefined;

    const onPhotoSelect = (photo) => {
        log.d(photo, "seletedPhoto");
        onPhoto(photo);
        const child_data = pdata.childs[edata.id];
        setPData({
            ...pdata,
            childs: {
                ...pdata.childs,
                [edata.id]: {
                    ...child_data,
                    width: child_data.width,
                    height: (child_data.width * photo.height) / photo.width,
                },
            },
        });
    };

    const panes = [
        {
            menuItem: {
                key: "collection",
                icon: "globe",
                content: "Collection",
            },
            render: () => <ImageLibrery placeholder={<ImagePlaceholder />} />,
        },
        {
            menuItem: { key: "unsplash", icon: "images", content: "Unsplash" },
            render: () => <Unsplash placeholder={<ImagePlaceholder />} />,
        },
        {
            menuItem: { key: "tenor", icon: "images", content: "GIF" },
            render: () => <TenorGif placeholder={<ImagePlaceholder />} />,
        },
        // {
        //     menuItem: { key: "upload", icon: "upload", content: "Upload" },
        //     render: () => <h2>Upload</h2>,
        // },
        // {
        //     menuItem: { key: "camera", icon: "camera", content: "Camera" },
        //     render: () => (
        //         <ImageLibrery placeholder={<ImagePlaceholder />} cam={true} />
        //     ),
        // },
        // {
        //     menuItem: { key: "texture", icon: "upload", content: "Texture" },
        //     render: () => <Texture placeholder={<ImagePlaceholder />} />,
        // },
    ];
    return (
        <Modal
            size="large"
            open={open}
            // trigger={<Button primary>Select Photo</Button>}
            trigger={
                <Form.Field>
                    {label && <label>{label}</label>}
                    {button ? button : <Button primary>Change Image</Button>}
                </Form.Field>
            }
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            style={{
                zIndex: 5000,
                backgroundColor: "yellow",
            }}
        >
            {/* <Modal.Header>Select Image</Modal.Header> */}
            <Modal.Content
                style={{ height: "80vh", overflow: "hidden", zIndex: 5000 }}
            >
                {/* <Modal.Content scrolling> */}
                <Tab
                    menu={{ fluid: true, vertical: true, tabular: true }}
                    panes={panes}
                    onTabChange={(e, { activeIndex }) => {
                        log.d(activeIndex, "ActiveTAbIndex");
                        setSourceIndex(activeIndex);
                    }}
                />
            </Modal.Content>
            <Modal.Actions>
                <div
                    style={{
                        display: "inline-block",
                        fontSize: "1.2rem",
                        color: "#999999",
                        userSelect: "none",
                    }}
                >
                    {sourceIndex === 0
                        ? ""
                        : sourceIndex === 1
                        ? "Powered By Unsplash"
                        : "Powered By Tenor"}
                </div>
                <Button color="black" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    positive
                    disabled={isSelected}
                    onClick={() => {
                        // onPhoto(selectedImage);
                        onPhotoSelect(selectedImage);
                        setOpen(false);
                    }}
                >
                    Proceed
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ImagePanel;
