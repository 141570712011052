/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/react";
import React from "react";
import ElementWrapper from "../wrappers/ElementWrapper";
import { useRecoilState, useSetRecoilState } from "recoil";
import { elementState, selectedElementState } from "../../db/elementDb";
// import ContentEditable from "react-contenteditable";
import { parseStyle } from "../../parser/styleparser";

function Button3D({ id, index, setHoverIndex, direction, gs, device }) {
    const [data, setData] = useRecoilState(elementState(id));
    const setSelectedElement = useSetRecoilState(selectedElementState);

    const handleChange = (eve) => {
        setData({
            ...data,
            input: { ...data.input, content: eve.target.value },
        });
    };

    const parsedStyles = parseStyle(data, gs, data.cstate);
    const mobileStyle = device === "mobile" ? parsedStyles.mobile : {};
    const input = parsedStyles.input;

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
        >
            <div
                css={css`
                    padding: 10px;
                    perspective: 1000px;
                    perspective-origin: center;
                    display: flex;
                    align-items: center;
                    justify-content: ${input.buttonAlign};
                `}
                onClick={() => setSelectedElement(id)}
            >
                <div
                    css={css`
                        width: 250px;
                        height: 100px;
                        transition: all 250ms ease;
                        transform-style: preserve-3d;
                        text-align: center;
                        // margin: 0 auto;
                        &:hover {
                            transform: rotateX(-89deg);
                        }
                        &:hover .b3dActive {
                            background: #2aba66;
                        }
                    `}
                >
                    <div
                        css={css`
                            height: 100px;
                            transform: translateZ(50px);
                            background: #2ecc71;
                            font-size: 16px;
                            text-transform: ${input.uppercase
                                ? "uppercase"
                                : "none"};
                            color: #fff;
                            line-height: 100px;
                            transition: background 250ms ease;
                        `}
                    >
                        <span>{input.frontText}</span>
                    </div>
                    <div
                        className="b3dActive"
                        css={css`
                            height: 100px;
                            background: #2aba66;
                            font-size: 16px;
                            text-transform: ${input.uppercase
                                ? "uppercase"
                                : "none"};
                            color: #fff;
                            line-height: 100px;
                            transform: rotateX(90deg) translateZ(150px);
                            /* translatez: 150px; */
                        `}
                    >
                        <span>{input.backText}</span>
                    </div>
                </div>
            </div>
        </ElementWrapper>
    );
}

export default Button3D;
