/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import React, { Fragment } from "react";
import { SketchPicker } from "react-color";
import { useRecoilValue } from "recoil";
import { gstyleConfigState } from "../db/gstyleDb";

import Loadable from "react-loadable";
import LoadingDialog from "../LoadingDialog";

import { Range } from "react-range";

import {
    Popup,
    Button,
    Icon,
    Form,
    Label,
    Input,
    Dropdown,
    TextArea,
} from "semantic-ui-react";
import log from "cslog";
import { decodeAttribute } from "../parser/styleparser";
import ImagePanel from "../widgets/ImagePanel";
import IconDialog from "../widgets/IconDialog";
import VoiceTyper from "../widgets/VoiceTyper";
import { startCase } from "lodash";

// const IconDialog = Loadable({
//     loader: () => import("../widgets/IconDialog"),
//     loading: LoadingDialog,
// });

export const rangeOption = (
    label,
    value,
    onChange,
    min = 0,
    max = 100,
    step = 1
) => {
    return (
        <div
            key={label}
            style={{ display: "flex", flexDirection: "row", gap: "5px" }}
        >
            <Form.Field style={{ flex: 1 }}>
                <label style={{ marginBottom: "10px" }}>{label}</label>
                <Range
                    step={1}
                    min={min}
                    max={value > max ? value : max}
                    step={step}
                    // max={max}
                    values={[value]}
                    onChange={(values) => {
                        log.p("Range changed to " + value);
                        onChange(values[0]);
                    }}
                    renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: "6px",
                                width: "100%",
                                backgroundColor: "#5f9de8",
                                borderRadius: "2px",
                            }}
                        >
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: "15px",
                                width: "15px",
                                backgroundColor: "white",
                                border: "1px solid black",
                                borderRadius: "2px",
                            }}
                        />
                    )}
                />
            </Form.Field>

            <Form.Input
                style={{
                    width: "70px",
                    marginTop: "8px",
                    paddingLeft: "1px",
                }}
                type="number"
                value={value}
                onChange={(eve) => onChange(parseInt(eve.target.value))}
            />
        </div>
    );
};

export const textOption = (label, value, onChange) => {
    return (
        <Form.Field>
            <label>{label}</label>
            <Input
                value={value}
                onChange={(eve) => onChange(eve.target.value)}
            />
        </Form.Field>
    );
};

// export const longTextOption = (label, value, onChange) => {
// 	return (
// 		<Form.Field key={label}>
// 			<label>{label}</label>
// 			<TextArea
// 				value={value}
// 				onChange={(eve) => onChange(eve.target.value)}
// 			/>
// 		</Form.Field>
// 	);
// };

export const longTextOption = (label, value, onChange) => {
    return (
        <React.Fragment key={label}>
            <VoiceTyper
                content={value}
                setContent={(newVal) => onChange(newVal)}
            />
            <Form.Field key={label}>
                <label>{label}</label>
                <TextArea
                    value={value}
                    onChange={(eve) => onChange(eve.target.value)}
                />
            </Form.Field>
        </React.Fragment>
    );
};

export const booleanOption = (label, value, onChange) => {
    return (
        <Form.Field key={label}>
            <Form.Checkbox
                label={label}
                checked={value}
                onChange={() => onChange(!value)}
            />
        </Form.Field>
    );
};

export const ColorConfigPicker = ({ active, value, setValue }) => {
    const gsc = useRecoilValue(gstyleConfigState);

    return (
        <Dropdown
            icon={null}
            trigger={
                <Button
                    icon="circle"
                    style={{
                        color: "#7f7f7f",
                        border: "1px solid #f1f1f1",
                        ...(active ? { color: value } : {}),
                    }}
                />
            }
        >
            <Dropdown.Menu>
                <Dropdown.Header icon="globe" content="Global Colors" />
                <Dropdown.Menu scrolling>
                    {Object.keys(gsc).map((item) => (
                        <Dropdown.Item
                            key={item}
                            text={startCase(item)}
                            value={item}
                            label={
                                <Label
                                    circular={true}
                                    empty={true}
                                    style={{
                                        backgroundColor: gsc[item],
                                    }}
                                />
                            }
                            onClick={() =>
                                setValue({
                                    type: "config",
                                    value: item,
                                })
                            }
                        />
                    ))}
                </Dropdown.Menu>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export const colorOption = (label, value, onChange, gs) => {
    let val = value;
    const isObj = typeof value === "object";
    if (isObj) {
        val = decodeAttribute(value, gs);
    }

    return (
        <Fragment>
            <Form.Field key={label}>
                {/* <label>{key}</label> */}
                {label && <label>{label}</label>}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2px",
                    }}
                >
                    <Popup
                        trigger={
                            <Button
                                icon="tint"
                                // primary={!isObj}
                                style={{
                                    // color: "#7f7f7f",
                                    border: "1px solid #f1f1f1",
                                    ...(isObj ? {} : { color: val }),
                                }}
                            />
                        }
                        eventsEnabled={true}
                        on="click"
                    >
                        <SketchPicker
                            color={val}
                            onChangeComplete={(color) => {
                                // onChange(color.hex);
                                onChange(
                                    `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                                );
                            }}
                        />
                    </Popup>
                </div>
            </Form.Field>
        </Fragment>
    );
};

export const selectOption = (label, value, setValue, optionList) => {
    // const optionList = [
    // 	[`Arial`, `Arial, Helvetica, sans-serif`],
    // 	[`Comic Sans MS`, `"Comic Sans MS", cursive, sans-serif`],
    // 	[`Verdana`, `Verdana, Geneva, sans-serif`],
    // 	[`Lucida Console`, `"Lucida Console", Monaco, monospace`],
    // ];

    const options = optionList.map((item) => {
        return {
            key: item[0],
            text: item[0],
            value: item[1],
        };
    });

    const handleChange = (e, { value }) => setValue(value);

    return (
        <Form.Field key={label}>
            <label>{label}</label>
            <Dropdown
                placeholder={label}
                options={options}
                selection
                value={value}
                onChange={handleChange}
            />
        </Form.Field>
    );
};

export const listOption = (label, value, setValue) => {
    const total = value.length;
    if (value[total - 1] !== "") {
        value.push("");
    }

    const handleChange = (eve, index) => {
        const newVal = Array.from(value);
        newVal[index] = eve.target.value;
        if (index === total - 1) {
            newVal.push("");
        }

        setValue(newVal);
    };

    const deleteItem = (index) => {
        const newVal = Array.from(value);
        newVal.splice(index, 1);
        setValue(newVal);
    };

    return (
        <Form.Field key={label}>
            <label>{label}</label>
            {value.map((item, index) => {
                return (
                    <Form.Input placeholder="Item1">
                        <input
                            value={item}
                            onChange={(eve) => handleChange(eve, index)}
                        />
                        <Button
                            icon="delete"
                            style={{
                                marginLeft: "5px",
                                opacity: index === value.length - 1 ? 0 : 1,
                            }}
                            onClick={() => deleteItem(index)}
                        />
                    </Form.Input>
                );
            })}
        </Form.Field>
    );
};

export const dynamicSelectOption = (
    label,
    label2,
    value,
    value2,
    setValue,
    setValue2,
    optionList1,
    optionList2
) => {
    const options = optionList1.map((item) => {
        return {
            key: item[0],
            text: item[0],
            value: item[1],
        };
    });

    const options2 = optionList2[value].map((item) => {
        return {
            key: item[0],
            text: item[0],
            value: item[1],
        };
    });

    return (
        <Fragment>
            <Form.Field key={label}>
                <label>{label}</label>
                <Dropdown
                    placeholder={label}
                    options={options}
                    selection
                    value={value}
                    onChange={(e, { value }) => setValue(value)}
                />
            </Form.Field>
            <Form.Field key={label2}>
                <label>{label2}</label>
                <Dropdown
                    placeholder={label2}
                    options={options2}
                    selection
                    value={value2}
                    onChange={(e, { value }) => setValue2(value)}
                />
            </Form.Field>
        </Fragment>
    );
};

export const optionRowIcon = (label, value, onChange, options) => {
    return (
        <Form.Field key={label}>
            <label>{label}</label>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 40px)",
                    rowGap: "5px",
                    columnGap: "5px",
                }}
            >
                {options.map((item) => (
                    <Button
                        key={item[0]}
                        style={{
                            margin: 0,
                        }}
                        icon
                        onClick={() => {
                            onChange(item[0]);
                        }}
                        primary={value === item[0]}
                    >
                        <Icon name={item[1]} />
                    </Button>
                ))}
            </div>
        </Form.Field>
    );
};

export const optionRowNumber = (label, value, onChange, options) => {
    return (
        <Form.Field key={label}>
            <label>{label}</label>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 40px)",
                    rowGap: "5px",
                    columnGap: "5px",
                }}
            >
                {options.map((item) => (
                    <Button
                        key={item[0]}
                        style={{
                            margin: 0,
                        }}
                        icon
                        onClick={() => {
                            onChange(item[0]);
                        }}
                        primary={value === item[0]}
                    >
                        {item[1]}
                    </Button>
                ))}
            </div>
        </Form.Field>
    );
};

export const imgSrcOption = (label, value, setValue) => {
    return (
        <Form.Field key={label}>
            <label>{label}</label>
            <ImagePanel
                button={
                    <img
                        src={value.value}
                        style={{
                            width: "100%",
                        }}
                        alt="Logo"
                    />
                }
                onPhoto={(photo) => {
                    setValue({
                        params: photo,
                        value: photo.full,
                    });
                }}
            />
        </Form.Field>
    );
};

export const ytSourceOption = (label, value, setValue) => {
    function youtube_parser(url) {
        var regExp =
            /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return match && match[7].length == 11 ? match[7] : false;
    }

    return (
        <Fragment>
            <Form.Field>
                <label>{label}</label>
                <input
                    type="text"
                    placeholder="Youtube Link"
                    value={value.params}
                    onChange={(eve) => {
                        const video_id = youtube_parser(eve.target.value);
                        if (video_id) {
                            setValue({
                                ...value,
                                params: eve.target.value,
                                value: youtube_parser(eve.target.value),
                            });
                        } else {
                            setValue({
                                ...value,
                                params: eve.target.value,
                                value: false,
                            });
                        }
                    }}
                />
                <div
                    style={{
                        paddingTop: "5px",
                        color: value.value ? "green" : "red",
                    }}
                >
                    {value.value ? "Link is valid." : "Link is not valid."}
                </div>
            </Form.Field>
        </Fragment>
    );
};

export const iconOption = (label, value, setValue) => {
    return (
        <Form.Field key={label}>
            <label>{label}</label>
            <IconDialog
                button={
                    <span
                        css={{
                            fontSize: "30px",
                            color: "black",
                            backgroundColor: "#E0E1E2",
                            width: "60px",
                            height: "60px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                            ":hover": {
                                cursor: "pointer",
                            },
                        }}
                    >
                        <i className={value.value}></i>
                    </span>
                }
                setIcon={(newIcon) => {
                    setValue(newIcon);
                }}
            />
        </Form.Field>
    );
};

// combiner= a function to convert from params to value
export const fourSide = (
    label,
    value,
    setValue,
    combiner,
    min = 0,
    max = 100,
    corner = false
) => {
    const params = value.params;
    const sliderValue = value.type === "single" ? params : params[params.cside];

    // if (sliderValue > 70) {
    // 	max = sliderValue + Math.floor(sliderValue / 40);
    // }

    if (sliderValue > max) {
        max = sliderValue;
    }

    const onChange = (newVal) => {
        log.p(`Changing value via input:: ${newVal}`);
        if (value.type === "single") {
            setValue({
                type: "single",
                params: newVal,
                value: `${newVal}px`,
            });
        } else {
            const combinedValue = combiner({
                ...params,
                [params.cside]: newVal,
            });
            log.d(combinedValue, "Multiple paddign vlaue");
            setValue({
                type: "multiple",
                params: {
                    ...params,
                    [params.cside]: newVal,
                },
                // value: combiner(params),
                value: combinedValue,
            });
        }
    };

    const onSideChange = (side) => {
        if (value.type === "single") {
            setValue({
                ...value,
                type: "multiple",
                params: {
                    cside: side,
                    top: value.params,
                    right: value.params,
                    bottom: value.params,
                    left: value.params,
                },
            });
        } else {
            setValue({
                ...value,
                type: "multiple",
                params: { ...params, cside: side },
            });
        }
    };
    return (
        <Form.Field key={label}>
            <label>{label}</label>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 40px)",
                    rowGap: "5px",
                    columnGap: "5px",
                }}
            >
                {[
                    ["top", "arrow up"],
                    ["right", "arrow right"],
                    ["bottom", "arrow down"],
                    ["left", "arrow left"],
                ].map((item, index) => {
                    return (
                        <Button
                            key={index}
                            style={{
                                margin: 0,
                            }}
                            icon
                            onClick={() => {
                                onSideChange(item[0]);
                            }}
                            primary={params.cside === item[0]}
                        >
                            <Icon
                                name={item[1]}
                                style={
                                    corner
                                        ? { transform: "rotate(-45deg)" }
                                        : {}
                                }
                            />
                        </Button>
                    );
                })}

                <Button
                    style={{
                        margin: 0,
                    }}
                    icon
                    onClick={() => {
                        const temp =
                            value.type === "single"
                                ? params
                                : parseInt(
                                      (params.left +
                                          params.right +
                                          params.top +
                                          params.bottom) /
                                          4
                                  );
                        setValue({
                            type: "single",
                            params: temp,
                            value: `${temp}px`,
                        });
                    }}
                    primary={value.type === "single"}
                >
                    <Icon
                        name={value.type === "single" ? "lock" : "lock open"}
                    />
                </Button>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                <Form.Field style={{ flex: 1 }}>
                    <label style={{ marginBottom: "25px" }}></label>
                    <Range
                        step={1}
                        min={min}
                        max={max}
                        // max={max}
                        values={[sliderValue]}
                        onChange={(values) => {
                            log.p("Range changed to " + value);
                            onChange(values[0]);
                        }}
                        // onFinalChange={(values) => {
                        // 	log.d(values, "New Range Value");
                        // 	max = values[0] + 50;
                        // 	onChange(values[0]);
                        // }}
                        renderTrack={({ props, children }) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: "6px",
                                    width: "100%",
                                    backgroundColor: "#5f9de8",
                                    borderRadius: "2px",
                                }}
                            >
                                {children}
                            </div>
                        )}
                        renderThumb={({ props }) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: "15px",
                                    width: "15px",
                                    backgroundColor: "white",
                                    border: "1px solid black",
                                    borderRadius: "2px",
                                }}
                            />
                        )}
                    />
                </Form.Field>

                <Form.Input
                    style={{
                        width: "70px",
                        marginTop: "8px",
                        paddingLeft: "1px",
                        paddingRight: "-10px",
                    }}
                    type="number"
                    value={sliderValue}
                    onChange={(eve) => onChange(parseInt(eve.target.value))}
                />
            </div>
        </Form.Field>
    );
};

export const fourSide2 = (
    label,
    value,
    setValue,
    combiner,
    min = 0,
    max = 100
) => {
    const params = value.params;
    const sliderValue = value.type === "single" ? params : params[params.cside];

    const onChange = (newVal) => {
        log.p("Changing value");
        if (value.type === "single") {
            setValue({
                type: "single",
                params: newVal,
                value: `${newVal}px`,
            });
        } else {
            const combinedValue = combiner(newVal);
            log.p(combinedValue, "Multiple paddign vlaue");
            setValue({
                type: "multiple",
                params: {
                    ...params,
                    [params.cside]: newVal,
                },
                value: combinedValue,
            });
        }
    };

    const onSideChange = (side) => {
        if (value.type === "single") {
            setValue({
                ...value,
                type: "multiple",
                params: {
                    cside: side,
                    top: value.params,
                    right: value.params,
                    bottom: value.params,
                    left: value.params,
                },
            });
        } else {
            setValue({
                ...value,
                type: "multiple",
                params: { ...params, cside: side },
            });
        }
    };
    return (
        <Form.Field key={label}>
            <label>{label}</label>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 40px)",
                    rowGap: "5px",
                    columnGap: "5px",
                }}
            >
                {[
                    ["top", "arrow up"],
                    ["right", "arrow right"],
                    ["bottom", "arrow down"],
                    ["left", "arrow left"],
                ].map((item, index) => {
                    return (
                        <Button
                            key={index}
                            style={{
                                margin: 0,
                            }}
                            icon
                            onClick={() => {
                                onSideChange(item[0]);
                            }}
                            primary={params.cside === item[0]}
                        >
                            <Icon name={item[1]} />
                        </Button>
                    );
                })}

                <Button
                    style={{
                        margin: 0,
                    }}
                    icon
                    onClick={() => {
                        const temp =
                            value.type === "single"
                                ? params
                                : parseInt(
                                      (params.left +
                                          params.right +
                                          params.top +
                                          params.bottom) /
                                          4
                                  );
                        setValue({
                            type: "single",
                            params: temp,
                            value: `${temp}px`,
                        });
                    }}
                    primary={value.type === "single"}
                >
                    <Icon
                        name={value.type === "single" ? "lock" : "lock open"}
                    />
                </Button>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                <Form.Field style={{ flex: 1 }}>
                    <label style={{ marginBottom: "25px" }}></label>
                    <Range
                        step={1}
                        min={min}
                        max={max}
                        // max={max}
                        values={[sliderValue]}
                        onChange={(values) => {
                            log.p("Range changed to " + value);
                            onChange(values[0]);
                        }}
                        renderTrack={({ props, children }) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: "6px",
                                    width: "100%",
                                    backgroundColor: "#5f9de8",
                                    borderRadius: "2px",
                                }}
                            >
                                {children}
                            </div>
                        )}
                        renderThumb={({ props }) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: "15px",
                                    width: "15px",
                                    backgroundColor: "white",
                                    border: "1px solid black",
                                    borderRadius: "2px",
                                }}
                            />
                        )}
                    />
                </Form.Field>

                <Form.Input
                    style={{
                        width: "70px",
                        marginTop: "8px",
                        paddingLeft: "1px",
                    }}
                    type="number"
                    value={sliderValue}
                    onChange={(eve) => onChange(parseInt(eve.target.value))}
                />
            </div>
        </Form.Field>
    );
};
