export const OPTIONS = {
    headingTag: [
        ["H1", "h1"],
        ["H2", "h2"],
        ["H3", "h3"],
        ["H4", "h4"],
        ["H5", "h5"],
        ["H6", "h6"],
    ],
    columnCount: [
        [1, 1],
        [2, 2],
        [3, 3],
        [4, 4],
    ],
    textAlign: [
        ["left", "align left"],
        ["center", "align center"],
        ["right", "align right"],
        ["justify", "align justify"],
    ],
    buttonAlign: [
        ["flex-start", "align left"],
        ["center", "align center"],
        ["flex-end", "align right"],
        ["stretch", "align justify"],
    ],
    alignImage: [
        ["flex-start", "align left"],
        ["center", "align center"],
        ["flex-end", "align right"],
    ],
    alignVideo: [
        ["flex-start", "align left"],
        ["center", "align center"],
        ["flex-end", "align right"],
    ],
    alignList: [
        ["flex-start", "align left"],
        ["center", "align center"],
        ["flex-end", "align right"],
    ],
    alignItem: [
        ["flex-start", "align left"],
        ["center", "align center"],
        ["flex-end", "align right"],
    ],
    frameType: [
        ["none", "dont"],
        ["solid", "square"],
        ["frame", "square outline"],
    ],
    alignChilds: [
        ["flex-start", "long arrow alternate up"],
        ["center", "dot circle outline"],
        ["flex-end", "long arrow alternate down"],
        ["stretch", "arrows alternate vertical"],
    ],
    orientation: [
        ["h", "ellipsis horizontal"],
        ["v", "ellipsis vertical"],
    ],
    theme: [
        ["dark", "square"],
        ["light", "square outline"],
    ],
    language: [
        ["Python", "python"],
        ["JSX", "jsx"],
        ["CSS", "css"],
    ],
    codeEditorTheme: [
        ["Dracula", "dracula"],
        ["Night Owl", "nightOwl"],
        ["Github", "github"],
        ["Ocianic Next", "oceanicNext"],
        ["Shade Of Purple", "shadesOfPurple"],
    ],
    lineStyle: [
        ["Solid", "solid"],
        ["Dashed", "dashed"],
        ["Dotted", "dotted"],
        ["Rounded", "rounded"],
    ],
    hoverAnimation: [
        ["None", "qureal-none"],
        ["Grow", "qureal-grow"],
        ["Shrink", "qureal-shrink"],
        ["Pulse", "qureal-pulse"],
        ["Push", "qureal-push"],
        ["Pop", "qureal-pop"],
        ["Float", "qureal-float"],
        ["Bob", "qureal-bob"],
        ["Forward", "qureal-forward"],
        ["Grow Shadow", "qureal-grow-shadow"],
    ],
    voiceLangs: [
        ["English", "en-IN"],
        ["Hindi", "hi-IN"],
        ["Marathi", "mr-IN"],
        ["Bengali", "bn-IN"],
        ["Gujarati", "gu-IN"],
        ["Kannada", "kn-IN"],
        ["Malayalam", "ml-IN"],
        ["Tamil", "ta-IN"],
        ["Telugu", "te-IN"],
    ],
};
