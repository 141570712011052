import React from "react";
import { Modal, Button } from "semantic-ui-react";

function LoadingDialog({ title }) {
    return (
        <Modal open={true} size="tiny">
            <Modal.Content>
            <div
            style={{
                width: "100%",
                height: "200px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
            }}
        >
            <img
                src={process.env.PUBLIC_URL + "/logo.svg"}
                alt="Qureal Logo"
                style={{
                    width: "50px",
                    opacity: 0.2,
                }}
            />
            <h3
                style={{
                    textAlign: "center",
                    color: "#c4c4c4",
                    marginTop: "10px",
                }}
            >
                Loading...
            </h3>
        </div>
            </Modal.Content>
        </Modal>
    );
}

export default LoadingDialog;
