import { atom, selector } from "recoil";
import log from "cslog";

export const EDITOR_VERSION = 2;

export const pageArray = {};

export const pageState = atom({
    key: "pages",
    default: [
        { name: "Home", id: "home" },
        // { name: "404", id: "404" },
        // { name: "About Us", id: "about_us" },
    ],
});

export const menuState = atom({
    key: "menuItems",
    default: [
        {
            name: "Home",
            id: "home",
            link: { type: "page", params: "home", value: "/home.html" },
        },
        {
            name: "About",
            id: "about",
            link: { type: "page", params: "about_us", value: "/about_us.html" },
        },
    ],
});

export const selectedPageState = atom({
    key: "selectedPage",
    default: {
        id: "home",
        name: "Home",
    },
});

export const siteLoaded = atom({
    key: "siteLoaded",
    default: false,
});

export const siteInfoState = atom({
    key: "siteInfo",
    default: {
        id: null,
        siteplan: null,
        domain: null,
        custom_domain: null,
        editorInfo: {
            version: 1,
            editorVersion: EDITOR_VERSION,
            lastUpdated: new Date().getTime(),
        },
    },
});
