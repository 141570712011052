/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/react";
import React from "react";
import ElementWrapper from "./wrappers/ElementWrapper";
import { useRecoilState, useSetRecoilState } from "recoil";
import { elementState, selectedElementState } from "../db/elementDb";
import { parseStyle, decodeStyle } from "../parser/styleparser";
import ContentEditable from "react-contenteditable";
import { tablet, mobile } from "../data/bp";
import log from "cslog";
import classNames from "classnames";

function Header({ id, index, setHoverIndex, direction, device, gs }) {
    const [data, setData] = useRecoilState(elementState(id));
    const setSelectedElement = useSetRecoilState(selectedElementState);

    const handleChange = (eve) => {
        log.d(eve.target.value, "Changed Heading content");
        setData({
            ...data,
            input: {
                ...data.input,
                content: eve.target.value,
            },
        });
    };

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    let content = input?.content;

    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    // log.d(data.classes, " Classes in data");
    // log.d(classNames(data.classes), "Classes in Header");

    // if (ps.classes.q_hide_on_mobile && device === "mobile") {
    //     return null;
    // }

    const onPaste = (eve) => {
        eve.preventDefault();
        var text = eve.clipboardData.getData("text");
        document.execCommand("insertText", false, text);
    };

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            <ContentEditable
                className={classNames(ps.classes)}
                // html={input ? input.id.content : data.input.content}
                // html={input ? input.content : "Something"}
                html={content ? content : ""}
                onChange={handleChange}
                disabled={false}
                tagName={input.headingTag.type}
                onPaste={onPaste}
                // className="qh1"
                css={{
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    textAlign: input.textAlign,
                    textTransform: input.uppercase ? "uppercase" : "none",
                    ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                    ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                    fontSize:
                        input.headingTag.type === "div"
                            ? input.headingTag.value
                            : "auto",
                    // lineHeight:
                    //     input.headingTag.type === "div"
                    //         ? input.headingTag.value
                    //         : "auto",
                    outline: "none",
                }}
                onFocusCapture={() => {
                    setSelectedElement(id);
                }}
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        document.execCommand("insertLineBreak");
                        event.preventDefault();
                    }
                }}
            />
        </ElementWrapper>
    );
}

export default Header;
