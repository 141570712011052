/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import "./styles/QurealTestEditor.css";
import React, { Fragment, useState } from "react";
import ReactQuil, { Quill } from "react-quill";
import { Modal, Button } from "semantic-ui-react";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import BlotFormatter, {
    ImageSpec,
    ResizeAction,
    AlignAction,
    DeleteAction,
} from "quill-blot-formatter";
import log from "cslog";

import ImagePanel from "../ImagePanel";

// import { DividerToolbar, Divider } from "./Divider";
// Quill.register(Divider);
// Quill.register("modules/divider", DividerToolbar);
// // ref= https://github.com/rain0002009/quill-divider

Quill.register("modules/blotFormatter", BlotFormatter);
class CustomImageSpec extends ImageSpec {
    getActions() {
        return [ResizeAction, AlignAction, DeleteAction];
    }
}

function insertPhoto() {
    const cursorPosition = this.quill.getSelection().index;
    this.quill.insertEmbed(
        cursorPosition,
        "image",
        "https://i.stack.imgur.com/ILTQq.png"
    );
    this.quill.setSelection(cursorPosition + 2);
}

function undo() {
    this.quill.history.undo();
}

function redo() {
    this.quill.history.redo();
}

function insertStar() {
    const cursorPosition = this.quill.getSelection().index;
    // this.quill.insertText(cursorPosition, "★ ");
    this.quill.insertText(cursorPosition, "\n");
    this.quill.insertText(cursorPosition + 1, "STAR");
    this.quill.setSelection(this.quill.getLength());
}

function Seperator() {
    return (
        <button
            style={{
                width: "1px",
                borderLeft: "1px solid #dbdbdb",
                backgroundColor: "white",
                marginLeft: "5px",
                marginRight: "-5px",
                cursor: "select",
            }}
        ></button>
    );
}

export const BasicToolbar = ({ id }) => {
    return (
        <div id={id}>
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            {/* <button className="ql-strike" /> */}
            <button className="ql-code" />
            <select className="ql-color"></select>
            <select className="ql-background"></select>
            <button className="ql-link"></button>
            <select className="ql-align"></select>
        </div>
    );
};

const CustomToolbar = ({ setImage }) => (
    <div id="toolbar">
        <button className="ql-undo">
            <i class="fas fa-undo"></i>
        </button>
        <button className="ql-redo">
            <i class="fas fa-redo"></i>
        </button>
        <Seperator />

        <select
            className="ql-header"
            defaultValue={""}
            onChange={(e) => e.persist()}
        >
            <option value="1" />
            <option value="2" />
            <option value="3" />
            <option value="4" />
            <option selected />
        </select>
        <select class="ql-font">
            <option value="comic-sans">Comic Sans</option>
            <option value="courier-new">Courier New</option>
            <option value="georgia">Georgia</option>
            <option value="helvetica">Helvetica</option>
            <option value="lucida" selected>
                Lucida
            </option>
            <option value="monospace">Monospace</option>
        </select>
        <select class="ql-size">
            <option value="small"></option>
            <option selected></option>
            <option value="large"></option>
            <option value="huge"></option>
        </select>

        <Seperator />
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
        <button className="ql-code" />
        <select className="ql-color"></select>
        {/* <select className="ql-color">
            <option value="yellow"></option>
            <option value="cyan"></option>
            <option value="blue"></option>
            <option value="orange"></option>
            <option value="violet"></option>
            <option value="#d0d1d2"></option>
            <option value="purple"></option>
            <option selected></option>
            <option value="custom-color">Custom Color</option>
        </select> */}
        <select className="ql-background"></select>
        <Seperator />

        <button className="ql-link"></button>
        {/* <button className="ql-image"></button> */}
        <ImagePanel
            button={
                <button>
                    <i class="fas fa-image"></i>
                </button>
            }
            onPhoto={(photo) => {
                setImage(photo.full);
            }}
        />
        <button className="ql-video"></button>
        {/* <select className="ql-align"></select> */}
        <Seperator />
        <button className="ql-align" value="" />
        <button className="ql-align" value="center" />
        <button className="ql-align" value="right" />
        <button className="ql-align" value="justify" />
        <Seperator />

        <button className="ql-blockquote"></button>
        <button className="ql-code-block"></button>

        <Seperator />
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
        <button className="ql-indent" value="+1"></button>
        <button className="ql-indent" value="-1"></button>
        <Seperator />

        <button className="ql-clean"></button>
        {/* <button className="ql-formula"></button> */}

        {/* <button class="ql-script" value="sub"></button>
        <button class="ql-script" value="super"></button> */}
        {/* <Seperator /> */}
        {/* <button className="ql-insertStar">
            <i class="fas fa-star"></i>
        </button> */}
    </div>
);

const Font = Quill.import("formats/font");
Font.whitelist = [
    "arial",
    "comic-sans",
    "courier-new",
    "georgia",
    "helvetica",
    "lucida",
];
Quill.register(Font, true);

class QurealTextEditor extends React.Component {
    constructor(props) {
        super(props);
        // this.state = {
        //     html: "",
        // };
        this.ref = React.createRef();
    }

    render() {
        return (
            <Fragment>
                <CustomToolbar
                    setImage={(img) => {
                        // this.ref.getEditor().format("color", "hotpink");
                        this.ref.getEditor().focus();
                        const cursorPosition = this.ref
                            .getEditor()
                            .getSelection().index;
                        this.ref
                            .getEditor()
                            .insertEmbed(cursorPosition, "image", img);
                        this.ref
                            .getEditor()
                            .insertText(cursorPosition + 2, "\n");
                        this.ref.getEditor().setSelection(cursorPosition + 3);
                    }}
                />
                <ReactQuil
                    ref={(el) => {
                        this.ref = el;
                    }}
                    theme="snow"
                    value={this.props.html}
                    onChange={(newHtml) => this.props.setHtml(newHtml)}
                    modules={QurealTextEditor.modules}
                    formats={QurealTextEditor.formats}
                    placeholder="Start writing epic...."
                    css={{
                        ".ql-editor": this.props.custom_style,
                    }}
                ></ReactQuil>
            </Fragment>
        );
    }
}

QurealTextEditor.modules = {
    toolbar: {
        container: "#toolbar",
        handlers: {
            undo: undo,
            redo: redo,
            image: insertPhoto, // override defalt , remove for default
            insertStar: insertStar,
        },
    },
    clipboard: {
        matchVisual: false,
    },
    blotFormatter: {
        specs: [CustomImageSpec],
        overlay: {
            style: {
                border: "2px solid blue",
            },
        },
    },
};

const FORMATS = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "background",
    "code-block",
    "code",
    "script",
    "align",
    "formula",
    "hr",
];

QurealTextEditor.formats = FORMATS;

export class QurealTextEditorBasic extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        this.modules = {
            toolbar: {
                container: `#${this.props.id}`,
                // container: "#qqqtttoolbar",
            },
            clipboard: {
                matchVisual: false,
            },
        };
    }

    render() {
        return (
            <Fragment>
                {/* <h2 onClick={() => this.props.onFocus()}>Not Active</h2> */}
                <BasicToolbar id={this.props.id} />
                <ReactQuil
                    readOnly={!this.props.active}
                    ref={(el) => {
                        this.ref = el;
                    }}
                    theme="bubble"
                    // value={this.state.html}
                    // onChange={(newHtml) => this.setState({ html: newHtml })}
                    value={this.props.html}
                    onChange={(newHtml) => this.props.setHtml(newHtml)}
                    modules={this.modules}
                    formats={QurealTextEditorBasic.formats}
                    placeholder="Start writing epic...."
                    css={css`
                        .ql-editor {
                            font-size: ${this.props.styles.fontSize}px;
                            overflow: visible;
                            min-height: auto;
                            padding: 0px;
                        }
                        .ql-bubble .ql-tooltip {
                            background-color: #2146b2;
                            //background-color: green;
                            //this fixed position did all the megic
                            position: fixed;
                            padding: 7px 15px;
                            z-index: 1000;
                        }
                        .ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
                            border-bottom: 6px solid #2146b2;
                        }
                        .ql-bubble .ql-tooltip.ql-flip .ql-tooltip-arrow {
                            border-top: 6px solid #2146b2;
                        }
                        .ql-bubble .ql-stroke {
                            fill: none;
                            stroke: #dbdbdb;
                        }
                    `}
                    onFocus={this.props.onFocus}
                />
            </Fragment>
        );
    }
}

// QurealTextEditorBasic.modules = {
//     toolbar: {
//         container: "#toolbar-basic",
//     },
//     clipboard: {
//         matchVisual: false,
//     },
// };

QurealTextEditorBasic.formats = FORMATS;

export function QurealTextEditorDialog({ html, setHtml, trigger }) {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(html);

    const custom_style = {
        fontSize: "1.1rem",
        minHeight: "75vh",
    };

    log.d(html, "HTML in QTEDialog");

    return (
        <Modal
            size="large"
            open={open}
            trigger={trigger}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            style={{
                marginTop: "-10px",
                // paddingTop: "0px",
            }}
        >
            {/* <Modal.Header>Qureal Text Editor</Modal.Header> */}
            <Modal.Content
                style={{
                    padding: 0,
                    paddingTop: "3px",
                    // height: "85vh",
                }}
            >
                <QurealTextEditor
                    html={data}
                    setHtml={setData}
                    custom_style={custom_style}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color="black"
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Cancle
                </Button>
                <Button
                    primary
                    onClick={() => {
                        setHtml(data);
                        log.d(data, "HTML Data");
                        setOpen(false);
                    }}
                >
                    Done
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
