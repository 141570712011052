import React from "react";
import { atom } from "recoil";

export const elementMenuState = atom({
	key: "elementMenuState",
	default: null,
});

export const containerMenuState = atom({
	key: "containerMenuState",
	default: null,
});

export const sectionMenuState = atom({
	key: "sectionMenuState",
	default: null,
});

export const copyState = atom({
	key: "copyState",
	default: null,
});
