import React, { useState } from "react";
import Script from "react-load-script";
import log from "cslog";
import Helmet from "react-helmet";
import { jsx } from "@emotion/react";

function ScriptLoader({ scriptPath, stylePath, onLoad }) {
    const [status, setStatus] = useState("loading");
    let loaded = 0;

    const onScLoaded = () => {
        loaded += 1;
        if (scriptPath.length <= loaded) {
            onLoad(true);
        }
    };

    return (
        <div
            style={{
                padding: "50px",
                backgroundColor: status === "error" ? "red" : "cyan",
            }}
        >
            {Array.isArray(scriptPath) ? (
                scriptPath.map((item, index) => (
                    <Script
                        key={index}
                        url={item}
                        onCreate={() => log.p("Script load onCreate")}
                        onError={() => setStatus("error")}
                        onLoad={() => {
                            log.p("Script loaded");
                            // onLoad(true);
                            onScLoaded();
                        }}
                    />
                ))
            ) : (
                <Script
                    url={scriptPath}
                    onCreate={() => log.p("Script load onCreate")}
                    onError={() => setStatus("error")}
                    onLoad={() => {
                        log.p("Script loaded");
                        onLoad(true);
                    }}
                />
            )}
            {stylePath && (
                <Helmet>
                    <link rel="stylesheet prefetch" href={stylePath}></link>
                </Helmet>
            )}
            <h2>
                {status === "error"
                    ? "Error while initializing component"
                    : "Initializing component..."}
            </h2>
        </div>
    );
}

export default ScriptLoader;
