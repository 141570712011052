// const BREAKPOINTS = [768, 480];
// https://www.freecodecamp.org/news/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862/
// const BREAKPOINTS = [900, 600];

// Mobile: 0-600
// Tablet: 600-800
// Desktop: >800

// export const desktop = `@media (max-width: ${BREAKPOINTS[0]}px)`;
// export const tablet = `@media (max-width: ${BREAKPOINTS[1]}px)`;
// export const mobile = `@media (max-width: ${BREAKPOINTS[2]}px)`;

// export const tablet = (device) => ["tablet", "mobile"].includes(device);
// export const mobile = (device) => device === "mobile";

export const DESKTOP = 2040;
export const TABLET = 768;
export const MOBILE = 480;

export const desktop = (device) => device <= DESKTOP;
export const tablet = (device) => device <= TABLET;
export const mobile = (device) => device <= MOBILE;

export const getDevice = (width) => {
	if (width <= DESKTOP && width > TABLET) {
		return "desktop";
	} else if (width <= TABLET && width > MOBILE) {
		return "tablet";
	} else {
		return "mobile";
	}
};
