import React, { useState } from "react";
import Gallery from "react-photo-gallery";
import API from "../api/tenor";
import { Input, Button } from "semantic-ui-react";
import log from "cslog";

import CImage from "./CImage";

import { useRecoilState } from "recoil";
import { selectedImageState } from "../db/elementDb";

const TenorGif = ({ placeholder }) => {
    const [term, setTerm] = useState("nature");
    const [data, setData] = useState();
    const [search, setSearch] = useState("");

    const [selectedImage, setSelectedImage] =
        useRecoilState(selectedImageState);

    React.useEffect(() => {
        log.d(term, "Now searching");
        API.get(`/search?key=QMOXPYZL62ZY`, {
            params: { q: term, limit: 12 },
        })
            .then((response) => {
                console.log(response.data);
                // if (!data) {
                setData(response.data.results);
                // }
            })
            .catch((err) => {
                console.log(err);
            });
        // eslint-disable-next-line
    }, [search]);

    return (
        <div style={{ zIndex: 5000 }}>
            {data ? (
                <div>
                    <Input
                        placeholder="Search Tenor"
                        value={term}
                        onChange={(eve) => setTerm(eve.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                setSearch(term);
                            }
                        }}
                        fluid
                        action={
                            <Button
                                positive
                                onClick={() => setSearch(term)}
                                loading={false}
                            >
                                Search
                            </Button>
                        }
                    />
                    {/* <Divider /> */}
                    <div
                        style={{
                            height: "70vh",
                            overflow: "auto",
                            marginTop: "10px",
                        }}
                    >
                        <Gallery
                            direction={"column"}
                            columns={3}
                            renderImage={CImage}
                            photos={data.map((item) => {
                                const gif = item.media[0].gif;
                                const nanogif = item.media[0].tinygif;
                                return {
                                    src: nanogif.url,
                                    srcRegular: gif.url,
                                    width: gif.dims[0],
                                    height: gif.dims[1],
                                    id: item.id,
                                    sid: selectedImage.id,
                                    onPhotoClick: (img) => {
                                        // log.d(item, "Selected Item");
                                        // log.d(img, "Selected Image");
                                        const imgObj = {
                                            id: item.id,
                                            width: gif.dims[0],
                                            height: gif.dims[1],
                                            description: item.title,
                                            thumb: nanogif.url,
                                            full: gif.url,
                                            small: nanogif.url, //optional
                                            // regular: item.urls.full,
                                        };
                                        log.d(imgObj, "Created Selected Img");
                                        setSelectedImage(imgObj);
                                    },
                                };
                            })}
                        />
                    </div>
                </div>
            ) : (
                placeholder
            )}
        </div>
    );
};

export default TenorGif;
