import React from "react";
import { useRecoilValue } from "recoil";
import { elementState } from "./db/elementDb";

import Page from "./elements_freeview_static/Page";
import Container from "./elements_freeview_static/Container";
import HorizontalContainer from "./elements_freeview_static/HorizontalContainer";
import Header from "./elements_freeview_static/Header";
import Text from "./elements_freeview_static/Text";
import RichText from "./elements_freeview_static/RichText";
import Button from "./elements_freeview_static/Button";
import Section from "./elements_freeview_static/Section";
import Button3D from "./elements_freeview_static/custom/Button3D";
import List from "./elements_freeview_static/List";
import Img from "./elements_freeview_static/Img";
import Video from "./elements_freeview_static/Video";
import QIcon from "./elements_freeview_static/QIcon";
import Blank from "./elements_freeview_static/Blank";
import NavMenu from "./elements_freeview_static/NavMenu";
import Countdown from "./elements_freeview_static/custom/Countdown";
import CodeView from "./elements_freeview_static/custom/CodeView";
import Divider from "./elements_freeview_static/Divider";
import IconList from "./elements_freeview_static/IconList";
import HeaderSection from "./elements_freeview_static/HeaderSection";
import BookPage from "./elements_freeview_static/book/BookPage";
import GridBox from "./elements_freeview_static/book/GridBox";
import GridBoxPreview from "./elements_freeview_static/book/GridBoxPreview";
import Shape from "./elements_freeview_static/Shape";
import PageLink from "./elements_freeview_static/PageLink";
import log from "cslog";

function Element({
    id,
    db,
    index,
    setHoverIndex,
    direction,
    device,
    gs,
    preview,
}) {
    // const data = useRecoilValue(elementState(id));
    const data = db[id];

    if (!data) {
        return <p>Nothing found</p>;
    }

    switch (data.type) {
        case "page":
            return (
                <Page
                    id={id}
                    db={db}
                    index={index}
                    device={device}
                    gs={gs}
                    preview={preview}
                />
            );
        // return <BookPage id={id} index={index} device={device} gs={gs} />;
        case "bookPage":
            return (
                <BookPage
                    id={id}
                    db={db}
                    index={index}
                    device={device}
                    gs={gs}
                    preview={preview}
                />
            );
        case "gridBox":
            if (preview.isPreview) {
                return (
                    <GridBoxPreview
                        id={id}
                        db={db}
                        index={index}
                        device={device}
                        gs={gs}
                        preview={preview}
                    />
                );
            } else {
                return (
                    <GridBox
                        id={id}
                        db={db}
                        index={index}
                        device={device}
                        gs={gs}
                        preview={preview}
                    />
                );
            }
        case "section":
            return (
                <Section
                    id={id}
                    db={db}
                    index={index}
                    device={device}
                    gs={gs}
                />
            );
        case "headerSection":
            return (
                <HeaderSection
                    id={id}
                    db={db}
                    index={index}
                    device={device}
                    gs={gs}
                />
            );
        case "box":
            return (
                <Container
                    id={id}
                    db={db}
                    index={index}
                    device={device}
                    gs={gs}
                />
            );
        case "hbox":
            return <HorizontalContainer id={id} index={index} gs={gs} />;
        case "header":
            return (
                <Header
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    device={device}
                    gs={gs}
                />
            );
        case "p":
        case "text":
            return (
                <Text
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "link":
            return (
                <PageLink
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "richtext":
            return (
                <RichText
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "button":
            return (
                <Button
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "img":
            return (
                <Img
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "video":
            return (
                <Video
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "icon":
            return (
                <QIcon
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "shape":
            return (
                <Shape
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "button3d":
            return (
                <Button3D
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "list":
            return (
                <List
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "iconList":
            return (
                <IconList
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "navMenu":
            return (
                <NavMenu
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "blank":
            return (
                <Blank
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "divider":
            return (
                <Divider
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "countdown":
            return (
                <Countdown
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "codeview":
            return (
                <CodeView
                    id={id}
                    db={db}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        default:
            return <p>Element Not found: {data.type}</p>;
    }
}

export default Element;
