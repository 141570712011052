import React, { useState } from "react";
import { useDrop } from "react-dnd";
import log from "cslog";

import ContainerWrapper from "./wrappers/ContainerWrapper";
import {
    Snapshot,
    useRecoilCallback,
    useRecoilState,
    useSetRecoilState,
} from "recoil";
import {
    elementState,
    addElement,
    selectedElementState,
} from "../db/elementDb";
import Element from "../Element";
import { v4 as uuid } from "uuid";
import { nanoid } from "nanoid";

import { ELEMENTS } from "../data/panelConfig";
import { Icon } from "semantic-ui-react";

// TODO: cross parent child move
function Container({ id, index, device, gs }) {
    const [data, setData] = useRecoilState(elementState(id));

    const removeChildFromParent = useRecoilCallback(
        ({ set, snapshot }) =>
            async (pid, index, id, toParent) => {
                log.d(pid, "PID in removeChildFromParent");

                await set(elementState(pid), (data) => {
                    const newChilds = Array.from(data.childs);
                    newChilds.splice(index, 1);
                    return {
                        ...data,
                        childs: newChilds,
                    };
                });
                await set(elementState(id), (data) => {
                    return {
                        ...data,
                        pid: toParent,
                    };
                });
            }
    );

    let hoverIndex = 999;
    const setHoverIndex = (newHI) => {
        hoverIndex = newHI;
    };

    const [{ isOver, isOverCurrent }, drop] = useDrop({
        // accept: "basic",
        accept: ["basic", "complex", "section"],
        drop: async (item, monitor) => {
            const didDrop = monitor.didDrop();
            if (didDrop) {
                return;
            }

            if (!item.data) {
                log.d(item, "Sorting");
                let fromIndex = item.index;
                let toIndex = hoverIndex;
                log.p(`From: ${fromIndex}, To: ${toIndex}`);
                let fromParent = item.pid;
                let toParent = id;
                log.p(`From ${fromParent}, To: ${toParent}`);

                const newChilds = Array.from(data.childs);

                if (!fromParent) {
                    return;
                }

                if (fromParent === toParent) {
                    if (fromIndex < toIndex) {
                        log.p("Downward");
                        newChilds.splice(toIndex, 0, item.id);
                        log.p("added");
                        newChilds.splice(fromIndex, 1);
                        log.p("removed");
                        setData({ ...data, childs: newChilds });
                    } else {
                        log.p("Upward");
                        newChilds.splice(toIndex, 0, item.id);
                        newChilds.splice(fromIndex + 1, 1);
                        setData({ ...data, childs: newChilds });
                    }
                } else {
                    log.p("Different Parent");
                    await removeChildFromParent(
                        fromParent,
                        fromIndex,
                        item.id,
                        toParent
                    );
                    newChilds.splice(toIndex, 0, item.id);
                    setData({ ...data, childs: newChilds });
                }

                return;
            }

            //Add element
            // ~17 years needed, in order to have a 1% probability of at least one collision
            const eid = "q_" + nanoid(10);
            const edata = {
                ...ELEMENTS[item.data.type],
                id: eid,
                pid: data.id,
            };

            if (item.data.type === "section") {
                const box1_id = "q_" + nanoid(10);
                const box2_id = "q_" + nanoid(10);

                const box1_data = {
                    ...ELEMENTS["box"],
                    id: box1_id,
                    pid: eid,
                };
                addElement(box1_id, box1_data);

                const box2_data = { ...ELEMENTS["box"], id: box2_id, pid: eid };
                addElement(box2_id, box2_data);

                edata["childs"] = [box1_id, box2_id];
                edata["input"] = {
                    ...edata.input,
                    widths: [50, 50],
                    name: "Inner Section",
                    isInner: true,
                };
            }

            addElement(eid, edata);

            const newChilds = Array.from(data.childs);
            newChilds.splice(hoverIndex, 0, eid);
            setData({ ...data, childs: newChilds });
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            isOverCurrent: monitor.isOver({ shallow: true }),
        }),
    });

    const minHeight = "50px";

    //TODO: box childs
    const childs = data.childs;
    return (
        <ContainerWrapper
            label="box"
            index={index}
            data={data}
            gs={gs}
            device={device}
        >
            <div
                ref={drop}
                style={{
                    width: "100%",
                    // height: "auto",
                    height: "100%",
                    minHeight: minHeight,
                    backgroundColor: isOver ? "#4361EE88" : "transparent",
                    display: "flex",
                    flexDirection: "column",
                    gap: 0,
                    // justifyContent: data.input.alignChilds,
                    // paddingBottom: "10px",
                    // ...data.style,
                }}
            >
                {childs.map((item, index) => (
                    <Element
                        key={item}
                        id={item}
                        index={index}
                        setHoverIndex={setHoverIndex}
                        direction="v"
                        device={device}
                        gs={gs}
                    />
                ))}
                {childs.length === 0 && (
                    <div
                        style={{
                            // height: "200px",
                            minHeight: minHeight,
                            marginBottom: "-10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            opacity: 0.15,
                            userSelect: "none",
                            backgroundColor: "#e2e2e2",
                            border: "1px solid black",
                        }}
                    >
                        {/* <Icon name="bars" size="big"/> */}
                        <span
                            style={{
                                fontWeight: "bold",
                                fontSize: "1.2rem",
                                textAlign: "center",
                            }}
                        >
                            DROP HERE
                        </span>
                    </div>
                )}
            </div>
        </ContainerWrapper>
    );
}

export default Container;
