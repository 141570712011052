/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { Modal, Button, Icon, Menu, Grid, Segment } from "semantic-ui-react";
import log from "cslog";
import axios from "axios";

const SECTION_CATS = [
    "all",
    "header",
    "features",
    "teams",
    "coupan",
    "testimonial",
    "footer",
];

function SectionItem({ item, onSelect }) {
    return (
        <div
            css={css`
                border: 1px solid #becad3;
                border-radius: 3px;
                background-color: aliceblue;
                color: black;
                &:hover {
                    background-color: royalblue;
                    color: white;
                }
                &:hover div {
                    color: white;
                }
            `}
        >
            <img
                css={css`
                    width: 100%;
                `}
                src={item.preview}
                alt={item.name}
            />
            <div
                css={css`
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: flex-start;
                    justify-content: center;
                    padding: 5px;
                    cursor: pointer;
                    padding-bottom: 7px;
                `}
                onClick={() => onSelect(item)}
            >
                <Icon name="arrow right" />
                <div
                    css={css`
                        flex: 1;
                        text-align: left;
                        color: black;
                        font-weight: bold;
                        padding-left: 5px;
                    `}
                >
                    {item.name}
                </div>
            </div>
        </div>
    );
}

function SectionBrowser({ open, setOpen, onSelect }) {
    const [cat, setCat] = useState("footer");
    const [data, setData] = useState(null);

    useEffect(() => {
        log.p("Fetching section tempates for " + cat);
        axios
            .get("/templates/sections/public")
            .then((res) => {
                log.d(res.data, "Seciton Tempate Response");
                setData(res.data);
            })
            .catch((err) => {
                log.d(err, "Error");
            })
            .finally(() => log.p("done"));
    }, [cat]);

    const onCatSelect = (e, { name }) => {
        setCat(name);
    };

    const onTemplateSelect = (item) => {
        const tid = item.id;
        axios
            .get(`/templates/sections/get/${item.id}`)
            .then((res) => {
                log.d(res.data, "Seciton Tempate Response");
                onSelect(res.data);
            })
            .catch((err) => {
                log.d(err, "Error");
            })
            .finally(() => log.p("done"));
    };

    return (
        <Modal size="large" onClose={() => setOpen(false)} open={open}>
            <Modal.Header>Pick A Section</Modal.Header>
            <Modal.Content style={{ height: "80vh", paddingRight: 0 }}>
                <div
                    style={{
                        position: "relative",
                        display: "flex",
                        maxHeight: "100%",
                    }}
                >
                    <div style={{ marginRight: "15px" }}>
                        <Menu vertical pointing>
                            {SECTION_CATS.map((item) => (
                                <Menu.Item
                                    name={item}
                                    active={cat === item}
                                    onClick={onCatSelect}
                                />
                            ))}
                        </Menu>
                    </div>
                    <div
                        style={{
                            flex: "1",
                            maxHeight: "100%",
                            overflowY: "auto",
                            color: "grey",
                        }}
                    >
                        {data ? (
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr 1fr",
                                    gap: "10px",
                                    paddingRight: "15px",
                                }}
                            >
                                {data.map((item) => (
                                    <SectionItem
                                        item={item}
                                        // onSelect={onSelect}
                                        onSelect={onTemplateSelect}
                                    />
                                ))}
                            </div>
                        ) : (
                            <h2>Loading...</h2>
                        )}
                    </div>
                </div>
            </Modal.Content>
        </Modal>
    );
}

export default SectionBrowser;
