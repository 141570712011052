/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { mobile, tablet } from "../data/bp";
import { decodeStyle, parseStyle } from "../parser/styleparser";
import ElementWrapper from "./wrappers/ElementWrapper";

// Create a function that gives css for perticular element type
// and then create a css file with that css
function Button({ id, db, index, setHoverIndex, direction, gs, device }) {
    // const data = useRecoilValue(elementState(id));
    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    return (
        <ElementWrapper>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    pointerEvents: "auto",
                }}
            >
                <button
                    className={input.hoverAnimation}
                    css={{
                        "&:hover": decodeStyle(ps.hover, gs),
                    }}
                    data-openpage={7}
                    style={{
                        ...decodeStyle(ps.fixStyle, gs),
                        ...decodeStyle(ps.style, gs),
                        // ...mobileStyle,
                        // alignSelf: input.buttonAlign,
                        alignSelf: "stretch",
                        textTransform: input.uppercase ? "uppercase" : "none",
                        // NOT [...decodeStyle(tabletStyle, gs)] just for optimization
                        ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                        ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                        pointerEvents: "auto",
                    }}
                >
                    {input.content}
                </button>
            </div>
        </ElementWrapper>
    );
}

export default Button;
