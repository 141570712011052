import { atom, selector } from "recoil";

export const editorLayoutState = atom({
    key: "editorLayout",
    default: "left",
});

export const editorViewState = atom({
    key: "editorView",
    default: 2040,
});

export const deviceFrameState = atom({
    key: "deviceFrame",
    default: false,
});

export const editorConfigState = selector({
    key: "editorConfig",
    get: ({ get }) => {
        const editorLayout = get(editorLayoutState);
        const editorView = get(editorViewState);
        const deviceFrame = get(deviceFrameState);

        return {
            editorLayout: editorLayout,
            editorView: editorView,
            deviceFrame: deviceFrame,
        };
    },
});

export const premiumDialogState = atom({
    key: "premiumDialogState",
    default: {
        open: false,
        title: "Hello World",
    },
});

export const authState = atom({
    key: "authState",
    default: {
        isAuth: true,
        user: null,
    },
});
