/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/react";
import React from "react";
import ElementWrapper from "./wrappers/ElementWrapper";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { elementState, selectedElementState } from "../db/elementDb";
import { parseStyle, decodeStyle } from "../parser/styleparser";
import ContentEditable from "react-contenteditable";
import { tablet, mobile } from "../data/bp";
import log from "cslog";
import classNames from "classnames";

function Header({ id, index, setHoverIndex, direction, device, gs }) {
    const data = useRecoilValue(elementState(id));

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    let content = input?.content;

    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    return (
        <ElementWrapper>
            <ContentEditable
                className={classNames(ps.classes)}
                // html={input ? input.id.content : data.input.content}
                // html={input ? input.content : "Something"}
                html={content ? content : ""}
                disabled={true}
                tagName={input.headingTag.type}
                // className="qh1"
                css={{
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    textAlign: input.textAlign,
                    textTransform: input.uppercase ? "uppercase" : "none",
                    ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                    ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                    fontSize:
                        input.headingTag.type === "div"
                            ? input.headingTag.value
                            : "auto",
                    // lineHeight:
                    //     input.headingTag.type === "div"
                    //         ? input.headingTag.value
                    //         : "auto",
                    outline: "none",
                }}
            />
        </ElementWrapper>
    );
}

export default Header;
