import React from "react";
import { useRecoilValue } from "recoil";
import { elementState } from "./db/elementDb";

import Page from "./elements_view/Page";
import Container from "./elements_view/Container";
import HorizontalContainer from "./elements_view/HorizontalContainer";
import Header from "./elements_view/Header";
import Text from "./elements_view/Text";
import RichText from "./elements_view/RichText";
import Button from "./elements_view/Button";
import Section from "./elements_view/Section";
import Button3D from "./elements_view/custom/Button3D";
import List from "./elements_view/List";
import Img from "./elements_view/Img";
import Video from "./elements_view/Video";
import QIcon from "./elements_view/QIcon";
import Blank from "./elements_view/Blank";
import NavMenu from "./elements_view/NavMenu";
import Countdown from "./elements_view/custom/Countdown";
import CodeView from "./elements_view/custom/CodeView";
import Divider from "./elements_view/Divider";
import IconList from "./elements_view/IconList";
import HeaderSection from "./elements_view/HeaderSection";
import BookPage from "./elements_view/book/BookPage";
import GridBox from "./elements_view/book/GridBox";
import GridBoxPreview from "./elements_view/book/GridBoxPreview";
import log from "cslog";

function Element({ id, index, setHoverIndex, direction, device, gs, preview }) {
    const data = useRecoilValue(elementState(id));

    if (!data) {
        return <p>Nothing found</p>;
    }

    switch (data.type) {
        case "page":
            return (
                <Page
                    id={id}
                    index={index}
                    device={device}
                    gs={gs}
                    preview={preview}
                />
            );
        // return <BookPage id={id} index={index} device={device} gs={gs} />;
        case "bookPage":
            return (
                <BookPage
                    id={id}
                    index={index}
                    device={device}
                    gs={gs}
                    preview={preview}
                />
            );
        case "gridBox":
            return (
                <GridBoxPreview id={id} index={index} device={device} gs={gs} />
            );
        case "section":
            return <Section id={id} index={index} device={device} gs={gs} />;
        case "headerSection":
            return (
                <HeaderSection id={id} index={index} device={device} gs={gs} />
            );
        case "box":
            return <Container id={id} index={index} device={device} gs={gs} />;
        case "hbox":
            return <HorizontalContainer id={id} index={index} gs={gs} />;
        case "header":
            return (
                <Header
                    id={id}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    device={device}
                    gs={gs}
                />
            );
        case "p":
        case "text":
            return (
                <Text
                    id={id}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "richtext":
            return (
                <RichText
                    id={id}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "button":
            return (
                <Button
                    id={id}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "img":
            return (
                <Img
                    id={id}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "video":
            return (
                <Video
                    id={id}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "icon":
            return (
                <QIcon
                    id={id}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "button3d":
            return (
                <Button3D
                    id={id}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "list":
            return (
                <List
                    id={id}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "iconList":
            return (
                <IconList
                    id={id}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "navMenu":
            return (
                <NavMenu
                    id={id}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "blank":
            return (
                <Blank
                    id={id}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "divider":
            return (
                <Divider
                    id={id}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "countdown":
            return (
                <Countdown
                    id={id}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        case "codeview":
            return (
                <CodeView
                    id={id}
                    index={index}
                    setHoverIndex={setHoverIndex}
                    direction={direction}
                    gs={gs}
                    device={device}
                />
            );
        default:
            return <p>Element Not found: {data.type}</p>;
    }
}

export default Element;
