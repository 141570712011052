import "./styles/GridBox.css";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { selectedElementState } from "../../db/elementDb";
import Element from "../../Element";
import log from "cslog";
import GridLayout from "react-grid-layout";
import classNames from "classnames";
import { currentDragState } from "../../db/elementDb";

const GridBox = ({ gridWidth, data, setData, onDrop, device, gs }) => {
    const currentDrag = useRecoilValue(currentDragState);
    const selectedElement = useRecoilValue(selectedElementState);
    return (
        <GridLayout
            className="layout"
            // width={600}
            width={gridWidth}
            autoSize={false}
            cols={21}
            compactType={null}
            margin={[5, 5]}
            containerPadding={[0, 0]}
            rowHeight={5}
            isBounded={true}
            transformScale={1}
            preventCollision={true}
            layout={data.layout}
            onResizeStop={(layout) => {
                log.d(layout, "Layout After Resize");
                setData({ ...data, layout: layout });
            }}
            onDragStop={(layout) => {
                setData({ ...data, layout: layout });
            }}
            onDrop={onDrop}
            isDroppable={true}
            // droppingItem={{ i: "b3", w: 4, h: 1 }}
            droppingItem={currentDrag ? currentDrag : { i: "new", w: 4, h: 1 }}
            draggableHandle=".gridDragHandle"
            isResizable={true}
            // resizeHandles={["se", "e", "s"]}
            resizeHandles={["se"]}
            style={{
                height: "100%",
            }}
        >
            {data.layout.map((item) => {
                if (!item) {
                    return null;
                }
                log.d(item, "Itemm");
                return (
                    <div key={item.i} className="grid_item_wrapper ">
                        <div
                            className="grid_element_wrapper"
                            style={{
                                border:
                                    selectedElement === item.i
                                        ? "1px solid royalblue"
                                        : null,
                            }}
                        >
                            <div
                                className={classNames(
                                    // styles.label,
                                    "grid_item_label",
                                    "gridDragHandle"
                                )}
                            >
                                :::
                            </div>
                            <Element id={item.i} device={device} gs={gs} />
                        </div>
                    </div>
                );
            })}
        </GridLayout>
    );
};

export default GridBox;
