/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { elementState, addElement } from "../db/elementDb";
import Element from "../Element";
import log from "cslog";
import { Button, Icon } from "semantic-ui-react";
import { nanoid } from "nanoid";
import { ELEMENTS } from "../data/panelConfig";
import NavMenu from "./NavMenu";
import SectionBrowser from "../commons/SectionBrowser";
import { cloneDeep } from "lodash";
import Section from "./Section";
import { TOP_HEADER_ID } from "../data/defaults";
import QurealAd from "./QurealAd";
import { cultivateData } from "../data/extractor";
import { globalConfigState } from "../db/gstyleDb";
import { decodeStyle } from "../parser/styleparser";
import { BasicToolbar } from "../widgets/rte/QurealTextEditor";
import { GOOGLE_FONTS } from "../customize/ComplexOptions";

function SectionAdder({
    addSection,
    setSbopen,
    setSbIndex,
    onDelete,
    index,
    last = false,
}) {
    return (
        <div
            style={{
                width: "100%",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px dotted grey",
                transition: "0.5s",
            }}
        >
            <Button
                onClick={() => {
                    if (last) {
                        addSection();
                    } else {
                        addSection(index);
                        onDelete();
                    }
                }}
                icon
                labelPosition="left"
            >
                <Icon name="add" />
                Blank
            </Button>
            <Button
                onClick={() => {
                    setSbopen(true);
                    setSbIndex(index !== undefined ? index : -1);
                    log.d(index, "Setting SB Index");
                }}
                icon
                labelPosition="left"
            >
                <Icon name="th" />
                Browse
            </Button>
            {!last && (
                <Button icon onClick={onDelete}>
                    <Icon name="delete" />
                </Button>
            )}
        </div>
    );
}

const Page = ({ id, index, device, gs }) => {
    const [data, setData] = useRecoilState(elementState(id));
    const globalConfig = useRecoilValue(globalConfigState);
    const [sbopen, setSbopen] = useState(false);
    const [sbIndex, setSbIndex] = useState(-1);
    const [placeholders, setPlaceholders] = useState(new Set());
    const globalConfigD = decodeStyle(globalConfig, gs);

    useEffect(() => {
        if (GOOGLE_FONTS.includes(globalConfigD.defaultFontFamily)) {
            window.WebFont.load({
                google: {
                    families: [globalConfigD.defaultFontFamily],
                },
            });
        }
    }, []);

    const addSection = async (index = -1) => {
        log.p("Adding Section");
        const section_id = nanoid(10);
        const box_id = nanoid(10);

        const box_data = {
            ...ELEMENTS["box"],
            id: box_id,
            pid: section_id,
            childs: [],
        };
        addElement(box_id, box_data);

        const section_data = {
            ...ELEMENTS["section"],
            id: section_id,
            pid: id,
            childs: [box_id],
            widths: [100],
            name: "New Section",
        };
        addElement(section_id, section_data);

        const newSections = Array.from(data.sections);
        if (index === -1) {
            newSections.push(section_id);
        } else {
            newSections.splice(index, 0, section_id);
        }

        setData({ ...data, sections: newSections });
    };

    const onSectionTemplateSelect = (template) => {
        let html = JSON.parse(template.html);
        log.d(html, "Section Template HTML");

        let rootid = nanoid(10);
        let rootdata = html["root"];
        // createIt(rootid, id, rootdata, html);
        cultivateData(rootid, id, rootdata, html);

        const newSections = Array.from(data.sections);
        log.d(sbIndex, "SBINDEX");
        if (sbIndex === -1) {
            newSections.push(rootid);
        } else {
            newSections.splice(sbIndex, 0, rootid);
            const newPhs = new Set(placeholders);
            newPhs.delete(sbIndex);
            setPlaceholders(newPhs);
            setSbIndex(-1);
        }

        setData({ ...data, sections: newSections });

        setSbopen(false);
    };

    return (
        <div
            id={id}
            name={id}
            style={{
                width: "100%",
                // height: "100vh",
                height: "100%",
                margin: 0,
                padding: 0,
                paddingBottom: "100px",
                // background: "#f9f6ff",
                overflow: "auto",
                overflowX: "hidden",
            }}
            // css={css`
            //     * {
            //         font-family: "Times New Roman", Times, serif;
            //     }
            // `}
            css={{
                // "*": globalConfig,
                fontFamily: globalConfigD.defaultFontFamily,
                background: globalConfigD.pageBackground,
                // background: "yellow",
            }}
        >
            {/* <NavMenu /> */}
            <Element
                id={TOP_HEADER_ID}
                index={0}
                key={TOP_HEADER_ID}
                device={device}
                gs={gs}
            />
            {data.sections.map((item, index) => {
                return (
                    // <Element
                    //     id={item}
                    //     index={index}
                    //     key={index}
                    //     device={device}
                    //     gs={gs}
                    // />
                    <Fragment key={index}>
                        {placeholders.has(index) && (
                            <SectionAdder
                                index={index}
                                addSection={addSection}
                                setSbopen={setSbopen}
                                setSbIndex={setSbIndex}
                                onDelete={() => {
                                    const newPh = new Set(placeholders);
                                    newPh.delete(index);
                                    setPlaceholders(newPh);
                                }}
                            />
                        )}
                        <Section
                            id={item}
                            index={index}
                            key={index}
                            device={device}
                            gs={gs}
                            addSection={() => {
                                // const phs = Array.from(placeholders);
                                // phs.push(index);
                                const newPh = new Set(placeholders);
                                newPh.add(index);
                                setPlaceholders(newPh);
                            }}
                        />
                    </Fragment>
                );
            })}
            <SectionAdder
                addSection={addSection}
                setSbopen={setSbopen}
                setSbIndex={setSbIndex}
                last={true}
            />
            {sbopen && (
                <SectionBrowser
                    open={sbopen}
                    setOpen={setSbopen}
                    onSelect={onSectionTemplateSelect}
                />
            )}
            {/* <QurealAd device={device} /> */}
            {/* <BasicToolbar id="qrichtool" /> */}
        </div>
    );
};

export default Page;
