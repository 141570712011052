import { atom, selector } from "recoil";

// export const elementArray = atom({
// 	key: "elementArray",
// 	default: {},
// });

export const gstyleArray = {};

// ===========================================

export const parsedGStyleArray = {};

export const gstyleArrayState = selector({
    key: "GStyleArray",
    get: ({ get }) => {
        const result = { config: get(gstyleConfigState) };
        Object.keys(gstyleArray).map((key) => {
            result[key] = get(gstyleState(key));
        });
        return result;
    },
});

// ===========================================

export const gstyleConfigState = atom({
    key: "GStyleConfig",
    default: {},
});

export const globalConfigState = atom({
    key: "GlobalConfig",
    default: {
        defaultFontFamily: `Arial, Helvetica, sans-serif`,
        pageBackground: `#f9f6ff`,
    },
});

export const GLOBAL_CONFIG_TYPE = {
    defaultFontFamily: "fontFamily",
    pageBackground: "background",
};

// ===========================================

export const gstyleState = (id, data = null) => {
    if (id in gstyleArray) {
        return gstyleArray[id];
    }
    gstyleArray[id] = atom({
        key: id,
        default: data ? data : {},
    });
    return gstyleArray[id];
};

export const addGStyle = (id, initialData) => {
    if (id in gstyleArray) {
        return;
    }
    gstyleArray[id] = atom({
        key: id,
        default: initialData,
    });
};

export const selectedGStyle = atom({
    key: "selectedGStyle",
    default: null,
});
