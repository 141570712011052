/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { mobile, tablet } from "../data/bp";
import { decodeStyle, parseStyle } from "../parser/styleparser";
import ElementWrapper from "./wrappers/ElementWrapper";

function RichText({ id, db, index, setHoverIndex, direction, gs, device }) {
    // const [data, setData] = useRecoilState(elementState(id));
    // const [selectedElement, setSelectedElement] =
    //     useRecoilState(selectedElementState);
    const data = db[id];

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    let styles = decodeStyle(ps.style, gs);
    if (tablet(device)) {
        styles = { ...styles, ...decodeStyle(tabletStyle, gs) };
    }
    if (mobile(device)) {
        styles = { ...styles, ...decodeStyle(mobileStyle, gs) };
    }

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            <div
                css={{
                    "&:hover": decodeStyle(ps.hover, gs),
                }}
                style={{
                    columnWidth: "auto",
                    columnCount: input.columnCount,
                    ...decodeStyle(ps.fixStyle, gs),
                    ...decodeStyle(ps.style, gs),
                    textAlign: input.textAlign,
                    ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                    ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                }}
            >
                <div
                    style={{ styles }}
                    dangerouslySetInnerHTML={{ __html: input.html }}
                />
                {/* <QurealTextEditorBasic
                    id={`QQ${id}`}
                    html={input.html}
                    setHtml={handleChange}
                    styles={styles}
                /> */}
            </div>
        </ElementWrapper>
    );
}

export default RichText;
