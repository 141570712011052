import React from "react";

function Loading({ title = "Loading...", full = false }) {
    return (
        <div
            style={{
                width: "100%",
                height: full ? "100vh" : "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
            }}
        >
            <img
                src={process.env.PUBLIC_URL + "/logo.svg"}
                alt="Qureal Logo"
                style={{
                    width: "50px",
                    opacity: 0.2,
                }}
            />
            <h3
                style={{
                    textAlign: "center",
                    color: "#c4c4c4",
                    marginTop: "10px",
                }}
            >
                {title}
            </h3>
        </div>
    );
}

export default Loading;
