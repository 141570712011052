/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { css, jsx } from "@emotion/react";
import React, { useEffect, useState, Fragment } from "react";
import ElementWrapper from "../wrappers/ElementWrapper";
import { useRecoilState, useSetRecoilState } from "recoil";
import { elementState, selectedElementState } from "../../db/elementDb";
import { parseStyle } from "../../parser/styleparser";
import log from "cslog";
import { tablet, mobile } from "../../data/bp";
import { decodeStyle } from "../../parser/styleparser";

import Editor from "react-simple-code-editor";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwl";

// TODO: theme customization
function CodeView({ id, db, index, setHoverIndex, direction, gs, device }) {
    const data = db[id];

    const highlight = (code) => (
        <Highlight
            {...defaultProps}
            theme={theme}
            code={code}
            language={input.language}
        >
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <Fragment>
                    {tokens.map((line, i) => (
                        <div {...getLineProps({ line, key: i })}>
                            {line.map((token, key) => (
                                <span {...getTokenProps({ token, key })} />
                            ))}
                        </div>
                    ))}
                </Fragment>
            )}
        </Highlight>
    );

    const ps = parseStyle(data, gs, data.cstate);

    const tabletStyle = tablet(device) ? ps.tablet : {};
    const mobileStyle = mobile(device) ? ps.mobile : {};

    let input = ps.input;
    input = { ...input, ...(tablet(device) && ps.itablet) };
    input = { ...input, ...(mobile(device) && ps.imobile) };

    const rootStyle = {
        // width: "100%",
        flex: 1,
        boxSizing: "border-box",
        fontFamily: '"Dank Mono", "Fira Code", monospace',
        fontSize: input.fontSize * 0.5,
        lineHeight: 1.5,
        ...theme.plain,
        // backgroundColor: "purple",
        ...decodeStyle(ps.fixStyle, gs),
        ...decodeStyle(ps.style, gs),
        ...(tablet(device) && decodeStyle(tabletStyle, gs)),
        ...(mobile(device) && decodeStyle(mobileStyle, gs)),
        padding: 0,
    };

    return (
        <ElementWrapper
            id={id}
            pid={data.pid}
            index={index}
            setHoverIndex={setHoverIndex}
            direction={direction}
            type={data.type}
        >
            <Editor
                value={input.code}
                highlight={highlight}
                padding={input.padding * 0.5}
                style={rootStyle}
            />
        </ElementWrapper>
    );
}

export default CodeView;

// https://www.npmjs.com/package/react-simple-code-editor
// https://github.com/FormidableLabs/prism-react-renderer
